import { useState, useEffect, useRef } from "react";

import './New.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { GetListPag, RegisterListPag, SetListPag } from "interface/InterfacePag";

import { SvgAddData, SvgDelete, SvgEdit, SvgEye, SvgPdf, SvgReturn, SvgSave, SvgStatus } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/InterfacePopUp";
import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";
import { GetUserData, RegisterUserData } from "interface/InterfaceUsers";

import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";
import PopUp_NewContact from "components/PopUp/NewContact";
import { Reg_Client } from "services/RegisterData";
import { phoneMask } from "services/Mask";

export default function Client_Details(){

    const [ loading, setLoading ]   = useState(false);
    const [ idUser, setIdUser ]     = useState(GetUserData("id"));
    const [ dataPage, setDataPage ] = useState(GetDataPage('client'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ name, setName ]                   = useState(InitialData('name'));
    const [ corporateName, setCorporateName ] = useState(InitialData('corporate_name'));
    const [ cnpj, setCnpj ]                   = useState(InitialData('cnpj'));
    const [ actingArea, setActingArea ]       = useState(InitialData('acting_area'));
    const [ customerSince, setCustomerSince ] = useState(InitialData('customer_since'));
    const [ note, setNote ]                   = useState(InitialData('note'));
    const [ contact, setContact ]             = useState(InitialData('contact'));
    const [ budget, setBudget ]               = useState(InitialData('budget'));

    useEffect(()=>{
        RegisterUserData('id', setIdUser);
        RegisterDataPage("client", setDataPage);
        RegisterListPag('currentPageId', setIdPage)
    }, []);

    useEffect(()=>{
        setIdUser(GetUserData("id"));
        setDataPage(GetDataPage('client'));
        setIdPage(GetListPag('currentPageId'));

        setName(InitialData('name'));
        setCorporateName(InitialData('corporate_name'));
        setCnpj(InitialData('cnpj'));
        setActingArea(InitialData('acting_area'));
        setCustomerSince(InitialData('customer_since'));
        setNote(InitialData('note'));
        setContact(InitialData('contact'));
        setBudget(InitialData('budget'));
    }, [dataPage]);

    useEffect(()=>{
        setIdUser(GetUserData("id"));
        setDataPage(GetDataPage('client'));
        setIdPage(GetListPag('currentPageId'));

        setName(InitialData('name'));
        setCorporateName(InitialData('corporate_name'));
        setCnpj(InitialData('cnpj'));
        setActingArea(InitialData('acting_area'));
        setCustomerSince(InitialData('customer_since'));
        setNote(InitialData('note'));
        setContact(InitialData('contact'));
        setBudget(InitialData('budget'));
    }, [idPage]);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        if(type == 'contact' || type == 'budget'){
            return [];
        }
        return '';
    }

    function NewContact(){
        SetModalData("NewContact", { "id": idPage });
        SetModalState('NewContact', true);
    }

    function HandleContact(index, type, value){
        const newData = [...contact];
        newData[index][type] = value;
        setContact(newData);
    }
    function DeleteData(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "client", "type": "delete_contact", "name": value });
        SetModalState('Confirmation', true);
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_Client(idPage, name, corporateName, cnpj, actingArea, customerSince, note, contact, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'client');
        SetListPag('currentPageId', 'remuve');
    }

    function DetailsBudget(id){
        SetListPag('currentPage', 'budget_details')
        SetListPag('currentPageId', id);
    }

    return(
        <div className="NewClient">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() } }>
                        <SvgReturn color="#324d6b" className="icons" />
                        Voltar
                    </div>
                    {/*
                    <div className="name_project">
                        Detalhes
                    </div>
                    */}
                    <div className="list_opt_alt_page">
                        <button className="new_block_text new_project">
                            <div className="">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="">
                                Salvar
                            </div>
                        </button>
                    </div>
                </div>

                <div className="show_data_register">
                    <div className="div_show_text">
                        <div className="div_data_project">
                            <div className="div_input space_top space_data">
                                <input type="text" className="" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="140" required />
                                <span className="span_name_input">Nome</span>
                            </div>
                            <div className="div_input space_top space_data">
                                <input type="text" className="" onChange={ (e)=>{ setCorporateName(e.target.value) } } value={ corporateName } maxLength="100" />
                                <span className="span_name_input">Razão Social</span>
                            </div>
                        </div>
                        <div className="div_data_project">
                            <div className="div_input space_top">
                                <input type="text" className="cnpj" onChange={ (e)=>{ setCnpj(e.target.value) } } value={ cnpj } maxLength="18" />
                                <span className="span_name_input">CNPJ</span>
                            </div>
                            <div className="div_input space_top space_data">
                                <input type="text" className="" onChange={ (e)=>{ setActingArea(e.target.value) } } value={ actingArea } maxLength="100" />
                                <span className="span_name_input">Área de atuação</span>
                            </div>
                            <div className="div_input space_top">
                                <input type="text" className="customer_since" onChange={ (e)=>{ setCustomerSince(e.target.value) } } value={ customerSince } maxLength="4" />
                                <span className="span_name_input">Cliente desde</span>
                            </div>
                        </div>

                        <div className="show_title">
                            <div className="title_div">Observação</div>
                        </div>
                        <div className="show_textarea">
                            <JoditEditor config={ config } value={ note ? note.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setNote(newContent) } />
                        </div>
                    </div>

                    {
                        idPage !=0 ?
                        <>
                            <div className="div_show_or_new_data div_show_text">
                                <div className="show_title">
                                    <div className="title_div">Contatos</div>
                                    <div className="title_div">
                                        <div className="new_block_text new_project" onClick={ ()=>{ NewContact(); } }>
                                            Adicionar novo contato
                                        </div>
                                    </div>
                                </div>
                                <div className="div_show_text space_show_data">
                                    {
                                        contact.length > 0 ?
                                            contact.map((key, index)=>{
                                                return(
                                                    <div className="list_opt_add show_data_add" key={ index }>
                                                        <div className="div_data_project">
                                                            <div className="div_input space_top">
                                                                <input type="text" onChange={ (e)=>{ HandleContact(index, 'name', e.target.value) } } value={ key.name } maxLength="80" />
                                                                <span className="span_name_input">Nome</span>
                                                            </div>
                                                            <div className="div_input space_top">
                                                                <input type="text" onChange={ (e)=>{ HandleContact(index, 'email', e.target.value) } } value={ key.email } maxLength="40" />
                                                                <span className="span_name_input">E-mail</span>
                                                            </div>
                                                            <div className="div_input space_top">
                                                                <input type="text" className="phone" onChange={ (e)=>{ HandleContact(index, 'phone', e.target.value) } } value={ phoneMask(key.phone) } maxLength="20" />
                                                                <span className="span_name_input">Telefone</span>
                                                            </div>
                                                            <div className="div_input space_top">
                                                                <input type="text" onChange={ (e)=>{ HandleContact(index, 'position', e.target.value) } } value={ key.position } maxLength="50" />
                                                                <span className="span_name_input">Cargo</span>
                                                            </div>
                                                            <div className="div_input space_top" onClick={ ()=>{ DeleteData(key.id, key.name) } } style={ { display: 'flex' } }>
                                                                <SvgDelete color="#f00000" className="icons" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        :
                                        <div className="no_data">Nenhum contato encontrado...</div>
                                    }
                                </div>
                            </div>

                            {
                                budget.length > 0 ?
                                <div className="div_show_or_new_data div_show_text">
                                    <div className="show_title">
                                        <div className="title_div">Orçamentos</div>
                                    </div>
                                    <div className="div_show_text space_show_data">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th width="20">#</th>
                                                    <th width="120">Status</th>
                                                    <th>Código</th>
                                                    <th width="80">Data</th>
                                                    <th width="80">Valor R$</th>
                                                    <th width="20" align="right">#</th>
                                                </tr>
                                            </tbody>
                                            <tbody>
                                                {
                                                    budget.map((key, index)=>{
                                                        return(
                                                            <tr key={ index }>
                                                                <td align="left">{ index + 1 }</td>
                                                                <td>{ key.status }</td>
                                                                <td>#EST-{ key.code }</td>
                                                                <td>{ key.estimatedDate }</td>
                                                                <td>{ key.cash }</td>
                                                                <td align="right">
                                                                    <div className="list_opt">
                                                                        <div onClick={ ()=>{ DetailsBudget(key.id) } }>
                                                                            <SvgEdit color="#324d6b" className="icons"/>
                                                                        </div>
                                                                        <div>
                                                                            <a href={ "./budget.html?budget=" + (Math.floor(Math.random() * 65536) - 32768) + "_" + key.id } target="_blank">
                                                                                <SvgPdf color="#F00000" className="icons" />
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div> : null
                            }
                        </> : null
                    }
                </div>
            </form>
            <PopUP_ReturnResponse />
            <PopUp_NewContact />
        </div>
    )
}
