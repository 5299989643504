import Axios from 'axios';

import Cookies from 'universal-cookie';

import { SetListPag } from 'interface/InterfacePag';
import { SetListData, SetListDataSingle } from 'interface/InterfaceData';

import { connectionPage, cookiesRegister, typeCookieEmail, typeCookiePageId, typeCookiePassw } from 'dataFixed';
import { SetUserData } from 'interface/InterfaceUsers';

// Delete
export function Reg_Delete(id, origin, type, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append("id", id);
    data.append('type_page', origin);
    data.append('edit_data', type);

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        switch (origin) {
            case 'about_chroma':
                    SetListDataSingle('about_chroma', response.data.about_chroma);
                break;

            case 'portfolio':
                    SetListDataSingle('portfolio', response.data.portfolio);
                    SetListDataSingle('category', response.data.category);
                break;

            case 'presentation':
                    SetListDataSingle('presentation', response.data.presentation);
                break;

            case 'services':
                    SetListDataSingle('services', response.data.services);
                break;

            case 'budget':
                    SetListDataSingle('budget', response.data.budget);
                break;

            case 'accessDash':
                    SetListDataSingle('accessDash', response.data.accessDash);
                break;

            case 'client':
                    SetListDataSingle('client', response.data.client);
                break;

            case 'signature':
                    SetListDataSingle('signature', response.data.signature);
                break;
        }
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}

// About Chroma
export function Reg_About(description, servOffered, awards, CallbackSuccess, CallbackError){
    const data    = new FormData();
    data.append('type_page', 'about_chroma');
    data.append('edit_data', 'edit_or_register_data');

    data.append("description", description);

    servOffered.forEach((element, index) => {
        data.append("serv_id[]", element.id);
        data.append("serv_title[]", element.title);
        data.append("serv_img_" + index, element.img);
        data.append("serv_text[]", element.text);
        data.append("serv_link[]", element.link);
    });

    awards.forEach((element, index) => {
        data.append("awards_id[]", element.id);
        data.append("awards_title[]", element.title);
        data.append("awards_img_" + index, element.img);
    });

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('about_chroma', response.data.about_chroma);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}

// New presentation
export function Reg_Presentation(idPage, title, client, newClient, description, projectAdd, CallbackSuccess, CallbackError){
    const cookies   = new Cookies();
    const reg_email = cookies.get(typeCookieEmail);
    const reg_passw = cookies.get(typeCookiePassw);

    const data    = new FormData();
    data.append('type_page', 'portfolio');
    data.append('edit_data', 'new_presentation');

    data.append("id_page", idPage);
    data.append("title", title);
    data.append("client", client);
    data.append("newClient", newClient);
    data.append("description", description);

    data.append('email', reg_email);
    data.append('password', reg_passw);

    projectAdd.forEach(element => {
        data.append("id_project[]", element.id);
        data.append("id_text[]", element.text);
    });

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);

        SetListPag('currentPage', 'presentation_details')
        SetListPag('currentPageId', response.data.idPage);

        SetListDataSingle('presentation', response.data.presentation);
        SetListDataSingle('portfolio', response.data.portfolio);
        SetListDataSingle('category', response.data.category);
        SetListDataSingle('client', response.data.client);

        CallbackSuccess();
    }).catch((error)=>{

        CallbackError();
    })
}

// Edit ou register project
export function Reg_Project(idPage, client, newClient, project, year, file, category, contents, linkDrive, linkSite, linkBehance, restricted, description, datasheet, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data    = new FormData();

    data.append('type_page', 'portfolio');
    data.append('edit_data', 'edit_or_new_project');

    data.append("id_page", idPage);
    data.append("client", client);
    data.append("newClient", newClient);
    data.append("project", project);
    data.append("year", year);
    data.append("file", file);
    data.append("link_drive", linkDrive);
    data.append("link_site", linkSite);
    data.append("link_behance", linkBehance);
    data.append("restricted", restricted);
    data.append("description", description);
    data.append("datasheet", datasheet);

    // Category
    category.forEach(element => {
        data.append("category_id[]", element.id);
        data.append("category_name[]", element.name);
    })

    // contents
    console.log(contents);
    contents.forEach((elent_0, index_0) => {
        data.append('data_id[]', elent_0.id);
        data.append('data_type[]', elent_0.type);
        data.append('data_container[]', elent_0.container);
        data.append('data_order[]', elent_0.order_);
        data.append('data_width[]', elent_0.width);
        data.append('data_height[]', elent_0.height);

        elent_0.contents.forEach((elent_1, index_1) => {
            data.append('index_0[]', index_0);
            data.append('index_1[]', index_1);
            data.append('contents_id_' + index_0 + '_' + index_1, elent_1.id);
            data.append('contents_id_content_' + index_0 + '_' + index_1, elent_1.id_content);
            data.append('contents_type_' + index_0 + '_' + index_1, elent_1.type);
            data.append('contents_width_' + index_0 + '_' + index_1, elent_1.width);
            data.append('contents_height_' + index_0 + '_' + index_1, elent_1.height);

            data.append('contents_type_video_' + index_0 + '_' + index_1, elent_1.type_video);
            data.append('contents_link_' + index_0 + '_' + index_1, elent_1.link);
            data.append('contents_title_' + index_0 + '_' + index_1, elent_1.title);
            data.append('contents_file_' + index_0 + '_' + index_1, elent_1.file);
            data.append('contents_name_' + index_0 + '_' + index_1, elent_1.name);
            data.append('contents_text_' + index_0 + '_' + index_1, elent_1.text);
        });
    });

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);

        SetListDataSingle('portfolio', response.data.portfolio);
        SetListDataSingle('category', response.data.category);
        SetListDataSingle('client', response.data.client);

        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}

// Update presentation
export function Reg_UpdatePresentation(){
    const data    = new FormData();
    data.append('type_page', 'update_presentation');
    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('presentation', response.data.presentation);
    }).catch((error)=>{
    })
}
// Edit presentation
export function Reg_EditPresentation(idPage, showCG, client, newClient, title, status, meetingMinutes, description, listLink, project, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', 'presentation');
    data.append('edit_data', 'edit_presentation');

    data.append("id_page", idPage);
    data.append("showCG", showCG);
    data.append("client", client);
    data.append("newClient", newClient);
    data.append("title", title);
    data.append("status", status);
    data.append("meetingMinutes", meetingMinutes);
    data.append("description", description);

    project.forEach(element => {
        data.append("id_project[]", element.id_presentation);
        data.append("id_text[]", element.text);
    });

    listLink.forEach(element => {
        data.append("id_link[]", element.id);
        data.append("edit_link[]", element.link);
    });

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('presentation', response.data.presentation);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}
// Edit or register new presentation
export function Reg_NewProject(idPage, newProject, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', 'presentation');
    data.append('edit_data', 'new_project');
    data.append('idPage', idPage);

    newProject.forEach(element => {
        data.append('new_id[]', element.id);
    });

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('presentation', response.data.presentation);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}

// Edit or register budget
export function Reg_Budget(idPage, idRegister, status, signature, typeBudget, client, contact, presentation, subject, estimatedDate, dueDate, listServices, newServices, textFixed, nameClient, corporateName, cnpj, actingArea, note, contactName, contactEmail, contactPhone, contactPosition, tax, billed, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data    = new FormData();

    data.append('type_page', 'budget');
    data.append('edit_data', 'edit_or_register_budget');

    data.append('email', cookies.get(typeCookieEmail));
    data.append('password', cookies.get(typeCookiePassw));

    data.append('idPage', idPage);
    data.append('idRegister', idRegister);
    data.append('status', status);
    data.append('signature', signature);
    data.append('typeBudget', typeBudget);
    data.append('client', client);
    data.append('contact', contact);
    data.append('presentation', presentation);
    data.append('subject', subject);
    data.append('estimatedDate', estimatedDate);
    data.append('dueDate', dueDate);
    data.append('tax', tax);
    data.append('billed', billed);

    data.append('nameClient', nameClient);
    data.append('corporateName', corporateName);
    data.append('cnpj', cnpj);
    data.append('actingArea', actingArea);
    data.append('note', note);
    data.append('contactName', contactName);
    data.append('contactEmail', contactEmail);
    data.append('contactPhone', contactPhone);
    data.append('contactPosition', contactPosition);

    textFixed.forEach(element => {
        data.append('textFixed_id[]', element.id);
        data.append('textFixed_title[]', element.title);
        data.append('textFixed_text[]', element.text);
    });

    listServices.forEach(element => {
        data.append('edit_service_id[]', element.id);
        data.append('edit_service_title[]', element.title);
        data.append('edit_service_qtd[]', element.qtd);
        data.append('edit_service_cash[]', element.cash);
        data.append('edit_service_text[]', element.text);
    });

    newServices.forEach((element, index) => {
        data.append('new_service_type[]', element.type);
        data.append('new_service_data[]', element.value);

        element.service.forEach(element_1 =>{
            data.append('new_service_index[]', index);
            data.append('new_service_id_service[]', element_1.id_service);
            data.append('new_service_title[]', element_1.title);
            data.append('new_service_qtd[]', element_1.qtd);
            data.append('new_service_cash[]', element_1.cash);
            data.append('new_service_text[]', element_1.text);
        })
    });

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);

        SetListDataSingle('budget', response.data.budget);
        SetListDataSingle('client', response.data.client);
        SetListDataSingle('presentation', response.data.presentation);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}
// Copy Budget
export function Reg_CopyBudget(id, idUser, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data    = new FormData();

    data.append('type_page', 'budget');
    data.append('edit_data', 'copy_budget');

    data.append('id', id);
    data.append('idUser', idUser);

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListDataSingle('budget', response.data.budget);
        SetListPag('currentPageId', response.data.idPage);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}

// Edit or register services
export function Reg_Services(idPage, type, title, cash, text, listCombo, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data    = new FormData();

    data.append('type_page', 'services');
    data.append('edit_data', 'edit_services');

    data.append('idPage', idPage);
    data.append('type', type);
    data.append('title', title);
    data.append('cash', cash);
    data.append('text', text);

    listCombo.forEach((element, index)=> {
        data.append('list_id[]', element.id);
        data.append('list_title[]', element.title);
        data.append('index[]', index);
        element.new_list.forEach(element => {
            data.append('new_list[]', element.value);
            data.append('new_index[]', index);
        });

    });

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);

        SetListDataSingle('services', response.data.services);
        SetListDataSingle('combo', response.data.combo);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}

// Edit or register category
export function Reg_Category(typeData, type, nameAnt, name, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', 'category');
    data.append('edit_data', 'edit_category');

    data.append('typeData', typeData);
    data.append('type', type);
    data.append('nameAnt', nameAnt);
    data.append('name', name);

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('category', response.data.category);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}

// Edit or register data user
export function Reg_UserDash(userId, idPage, name, email, pass, file, nivel, note, addPage, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();

    data.append('type_page', 'accessDash');
    data.append('edit_data', 'edit_profile');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('name', name);
    data.append('email', email);
    data.append('pass', pass);
    data.append('file', file);
    data.append('nivel', nivel);
    data.append('note', note);

    addPage.forEach((element, index) => {
        data.append('page_value[]', element.value);
        data.append('page_name[]', element.label);
    });

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('accessDash', response.data.accessDash);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }

        if(userId == idPage){
            const newData = response.data.accessDash.find(item => item.id == userId);
            cookies.set(typeCookieEmail, email, '/', cookiesRegister);
            if(pass !=''){
                cookies.set(typeCookiePassw, response.data.pass, '/', cookiesRegister);
            }

            SetUserData('id', newData['id']);
            SetUserData('file', newData['file']);
            SetUserData('name', newData['name']);
            SetUserData('email', newData['email']);
            SetUserData('access', newData['access']);
            SetUserData('page', newData['page']);
        }

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Edit user logado
export function Reg_EditUser(id, name, email, pass, file, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();

    data.append('type_page', 'accessDash');
    data.append('edit_data', 'edit_user');

    data.append('id', id);
    data.append('name', name);
    data.append('email', email);
    data.append('pass', pass);
    data.append('file', file);

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('accessDash', response.data.accessDash);
        const newData = response.data.accessDash.find(item => item.id == id);
        cookies.set(typeCookieEmail, email, '/', cookiesRegister);
        if(pass !=''){
            cookies.set(typeCookiePassw, response.data.pass, '/', cookiesRegister);
        }
        SetUserData('id', newData['id']);
        SetUserData('file', newData['file']);
        SetUserData('name', newData['name']);
        SetUserData('email', newData['email']);

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// edit or register client
export function Reg_Client(idPage, name, corporateName, cnpj, actingArea, customerSince, note, contact, CallbackSuccess, CallbackError){
    const data    = new FormData();
    const cookies = new Cookies();

    data.append('type_page', 'client');
    data.append('edit_data', 'edit_or_register_client');

    data.append('idPage', idPage);
    data.append('name', name);
    data.append('corporateName', corporateName);
    data.append('cnpj', cnpj);
    data.append('actingArea', actingArea);
    data.append('customerSince', customerSince);
    data.append('note', note);

    contact.forEach(element => {
        data.append('contact_id[]', element.id);
        data.append('contact_name[]', element.name);
        data.append('contact_email[]', element.email);
        data.append('contact_phone[]', element.phone);
        data.append('contact_position[]', element.position);
    });

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);

        SetListDataSingle('client', response.data.client);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}

// Register client
export function Reg_NewClient(id, name, corporateName, cnpj, actingArea, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', 'portfolio');
    data.append('edit_data', 'new_client');

    data.append('idPage', id);
    data.append('name', name);
    data.append('corporateName', corporateName);
    data.append('cnpj', cnpj);
    data.append('actingArea', actingArea);

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('portfolio', response.data.portfolio);
        SetListDataSingle('category', response.data.category);
        SetListDataSingle('client', response.data.client);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}

// Register contact
export function Reg_NewContact(idPage, name, email, phone, position, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', 'client');
    data.append('edit_data', 'new_contact');

    data.append('idPage', idPage);
    data.append('name', name);
    data.append('email', email);
    data.append('phone', phone);
    data.append('position', position);

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('client', response.data.client);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}

// Register signature
export function Reg_Signature(id, name, file, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', 'signature');
    data.append('edit_data', 'new_or_edit_signature');

    data.append('id', id);
    data.append('name', name);
    data.append('file', file);

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('signature', response.data.signature);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}
