import React, { useState, useEffect, useRef } from "react";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import './Portfolio.css';

import Cookies from 'universal-cookie';

import Select from 'react-select'

import { SetModalData, SetModalState } from "interface/InterfacePopUp";
import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";
import { GetListPag, RegisterListPag, SetListPag } from "interface/InterfacePag";

import { SvgAddData, SvgAddOpt_1, SvgAddOpt_2, SvgBehance, SvgCircle, SvgCircleNoSelected, SvgCircleSelected, SvgDelete, SvgEdit, SvgEye, SvgGoogleDrive, SvgLockClose, SvgMenuPoint, SvgSearch, SvgSetaRight } from "components/SvgFile";

import { Reg_Presentation } from "services/RegisterData";
import { GetUserData, RegisterUserData } from "interface/InterfaceUsers";
import { cookiesRegister } from "dataFixed";
import Preview from "components/PageClient/preview";

export default function Portfolio(){
    const cookies = new Cookies();

    const [ loading, setLoading ] = useState(false);

    const textArea = useRef();
    const [ showDataPage, setShowDataPage ] = useState(GetDataPage("portfolio"));
    const [ showCategory, setShowCategory ] = useState(GetDataPage("category"));
    const [ showClient, setShowClient ]     = useState(GetDataPage("client"));

    const [ userPage, setUserPage ]         = useState(GetUserData('page'));
    const [ userAccess, setUserAccess ]     = useState(GetUserData("access"));

    const [ currentPage, setCurrentPage ]   = useState(GetListPag("currentPage"));

    const [ title, setTitle ]             = useState('');
    const [ client, setClient ]           = useState('');
    const [ newClient, setNewClient ]     = useState('');
    const [ description, setDescription ] = useState('');
    const [ newHeight, setNewHeight ]     = useState(110);
    const [ projectAdd, setProjectAdd ]   = useState([]);

    const [ newPresentation, setNewPresentation ] = useState(false);

    const [ search, setSearch ]     = useState('');
    const [ category, setCategory ] = useState('all');

    const [ itensPage, setItensPage ] = useState(30);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(showDataPage.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showDataPage.slice(startItens, endItens);

    useEffect(()=>{
        RegisterDataPage("portfolio", setShowDataPage);
        RegisterDataPage("category", setShowCategory);
        RegisterDataPage("client", setShowClient);

        RegisterUserData("page", setUserPage);
        RegisterUserData("access", setUserAccess);

        RegisterListPag("currentPage", setCurrentPage);
    }, []);

    useEffect(()=>{
        RegisterDataPage("portfolio", setShowDataPage);
        RegisterDataPage("category", setShowCategory);
        RegisterDataPage("client", setShowClient);
    }, [showDataPage]);

    const [ dataSearch, setDataSearch ] = useState([]);
    const [ dup_0, setDup_0 ] = useState([]);
    const [ dup_1, setDup_1 ] = useState([]);
    const [ dup_2, setDup_2 ] = useState([]);
    const [ dup_3, setDup_3 ] = useState([]);

    function SearchInput(value){
        let newList_0 = []; let newList_1 = [];
        let newList_2 = []; let newList_3 = [];


        let valeu_sep = value.toLowerCase().split(' ');
        if(value){

            if(valeu_sep.length == 1){
                GetDataPage('portfolio').map((key, index)=>{
                    // client
                    if(key.name_client.toLowerCase().indexOf(valeu_sep[0]) != -1){
                        newList_0.push(key);
                    }

                    // project
                    if(key.project.toLowerCase().indexOf(valeu_sep[0]) != -1){
                        newList_0.push(key);
                    }

                    // year
                    if(key.year.indexOf(valeu_sep[0]) != -1){
                        newList_0.push(key);
                    }
                });
                setDup_0(newList_0.filter((item, index) => newList_0.indexOf(item) == index));
                setTimeout(() => {
                    setShowDataPage(newList_0.filter((item, index) => newList_0.indexOf(item) == index));
                }, 100);

            }
            if(valeu_sep.length == 2){
                dup_0.map((key, index)=>{
                    // client
                    if(key.name_client.toLowerCase().indexOf(valeu_sep[1]) != -1){
                        newList_1.push(key);
                    }

                    // project
                    if(key.project.toLowerCase().indexOf(valeu_sep[1]) != -1){
                        newList_1.push(key);
                    }

                    // year
                    if(key.year.indexOf(valeu_sep[1]) != -1){
                        newList_1.push(key);
                    }
                });
                setDup_1(newList_1.filter((item, index) => newList_1.indexOf(item) == index));
                setTimeout(() => {
                    setShowDataPage(newList_1.filter((item, index) => newList_1.indexOf(item) == index));
                }, 100);
            }
            if(valeu_sep.length == 3){
                setDataSearch([]);
                dup_1.map((key, index)=>{
                    // client
                    if(key.name_client.toLowerCase().indexOf(valeu_sep[2]) != -1){
                        newList_2.push(key);
                    }

                    // project
                    if(key.project.toLowerCase().indexOf(valeu_sep[2]) != -1){
                        newList_2.push(key);
                    }

                    // year
                    if(key.year.indexOf(valeu_sep[2]) != -1){
                        newList_2.push(key);
                    }
                });
                setDup_2(newList_2.filter((item, index) => newList_2.indexOf(item) == index));
                setTimeout(() => {
                    setShowDataPage(newList_2.filter((item, index) => newList_2.indexOf(item) == index));
                }, 100);
            }
            if(valeu_sep.length == 4){
                setDataSearch([]);
                dup_2.map((key, index)=>{
                    // client
                    if(key.name_client.toLowerCase().indexOf(valeu_sep[2]) != -1){
                        newList_3.push(key);
                    }

                    // project
                    if(key.project.toLowerCase().indexOf(valeu_sep[2]) != -1){
                        newList_3.push(key);
                    }

                    // year
                    if(key.year.indexOf(valeu_sep[2]) != -1){
                        newList_3.push(key);
                    }
                });
                setDup_3(newList_3.filter((item, index) => newList_3.indexOf(item) == index));
                setTimeout(() => {
                    setShowDataPage(newList_3.filter((item, index) => newList_3.indexOf(item) == index));
                }, 100);
            }

        }else {
            setShowDataPage(GetDataPage("portfolio"));
        }
        setSearch(value);
    }

    function SearchCategory(value){
        const newList = [];
        if(value){
            if(value == 'all'){
                setShowDataPage(GetDataPage("portfolio"));
            }else {
                GetDataPage('portfolio').forEach(item =>{
                    item.category.map((key, index)=>{
                        if(key.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                            newList.push(item);
                        }
                    })
                });
                const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
                setShowDataPage(duplicate);
            }
        }else {
            setShowDataPage(GetDataPage("portfolio"));
        }
        setCategory(value);
    }

    function NewProject(id){
        SetListPag('currentPage', 'project');
        SetListPag('currentPageId', id);
    }

    function Delete(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "portfolio", "type": "delete_data", "name": value });
        SetModalState('Confirmation', true);
    }

    function AutoGrow() {
        setNewHeight(textArea.current.scrollHeight - 20);
    }

    function ShowCircleSelected(id, value, index, state){
        if(newPresentation == true){
            const newData = [...showDataPage];
            newData[index].selected = state;
            setShowDataPage(newData);

            if(state == true){
                projectAdd.push({ "id": id, "project": value, "text": "" })
            }else {
                const dataAdd    = [...projectAdd];
                let index_remuve = '';
                dataAdd.map((key, index)=>{
                    if(key.id == id){
                        index_remuve = index;
                    }
                })
                dataAdd.splice(index_remuve, 1);
                setProjectAdd(dataAdd);
            }
        }
    }

    function AddNewPresentation(state){
        setNewPresentation(state);
        const newData = [...showDataPage];
        newData.map((key, index)=>{
            key.selected = false;
        });
        setTitle('');
        setClient('');
        setProjectAdd([]);
        setDescription('');
        setNewHeight(110);
        setShowDataPage(newData);
    }

    function ShowClient(){
        const options = [{ "value": "add", "label": "Adicionar novo cliente" }]
        showClient.map((key, index)=>{
            options.push({
                "value": key.id, "label": key.name
            });
        })
        return options;
    }

    function RegisterPresentation(){
        setLoading(true);
        Reg_Presentation(0, title, client.value, newClient, description, projectAdd, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
    }

    function OpenProject(id){
        let pageCookies  = "project_" + id;
        let minutesValid = new Date(new Date().getTime() + 60 * 60 * 3600);
        cookies.set(pageCookies, id, { path: '/', expires: minutesValid }, cookiesRegister);
        window.open('preview.html?project=' + id, '_blank');
    }

    function HandleDataPresentation(index, value){
        const newData = [...projectAdd];
        newData[index].text = value;
        setProjectAdd(newData);
    }

    return(
        <div className="Portfolio">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page">
                        Portfólios
                    </div>
                </div>
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="buscar" />
                </div>
                <div className="list_opt_alt_page">
                    {
                        userAccess == 1 ?
                        <div className="new_data" onClick={ ()=>{ AddNewPresentation(!newPresentation) } }>
                            <div className="">
                                <span className="icons_menu">+</span>
                            </div>
                            <div className="">
                                apresentação
                            </div>
                        </div>:
                        <>
                            {
                                userPage.find(item => item.page == 'presentation') ?
                                <div className={ newPresentation == true ? "new_block_text new_block_active" : "new_block_text" } onClick={ ()=>{ AddNewPresentation(!newPresentation) } }>
                                    <div className="">
                                        <span className="icons_menu">+</span>
                                    </div>
                                    <div className="">
                                        apresentação
                                    </div>
                                </div>
                                : null
                            }
                        </>
                    }

                    <div className="new_block_text" onClick={ ()=>{ NewProject(0) } }>
                        <div className="">
                            <span className="icons_menu">+</span>
                        </div>
                        <div className="">
                            projeto
                        </div>
                    </div>
                </div>
            </div>

            <div className="list_category">
                <div className={ category == 'all' ? "show_name_category category_active" : "show_name_category" } onClick={ ()=>{ SearchCategory('all') } }>
                    Todas categorias
                </div>
                {
                    showCategory.map((key, index)=>{
                        return(
                            <div className={ category == key.name ? "show_name_category category_active" : "show_name_category" } key={ index } onClick={ ()=>{ SearchCategory(key.name) } }>
                                {
                                    key.name
                                }
                            </div>
                        )
                    })
                }
            </div>
            <div className="list_portfolio_new_presentation">
                <div className="list_project">
                    {
                        currentItens.map((key, index)=>{
                            return(
                                <div className="div_project_data" key={ index }>
                                    <div className="project_data_inf">
                                        <div className={ newPresentation == false ? "project_data_client" : "project_data_client select_project_data" }>{ key.name_client }</div>
                                        {
                                            newPresentation == false ? null :
                                            <div className="project_data_opt_select" onClick={ ()=>{ ShowCircleSelected(key.id, key.project, index, !key.selected) } }>
                                                {
                                                    key.selected == true ?
                                                    <SvgCircleSelected color="#07C4D6" className="circle_selected" /> :
                                                    <SvgCircleNoSelected color="#ffffff" className="circle_selected" />
                                                }
                                            </div>
                                        }
                                        <div className={ newPresentation == false ? "project_data_show_opt" : "hide_project_data" }>
                                            <div className="project_data_delete" onClick={ ()=>{ Delete(key.id, key.project) }}>
                                                <SvgDelete color="#ffffff" className="icons" />
                                            </div>
                                            {
                                                key.link_behance !='' ?
                                                <a href={ key.link_behance } target="_blank">
                                                    <div className="project_data_behance">
                                                        <SvgBehance color="#ffffff" className="icons" />
                                                    </div>
                                                </a> : null
                                            }
                                            {
                                                key.link_drive !='' ?
                                                <a href={ key.link_drive } target="_blank">
                                                    <div className="project_data_link_drive">
                                                        <SvgGoogleDrive color="#ffffff" className="icons" />
                                                    </div>
                                                </a> : null
                                            }
                                            <div className="project_data_eye" onClick={ ()=>{ OpenProject(key.id) } }>
                                                <SvgEye color="#ffffff" className="icons" />
                                            </div>
                                            <div className="project_data_edit" onClick={ ()=>{ NewProject(key.id) } }>
                                                <SvgEdit color="#ffffff" className="icons" />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        key.restricted == false ? null :
                                        <div className="project_data_lock_close">
                                            <SvgLockClose color="#FFFFFF" className="lock" />
                                        </div>
                                    }
                                    <div className="project_data_div_img">
                                        <img alt="show img" src={ "./assets/project/" + key.file } className="project_data_img" />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    newPresentation == false ? null :
                    <div className="new_presentation">
                        <div className="presentation_title">
                            Nova apresentação
                        </div>
                        <div className="save_presentation">
                            <input type="text" maxLength="140" className="input_new_presentation" value={ title } onChange={ (e)=>{ setTitle(e.target.value) } } placeholder="Título" />
                        </div>
                        <div className="save_presentation">
                            <Select className="input_new_presentation" defaultOptions options={ ShowClient() } onChange={ (e)=> { setClient(e); } } placeholder="Cliente..." />
                        </div>
                        {
                            client.value == 'add' ?
                            <div className="save_presentation">
                                <input type="text" maxLength="140" className="input_new_presentation" value={ newClient } onChange={ (e)=>{ setNewClient(e.target.value) } } placeholder="Novo cliente..." />
                            </div> : null
                        }
                        <div className="save_presentation">
                            <textarea ref={ textArea } className="input_new_presentation" onChange={ (e)=>{ setDescription(e.target.value); AutoGrow() } } value={ description !='' ? description.replace(/<br>/g,'\n') : "" } style={ { height: newHeight + "px" } } placeholder="Descrição..." />
                        </div>
                        <div className="save_presentation summary">
                            Resumo
                        </div>
                        <div className="list_project_add">
                            {
                                projectAdd.length > 0 ?
                                    projectAdd.map((key, index)=>{
                                        return(
                                            <div className="show_icon_presentation" key={ index }>
                                                <div className="">
                                                    Projeto: { key.project }
                                                </div>
                                                <div className="save_presentation">
                                                    <textarea className="input_new_presentation" onChange={ (e)=>{ HandleDataPresentation(index, e.target.value); } } value={ key.text !='' ? key.text.replace(/<br>/g,'\n') : "" } style={ { height: "34px" } } placeholder="Descrição..." />
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                "Nenhum projeto adicionado!"
                            }
                        </div>
                        <div className="save_presentation">
                            <div className="new_block_text" onClick={ ()=>{ RegisterPresentation(); } }>
                                Criar
                            </div>
                        </div>
                        <div className="save_presentation">
                            <div className="cancel_presentation" onClick={ ()=>{ AddNewPresentation(false) } }>
                                Cancelar
                            </div>
                        </div>
                    </div>
                }
            </div>
            {
                showDataPage.length >= 30 ?
                <div className="list_pages">
                    {
                        Array.from(Array(page), (item, index)=>{
                            return(
                                <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                    { index + 1 }
                                </div>
                            )
                        })
                    }
                </div> : null
            }
        </div>
    )
}
