import React, { useState, useEffect } from "react";

import './NewPresentation.css';

import Select from 'react-select';

import { SvgClose } from "components/SvgFile";

import TextareaAutosize from 'react-textarea-autosize';

import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/InterfacePopUp";

import { Reg_Presentation } from "services/RegisterData";
import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";

export default function PopUP_NewPresentation(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ showClient, setShowClient ] = useState(GetDataPage("client"));

    const [ title, setTitle ]         = useState('');
    const [ client, setClient ]       = useState('');
    const [ newClient, setNewClient ] = useState('');
    const [ text, setText ]           = useState('');

    useEffect(()=>{
        RegisterModalData('NewPresentation', setModaldata);
        RegisterModalObserver('NewPresentation', setShowPopUp);

        RegisterDataPage("client", setShowClient);
    }, []);

    function ShowClient(){
        const options = [{ "value": "add", "label": "Adicionar novo cliente" }]
        showClient.map((key, index)=>{
            options.push({
                "value": key.id, "label": key.name
            });
        })
        return options;
    }

    function SaveData(){
        Reg_Presentation(0, title, client.value, newClient, text, [], ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
    }

    function CallbackError(){
    }

    function ClosePopUp(){
        SetModalState('NewPresentation', false);
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all popup_presentation">
                        <div className="div_data type_div">
                            <div className="title">
                                Registrar nova aprensetação
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="show_input">
                                    <div className="div_input space_top space_data align_presentation">
                                        <Select className="space_data" defaultOptions options={ ShowClient() } onChange={ (e)=> { setClient(e) } } placeholder="..." />
                                        <span className="span_search_select">Cliente</span>
                                    </div>
                                </div>
                                {
                                    client.value == 'add' ?
                                    <div className="show_input">
                                        <div className="div_input space_top space_data">
                                            <input type="text" maxLength="140" className="input_text input_new_presentation" onChange={ (e)=>{ setNewClient(e.target.value) } } placeholder="" />
                                            <span className="span_name_input">Nome do novo cliente</span>
                                        </div>
                                    </div> : null
                                }
                                <div className="show_input">
                                    <div className="div_input space_top space_data">
                                        <input type="text" className="div_input space_data" onChange={ (e)=>{ setTitle(e.target.value) } } maxLength="140" />
                                        <span className="span_name_input">Título</span>
                                    </div>
                                </div>
                                <div className="show_input">
                                    <div className="div_input space_top space_data">
                                        <TextareaAutosize className="textarea_presentation" onChange={ (e)=>{ setText(e.target.value); } } />
                                        <span className="span_name_textarea">Descrição</span>
                                    </div>
                                </div>
                                <div className="register_data button_category" onClick={ ()=>{ SaveData() } }>
                                    Cadastrar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
