import { useState, useEffect, useRef } from "react";

import JoditEditor from 'jodit-react';

import { config } from "dataFixed";

import { GetDataPage } from "interface/InterfaceData";
import { GetListPag, RegisterListPag } from "interface/InterfacePag";

export default function Text(props){

    const [ showData, setShowData ] = useState(GetDataPage('portfolio'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ text, setText ]         = useState(HandleData('text'));

    useEffect(()=>{
        RegisterListPag('currentPageId', setIdPage);
        RegisterListPag('portfolio', setShowData);
    }, []);

    useEffect(()=>{
        setShowData(GetDataPage('portfolio'));
        setIdPage(GetListPag('currentPageId'));

        setText(HandleData('text'));
    }, [showData]);

    useEffect(()=>{
        setShowData(GetDataPage('portfolio'));
        setIdPage(GetListPag('currentPageId'));

        setText(HandleData('text'));
    }, [idPage]);

    function HandleData(type){
        if(idPage !=0){
            const newData      = showData.find(item => item.id == idPage);
            if(newData.contents){
                if(props.id_content !=0){
                    const dataContents = newData.contents.find(item => item.id == props.id_content);
                    const showDataContent = dataContents.contents.find(item => item.id == props.id_data);
                    return showDataContent[type];
                }
                return '';
            }
            return '';
        }
        return '';
    }

    function handleText(value){
        setText(value);
        props.RegisterData({
            "id": props.id_data,
            "id_content": props.id_content,
            "height": "center",
            "width": "left",
            "type": "text",
            "status": false,
            "name": "",
            "file": "",
            "text": value,
            "type_video": "",
            "title": "",
            "link": ""
        }, props.index_0, props.index_1);
    }

    return(
        <div className="DefaultDiv">
            <div className="div_input">
                <JoditEditor className="show_textarea" config={ config } value={ text ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => handleText(newContent) } />
            </div>
        </div>
    )
}
