import { useState, useEffect } from 'react';

import './Contents.css';

import Cookies from "universal-cookie";

import { SetModalData, SetModalState } from 'interface/InterfacePopUp';
import { GetUserData, RegisterUserData } from 'interface/InterfaceUsers';
import { GetListPag, RegisterListPag, SetListPag } from "interface/InterfacePag";

import { SvgCategory, SvgClient, SvgClipBoard, SvgDashboardAbout, SvgDashboardAccess, SvgDashboardBudget, SvgDashboardExit, SvgDashboardPresentation, SvgDashboardProject, SvgDashboardService, SvgImg, SvgLogoTipo, SvgLogoTipo_2, SvgMenuHamburguer, SvgMenuPoint, SvgSignature, SvgText, SvgUser, SvgVideo } from 'components/SvgFile';

import { cookiesRegister, typeCookie, typeCookieEmail, typeCookiePage, typeCookiePassw } from 'dataFixed';

import PopUp_ShowFile from 'components/PopUp/ShowFile';
import PopUp_ShowData from 'components/PopUp/ShowData';
import PopUP_Confirmation from 'components/PopUp/Confirmation';

import Budget from 'components/Page/Budget';
import Services from 'components/Page/Services';
import Portfolio from 'components/Page/Portfolio';
import Budget_New from 'components/Page/Budget/New';
import AboutChroma from 'components/Page/AboutChroma';
import Presentation from 'components/Page/Presentation';
import Services_New from 'components/Page/Services/New';
import Portfolio_Project from 'components/Page/Portfolio/Project';
import Presentation_ShowDetails from 'components/Page/Presentation/ShowDetalis';

import { dragpoint } from 'services/functionDragover';
import Category from 'components/Page/Category';
import AccessDash from 'components/Page/AccessDash';
import AccessDash_Details from 'components/Page/AccessDash/Details';
import Client from 'components/Page/Client';
import Client_Details from 'components/Page/Client/New';
import Signature from 'components/Page/Signature';
import Report from 'components/Page/Report';
import PopUP_EditProfile from 'components/PopUp/EditProfile';
import Report_Clients from 'components/Page/Report/Clients';
import Report_Project from 'components/Page/Report/Project';
import Report_Presentation from 'components/Page/Report/Presentation';
import Report_Budget from 'components/Page/Report/Budget';

export default function Contents(){

    const cookies = new Cookies();
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));

    const [ userId, setUserId ]         = useState(GetUserData('id'));
    const [ userFile, setUserFile ]     = useState(GetUserData('file'));
    const [ userName, setUserName ]     = useState(GetUserData('name'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));
    const [ userPage, setUserPage ]     = useState(GetUserData('page'));

    const url      = new URLSearchParams(window.location.search);
    const idBudget = url.get("budget");

    const [ status, setStatus ] = useState(false);

    useEffect(()=>{
        RegisterListPag('currentPage', setCurrentPage);

        RegisterUserData('id', setUserId);
        RegisterUserData('file', setUserFile);
        RegisterUserData('name', setUserName);
        RegisterUserData('access', setUserAccess);
        RegisterUserData('page', setUserPage);

        if(idBudget > 0){
            SetListPag('currentPage', 'budget_details');
            SetListPag('currentPageId', idBudget);
        }
    }, []);

    useEffect(()=>{
        if(idBudget > 0){
            SetListPag('currentPage', 'budget_details');
            SetListPag('currentPageId', idBudget);
        }
    }, [idBudget]);

    function EditProfile(){
        SetModalState('Profile', true);
    }

    function CurrentPage(){
        switch(currentPage) {
            case "accessDash":
                return <AccessDash CkickPage={ CkickPage } />;
            case "accessDash_details":
                return <AccessDash_Details CkickPage={ CkickPage } />;

            case "about_chroma":
                return <AboutChroma CkickPage={ CkickPage } />;

            case "category":
                return <Category CkickPage={ CkickPage } />;

            case "client":
                return <Client CkickPage={ CkickPage } />;
            case "client_details":
                return <Client_Details CkickPage={ CkickPage } />;

            case "portfolio":
                return <Portfolio CkickPage={ CkickPage } />;
            case "project":
                return <Portfolio_Project CkickPage={ CkickPage } />;

            case "presentation":
                return <Presentation CkickPage={ CkickPage } />;
            case "presentation_details":
                return <Presentation_ShowDetails CkickPage={ CkickPage } />;

            case "services":
                return <Services CkickPage={ CkickPage } />;
            case "services_details":
                return <Services_New CkickPage={ CkickPage } />;

            case "budget":
                return <Budget CkickPage={ CkickPage } />;
            case "budget_details":
                return <Budget_New CkickPage={ CkickPage } />;
            case "signature":
                return <Signature CkickPage={ CkickPage } />;

            case "report":
                return <Report CkickPage={ CkickPage } />;
            case "report_clients":
                return <Report_Clients CkickPage={ CkickPage } />;
            case "report_project":
                return <Report_Project CkickPage={ CkickPage } />;
            case "report_presentation":
                return <Report_Presentation CkickPage={ CkickPage } />;
            case "report_budget":
                return <Report_Budget CkickPage={ CkickPage } />;

            default:
                return(
                    <div className="no_data">
                        Selecione uma opção do menu na lateral para continuar...
                    </div>
                )
        }
    }

    function CkickPage(value){
        SetListPag('currentPage', value);
    }

    function Logout(){
        SetListPag('page', 'index');
        cookies.set(typeCookie, 'index', '/', cookiesRegister);

        cookies.remove(typeCookieEmail, '', '/', cookiesRegister);
        cookies.remove(typeCookiePassw, '', '/', cookiesRegister);
        cookies.remove(typeCookiePage, '', '/', cookiesRegister);
    }

    return(
        <div className="Contents">
            <div className={ status == true ? "list_menu active_menu" : "list_menu" }>
                <div className="show_opt_menu">
                    <div className="show_data_top">

                        <div className="div_logo" onClick={ ()=>{ setStatus(!status) } }>
                            <div className={ status == true ? "div_logo_opt div_logo_opt_" : "div_logo_opt" }>
                                <div className="">
                                    {
                                        status == true ?
                                        <SvgLogoTipo_2 color="#ffffff" className="logotipo2" /> :
                                        <SvgLogoTipo color="#ffffff" className="logotipo" />
                                    }
                                </div>
                                {
                                    status == true ? null :
                                    <div className="">
                                        <SvgMenuHamburguer color="#ffffff" className="icons" />
                                    </div>
                                }
                            </div>
                        </div>

                        {
                            // currentPage == 'project' ?
                            // <div className={ status == true ? "opt_add_project opt_add_project_" : "opt_add_project" }>
                            //     <div className="list_opt_add">
                            //         <div className="show_option" draggable="true" onDragStart={ (event)=> { dragpoint(event, 'img') } }>
                            //             <SvgImg color="#ffffff" className="icons_project" />
                            //             <span className={ status == true ? "menu_close" : "" }>Imagem</span>
                            //         </div>
                            //     </div>

                            //     <div className={ status == true ? "list_opt_add list_opt_add_" : "list_opt_add" }>
                            //         <div className="show_option" draggable="true" onDragStart={ (event)=> { dragpoint(event, 'text') } }>
                            //             <SvgText color="#ffffff" className="icons_project" />
                            //             <span className={ status == true ? "menu_close" : "" }>Texto</span>
                            //         </div>
                            //         <div className="show_option" draggable="true" onDragStart={ (event)=> { dragpoint(event, 'video') } }>
                            //             <SvgVideo color="#ffffff" className="icons_project" />
                            //             <span className={ status == true ? "menu_close" : "" }>Vídeo</span>
                            //         </div>
                            //     </div>
                            // </div> :
                            <div className="opt_menu_dash">
                                {
                                    userAccess == 1 ?
                                    <>
                                        <div className={ currentPage == "accessDash" || currentPage == "accessDash_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("accessDash"); } }>
                                            <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                                <div className="div_icons">
                                                    <SvgDashboardAccess color="#FFC400" className="icons_dash" />
                                                </div>
                                                <div className={ status == true ? "menu_close" : "" }>Acesso a dashboard</div>
                                            </div>
                                        </div>

                                        <div className={ currentPage == "about_chroma" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("about_chroma"); } }>
                                            <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                                <div className="div_icons">
                                                    <SvgDashboardAbout color="#FFC400" className="icons_dash" />
                                                </div>
                                                <div className={ status == true ? "menu_close" : "" }>Sobre a Chroma</div>
                                            </div>
                                        </div>

                                        <div className={ currentPage == "category" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("category"); } }>
                                            <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                                <div className="div_icons">
                                                    <SvgCategory color="#FFC400" className="icons_dash" />
                                                </div>
                                                <div className={ status == true ? "menu_close" : "" }>Categoria</div>
                                            </div>
                                        </div>

                                        <div className={ currentPage == "client" || currentPage == "client_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("client"); } }>
                                            <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                                <div className="div_icons">
                                                    <SvgClient color="#FFC400" className="icons_dash" />
                                                </div>
                                                <div className={ status == true ? "menu_close" : "" }>Clientes</div>
                                            </div>
                                        </div>

                                        <div className={ currentPage == "portfolio" || currentPage == "project" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("portfolio"); } }>
                                            <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                                <div className="div_icons">
                                                    <SvgDashboardProject color="#FFC400" className="icons_dash" />
                                                </div>
                                                <div className={ status == true ? "menu_close" : "" }>Portfólio</div>
                                            </div>
                                        </div>

                                        <div className={ currentPage == "presentation" || currentPage == "presentation_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("presentation"); } }>
                                            <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                                <div className="div_icons">
                                                    <SvgDashboardPresentation color="#FFC400" className="icons_dash" />
                                                </div>
                                                <div className={ status == true ? "menu_close" : "" }>Apresentações</div>
                                            </div>
                                        </div>

                                        <div className={ currentPage == "services" || currentPage == "services_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("services"); } }>
                                            <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                                <div className="div_icons">
                                                    <SvgDashboardService color="#FFC400" className="icons_dash" />
                                                </div>
                                                <div className={ status == true ? "menu_close" : "" }>Serviços</div>
                                            </div>
                                        </div>

                                        <div className={ currentPage == "budget" || currentPage == "budget_details" || currentPage == "signature" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("budget"); } }>
                                            <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                                <div className="div_icons">
                                                    <SvgDashboardBudget color="#FFC400" className="icons_dash" />
                                                </div>
                                                <div className={ status == true ? "menu_close" : "" }>Orçamentos</div>
                                            </div>
                                        </div>
                                        <div className={ currentPage == "report" || currentPage == "report_clients" || currentPage == "report_project" || currentPage == "report_presentation" || currentPage == "report_budget" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("report"); } }>
                                            <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                                <div className="div_icons">
                                                    <SvgClipBoard color="#FFC400" className="icons_dash" />
                                                </div>
                                                <div className={ status == true ? "menu_close" : "" }>Relatório</div>
                                            </div>
                                        </div>
                                    </> :
                                    <>
                                        {
                                            userPage.map((key, index)=>{
                                                let iconMenu   = '';
                                                switch (key.page) {
                                                    case 'about_chroma':
                                                            iconMenu = <SvgDashboardAbout color="#FFC400" className="icons_dash" />;
                                                        break;

                                                    case 'category':
                                                            iconMenu = <SvgCategory color="#FFC400" className="icons_dash" />;
                                                        break;

                                                    case 'client':
                                                            iconMenu = <SvgClient color="#FFC400" className="icons_dash" />;
                                                        break;

                                                    case 'portfolio':
                                                            iconMenu = <SvgDashboardProject color="#FFC400" className="icons_dash" />;
                                                        break;

                                                    case 'presentation':
                                                            iconMenu = <SvgDashboardPresentation color="#FFC400" className="icons_dash" />;
                                                        break;

                                                    case 'services':
                                                            iconMenu = <SvgDashboardService color="#FFC400" className="icons_dash" />;
                                                        break;

                                                    case 'budget':
                                                            iconMenu = <SvgDashboardBudget color="#FFC400" className="icons_dash" />;
                                                        break;
                                                }

                                                let activeMenu = '';
                                                if(key.page == 'presentation' && currentPage == 'presentation_details'){
                                                    status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                                }else if(key.page == 'budget' && currentPage == 'budget_details'){
                                                    status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                                }else if(key.page == 'services' && currentPage == 'services_details'){
                                                    status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                                }else if(currentPage == key.page){
                                                    status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                                }else {
                                                    status == true ? activeMenu = "show_menu_dash show_menu_dash_": activeMenu = "show_menu_dash";
                                                }

                                                return(
                                                    <div className={ activeMenu } onClick={ ()=>{ CkickPage(key.page); } } key={ index }>
                                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                                            <div className="div_icons">
                                                                { iconMenu }
                                                            </div>
                                                            <div className={ status == true ? "menu_close" : "" }>
                                                                { key.name }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                }

                                <div className={ status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ Logout() } }>
                                    <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                        <div className="div_icons">
                                            <SvgDashboardExit color="#FFC400" className="icons_dash" />
                                        </div>
                                        <div className={ status == true ? "menu_close" : "" }>Sair</div>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                    <div className="div_profile">
                        <div className={ status == true ? "div_profile_data div_profile_data_" : "div_profile_data" } onClick={ ()=>{ EditProfile() } }>
                            <div className="div_img">
                                {
                                    userFile !='' ?
                                    <img alt="profile user" src={ "./assets/login/" + userFile } className="show_img_profile" /> :
                                    <SvgUser color="#FFFFFF" className="show_img_profile" />
                                }
                            </div>
                            <div className={ status == true ? "name_user menu_close" : "name_user" }>
                                { userName }
                            </div>
                            <div className={ status == true ? "open_profile menu_close" : "open_profile" }>
                                <SvgMenuPoint color="#ffffff" className="icons" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="list_data">
                <div className="show_data">
                    { CurrentPage() }
                </div>
            </div>
            <PopUp_ShowData />
            <PopUP_Confirmation />
            <PopUP_EditProfile />
            <PopUp_ShowFile />
        </div>
    )
}
