import { useState, useEffect, useRef } from "react";

import './ShowDetails.css';

import Select from 'react-select';

import Cookies from 'universal-cookie';

import JoditEditor from 'jodit-react';
import { config, cookiesRegister } from "dataFixed";

import { SetModalData, SetModalState } from "interface/InterfacePopUp";
import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";
import { GetListPag, RegisterListPag, SetListPag } from "interface/InterfacePag";

import { SvgAddData, SvgBehance, SvgDashboardAbout, SvgDelete, SvgEye, SvgFolder, SvgGoogleDrive, SvgLink, SvgLink_opt2, SvgLockClose, SvgReturn, SvgStatus, SvgTriagle } from "components/SvgFile";

import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";
import { Reg_EditPresentation } from "services/RegisterData";

import TextareaAutosize from 'react-textarea-autosize';
import PopUP_NewProject from "components/PopUp/NewProject";

export default function Presentation_ShowDetails(){
    const cookies = new Cookies();

    const editText = useRef();

    const [ dataPage, setDataPage ]     = useState(GetDataPage('about_chroma'));
    const [ showClient, setShowClient ] = useState(GetDataPage("client"));

    const [ aboutText, setAboutText ]     = useState(InitialDataAbout('description'));
    const [ aboutServ, setAboutServ ]     = useState(InitialDataAbout('servicesOffered'));
    const [ aboutAwards, setAboutAwards ] = useState(InitialDataAbout('awards'));

    const [ loading, setLoading ]   = useState(false);
    const [ showData, setShowData ] = useState(GetDataPage('presentation'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ status, setStatus ]           = useState(InitialData('status'));
    const [ client, setClient ]           = useState(InitialData('client'));
    const [ newClient, setNewClient ]     = useState('');
    const [ title, setTitle ]             = useState(InitialData('title'));
    const [ description, setDescription ] = useState(InitialData('description'));
    const [ nameUser, setNameUser ]       = useState(InitialData('name'));
    const [ date, setDate ]               = useState(InitialData('date_br'));
    const [ project, setProject ]         = useState(InitialData('project'));
    const [ showCG, setShowCG ]           = useState(InitialData('show_cg'));
    const [ budget, setBudget ]           = useState(InitialData('budget'));
    const [ listLink, setListLink ]       = useState(InitialData('listLink'));
    const [ meetingMinutes, setMeetingMinutes ] = useState(InitialData('meetingMinutes'));

    const [ altStatus, setAltStatus ] = useState(false);

    useEffect(()=>{
        RegisterListPag('currentPageId', setIdPage);

        RegisterDataPage("client", setShowClient);
        RegisterDataPage('presentation', setShowData);
    }, []);

    useEffect(()=>{
        RegisterListPag('currentPageId', setIdPage);
        RegisterDataPage('presentation', setShowData);
        RegisterDataPage("client", setShowClient);

        setStatus(InitialData('status'));
        setTitle(InitialData('title'));
        setClient(InitialData('client'));
        setDescription(InitialData('description'));
        setNameUser(InitialData('name'));
        setDate(InitialData('date_br'));
        setProject(InitialData('project'));
        setListLink(InitialData('listLink'));
        setMeetingMinutes(InitialData('meetingMinutes'));
    }, [showData]);

    function InitialDataAbout(type){
        switch (type) {
            case 'description':
                return dataPage[type];

            case 'servicesOffered': case 'awards':
                return dataPage[type];
        }

    }

    function InitialData(type){
        const newData = showData.find(item => item.id == idPage);
        if(type == 'client'){
            const dataClient = showClient.find(item => item.id == newData['client']);
            if(dataClient){
                return { "value": dataClient.id, "label": dataClient.name };
            }
            let nameClient = newData['name_client'] + " -- cliente desativado"
            return { "value": newData['client'], "label": nameClient };
        }
        return newData[type]
    }

    function ShowClient(){
        const options = [{ "value": "add", "label": "Adicionar novo cliente" }]
        showClient.map((key, index)=>{
            options.push({
                "value": key.id, "label": key.name
            });
        })
        return options;
    }

    function Delete(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "presentation", "type": "delete_data", "name": value });
        SetModalState('Confirmation', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'presentation');
        SetListPag('currentPageId', 'remuve');
    }

    function StatusProject(value){
        switch (value) {
            case 'Ativo':
                return(
                    <div className="status" style={ { color: '#00BC08', backgroundColor: 'rgba(0, 188, 8, 0.2)' }} onClick={ ()=>{ setAltStatus(!altStatus); } }>
                        <SvgStatus color="#00BC08" color1="#00BC08" className="icon_status" />
                        Ativo
                    </div>
                )

            case 'Pausado':
                return(
                    <div className="status" style={ { color: '#BC9300', backgroundColor: 'rgba(188, 147, 0, 0.2)' }} onClick={ ()=>{ setAltStatus(!altStatus); } }>
                        <SvgStatus color="#BC9300" color1="#BC9300" className="icon_status" />
                        Pausado
                    </div>
                )

            case 'Inativo':
                return(
                    <div className="status" style={ { color: '#868686', backgroundColor: 'rgba(134, 134, 134, 0.2)' }} onClick={ ()=>{ setAltStatus(!altStatus); } }>
                        <SvgStatus color="#868686" color1="#868686" className="icon_status" />
                        Inativo
                    </div>
                )

            case 'Recusado':
                return(
                    <div className="status" style={ { color: '#AE1B1B', backgroundColor: 'rgba(174, 27, 27, 0.2)' }} onClick={ ()=>{ setAltStatus(!altStatus); } }>
                        <SvgStatus color="#AE1B1B" color1="#AE1B1B" className="icon_status" />
                        Recusado
                    </div>
                )

            case 'Concluído':
                return(
                    <div className="status" style={ { color: '#3A36FD', backgroundColor: 'rgba(58, 54, 253, 0.2)' }} onClick={ ()=>{ setAltStatus(!altStatus); } }>
                        <SvgStatus color="#3A36FD" color1="#3A36FD" className="icon_status" />
                        Concluído
                    </div>
                )
        }

    }

    function HandleStatus(index, value){
        const newData = [...aboutServ];
        newData[index].status = value;
        setAboutServ(newData);
    }

    function HandleDataPresentation(index, value){
        const newData = [...project];
        newData[index].text = value;
        setProject(newData);
    }

    function NewProject(){
        SetModalData("NewProject", { "id": idPage });
        SetModalState('NewProject', true);
    }

    function NewLink(){
        setListLink([...listLink, { "id": 0, "link": "" }])
    }

    function HandleLink(index, value){
        const newData = [...listLink];
        newData[index].link = value;
        setListLink(newData);
    }

    function DeleteLink(id, index){
        if(id == 0){
            const newData = [...listLink];
            newData.splice(index, 1);
            setListLink(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "presentation", "type": "delete_link", "name": "Link de referência" });
            SetModalState('Confirmation', true);
        }
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_EditPresentation(idPage, showCG, client.value, newClient, title, status, meetingMinutes, description, listLink, project, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function RegisterBudget(){
        SetListPag('currentPage', 'budget_details');
        SetListPag('currentPageId', budget);
        SetListPag('idNewRegister', idPage);
    }

    function OpenProject(id){
        let pageCookies  = "project_" + id;
        let minutesValid = new Date(new Date().getTime() + 60 * 60 * 3600);
        cookies.set(pageCookies, id, { path: '/', expires: minutesValid }, cookiesRegister);
        window.open('preview.html?project=' + id, '_blank');
    }

    return (
        <div className="ShowDetails">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>

            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() } }>
                        <SvgReturn color="#324d6b" className="icons" />
                        Voltar
                    </div>
                    <div className="name_project" />
                    <div className="list_opt_alt_page">
                        {
                            idPage == 0 ? null :
                            <div>
                                <a href={ "./presentation.html?project=" + idPage } target="_blank">
                                    <div className="icons_project">
                                        <SvgEye color="#324d6b" className="icons" />
                                    </div>
                                </a>
                            </div>
                        }
                        {
                            budget == 0 ?
                            <div className="new_block_text new_project new_budget" onClick={ ()=>{ RegisterBudget() } }>
                                +Orçamento
                            </div> :
                            <div className="new_block_text new_project show_budget" onClick={ ()=>{ RegisterBudget() } }>
                                Ver orçamento
                            </div>
                        }
                        <div className="div_restricted">
                            <div className="name_restricted">
                                Exibir bloco<br />
                                Sobre a CG?
                            </div>

                            <label className="switch">
                                <div className="switch_wrapper">
                                    <input type="checkbox" onChange={ (e)=>{ setShowCG(!showCG) } } checked={ showCG } />
                                    <span className="switch_button" />
                                </div>
                            </label>
                        </div>
                        <button className="new_block_text new_project">
                            Salvar
                        </button>
                    </div>
                </div>
                <div className="show_data_register">

                    <div className="div_show_text">
                        <div className="div_data_project">
                            <div className="div_input space_data">
                                <Select className="space_data" defaultOptions options={ ShowClient() } value={ client } onChange={ (e)=> { setClient(e); } } placeholder="Cliente..." />
                                <span className="span_search_select">Cliente</span>
                            </div>

                            <div className="div_input space_data">
                                <input type="text" className="" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" required />
                                <span className="span_name_input">Título</span>
                            </div>

                            <div className="list_status">
                                {
                                    StatusProject(status)
                                }
                                {
                                    altStatus == true ?
                                    <div className="opt_status">
                                        {
                                            status == 'Ativo' ? null :
                                            <div className="status sub_" onClick={ ()=>{ setStatus('Ativo'); setAltStatus(false); } }>
                                                <SvgStatus color="#00BC08" color1="#00BC08" className="icon_status" />
                                                Ativo
                                            </div>
                                        }
                                        {
                                            status == 'Pausado' ? null :
                                            <div className="status sub_" onClick={ ()=>{ setStatus('Pausado'); setAltStatus(false); } }>
                                                <SvgStatus color="#BC9300" color1="#BC9300" className="icon_status" />
                                                Pausado
                                            </div>
                                        }
                                        {
                                            status == 'Inativo' ? null :
                                            <div className="status sub_" onClick={ ()=>{ setStatus('Inativo'); setAltStatus(false); } }>
                                                <SvgStatus color="#868686" color1="#868686" className="icon_status" />
                                                Inativo
                                            </div>
                                        }
                                        {
                                            status == 'Recusado' ? null :
                                            <div className="status sub_" onClick={ ()=>{ setStatus('Recusado'); setAltStatus(false); } }>
                                                <SvgStatus color="#AE1B1B" color1="#AE1B1B" className="icon_status" />
                                                Recusado
                                            </div>
                                        }
                                        {
                                            status == 'Concluído' ? null :
                                            <div className="status sub_" onClick={ ()=>{ setStatus('Concluído'); setAltStatus(false); } }>
                                                <SvgStatus color="#3A36FD" color1="#3A36FD" className="icon_status" />
                                                Concluído
                                            </div>
                                        }
                                    </div> : null
                                }
                            </div>
                        </div>
                        {
                            client.value == 'add' ?
                            <div className="div_data_project space_block">
                                <div className="div_input space_data">
                                    <input type="text" maxLength="140" value={ newClient } onChange={ (e)=>{ setNewClient(e.target.value) } } placeholder="" />
                                    <span className="span_name_input">Nome do novo cliente</span>
                                </div>
                            </div> : null
                        }
                        <div className="div_data_project space_block">
                            <div className="div_input space_data">
                                <input type="text" className="" onChange={ (e)=>{ setMeetingMinutes(e.target.value) } } value={ meetingMinutes } />
                                <span className="span_name_input">Link Ata Reunião</span>
                            </div>
                        </div>
                        <div className="show_title">
                            <div className="">Descrição</div>
                        </div>
                        <div className="show_textarea">
                            <JoditEditor ref={ editText } config={ config } value={ description ? description.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setDescription(newContent) } />
                        </div>
                    </div>

                    {
                        showCG == true ?
                        <>
                            <div className="icon_title_project space_block">
                                <div className="">
                                    <SvgDashboardAbout color="#E51D4D" className="icon_folder" />
                                </div>
                                <div className="name_project">Sobre a Chroma</div>
                            </div>
                            <div className="div_show_or_new_data div_show_text">
                                <div className="show_title">
                                    <div className="title_div">Descrição</div>
                                </div>
                                <div className="div_show_text space_show_data">
                                    <div className="div_text" dangerouslySetInnerHTML={ { __html: aboutText !='' ? aboutText.replaceAll('&#34;', '"') : "" } } />
                                </div>
                                <div className="show_title">
                                    <div className="title_div">Serviços oferecidos</div>
                                </div>
                                <div className="div_show_text space_show_data div_data_about">
                                    {
                                        aboutServ.map((key, index)=>{
                                            return(
                                                <div className="show_data_about" key={ index }>
                                                    <div className="div_about_img">
                                                        <img src={ "./assets/about/" + key.img } className="about_img" />
                                                        <div className="about_link">
                                                            <a href={ key.link } target="_blank">
                                                                <SvgLink color="#ffffff" className="icon_link" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className={ key.status == true ? "div_about_title" : "div_about_title about_radius" } onClick={ ()=>{ HandleStatus(index, !key.status) } }>
                                                        { key.title }
                                                        {
                                                            key.status == true ?
                                                            <SvgTriagle color="#747474" className="icon_select_open" /> :
                                                            <SvgTriagle color="#747474" className="icon_select_close" />
                                                        }
                                                    </div>
                                                    {
                                                        key.status == true ?
                                                        <div className="div_about_title about_text about_radius" dangerouslySetInnerHTML={ { __html: key.text !='' ? key.text.replaceAll('&#34;', '"') : "" } } />
                                                        : null
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="show_title">
                                    <div className="title_div">Premiações</div>
                                </div>
                                <div className="div_show_text space_show_data div_data_about">
                                    {
                                        aboutAwards.map((key, index)=>{
                                            return(
                                                <div className="show_data_about" key={ index }>
                                                    <div className="div_about_img">
                                                        <img src={ "./assets/about/" + key.img } className="about_img" />
                                                    </div>
                                                    <div className="div_about_title about_radius">{ key.title }</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </>
                        : null
                    }

                    <div className="icon_title_project space_block">
                        <div className="">
                            <SvgLink_opt2 color="#E51D4D" className="icon_folder" />
                        </div>
                        <div className="name_project">Links de referência</div>
                        <div className="add_new_project">
                            <div className="new_block_text new_project new_budget" onClick={ ()=>{ NewLink() } }>
                                Adiconar novo link
                            </div>
                        </div>
                    </div>
                    <div className="list_link space_block">
                        <div className="div_show_text">
                            {
                                listLink.length > 0 ?
                                    listLink.map((key, index)=>{
                                        return(
                                            <div className="div_data_project space_block" key={ index }>
                                                <div className="div_input space_data">
                                                    <input type="text" className="" onChange={ (e)=>{ HandleLink(index, e.target.value) } } value={ key.link.replaceAll(' ', '') } />
                                                    <span className="span_name_input">Link { index + 1}</span>
                                                </div>
                                                <div className="div_input" onClick={ ()=>{ DeleteLink(key.id, index) } }>
                                                    <SvgDelete color="#F00000" className="icons" />
                                                </div>
                                            </div>
                                        )
                                    })
                                : <div className="no_data">Nenhum link adicionado até o momento...</div>
                            }
                        </div>
                    </div>

                    <div className="icon_title_project space_block">
                        <div className="">
                            <SvgFolder color="#E51D4D" className="icon_folder" />
                        </div>
                        <div className="name_project">Projetos</div>
                        <div className="add_new_project">
                            <div className="new_block_text new_project new_budget" onClick={ ()=>{ NewProject() } }>
                                Adiconar novo projeto
                            </div>
                        </div>
                    </div>
                    <div className="list_portfolio">
                        <div className="div_show_text">
                            <div className="list_portfolio_new_presentation">
                                <div className="list_project">
                                    {
                                        project.length > 0 ?
                                            project.map((key, index)=>{
                                                return(
                                                    <div className="div_project_data" key={ index }>
                                                        <div className="project_data_inf">
                                                            <div className="project_data_client">{ key.name_client }</div>
                                                            <div className="project_data_show_opt">
                                                                <div className="project_data_delete" onClick={ ()=>{ Delete(key.id_presentation, key.project) }}>
                                                                    <SvgDelete color="#ffffff" className="icons" />
                                                                </div>
                                                                {
                                                                    key.link_behance !='' ?
                                                                    <a href={ key.link_behance } target="_blank">
                                                                        <div className="project_data_behance">
                                                                            <SvgBehance color="#ffffff" className="icons" />
                                                                        </div>
                                                                    </a> : null
                                                                }
                                                                {
                                                                    key.link_drive !='' ?
                                                                    <a href={ key.link_drive } target="_blank">
                                                                        <div className="project_data_link_drive">
                                                                            <SvgGoogleDrive color="#ffffff" className="icons" />
                                                                        </div>
                                                                    </a> : null
                                                                }
                                                                <div className="project_data_eye" onClick={ ()=>{ OpenProject(key.id) } }>
                                                                    <SvgEye color="#ffffff" className="icons" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            key.restricted == false ? null :
                                                            <div className="project_data_lock_close">
                                                                <SvgLockClose color="#FFFFFF" className="lock" />
                                                            </div>
                                                        }
                                                        <div className="project_data_div_img">
                                                            <img alt="show img" src={ "./assets/project/" + key.file } className="project_data_img" />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        : <div className="no_data">Nenhum projeto adicionado até o momento...</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
            <PopUP_ReturnResponse />
            <PopUP_NewProject />
        </div>
    )
}
