import { useState, useEffect } from "react";

import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";
import { GetListPag, RegisterListPag } from "interface/InterfacePag";
import { SetModalData, SetModalState, RegisterModalData } from "interface/InterfacePopUp";

import { SvgImg } from "components/SvgFile";

export default function Img(props){

    const [ showData, setShowData ] = useState(GetDataPage('portfolio'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ name, setName ]           = useState(HandleData('name'));
    const [ file, setFile ]           = useState(HandleData('file'));
    const [ stateFile, setStateFile ] = useState(false);

    useEffect(()=>{
        RegisterListPag('currentPageId', setIdPage);
        RegisterListPag('portfolio', setShowData);
    }, []);

    useEffect(()=>{
        setShowData(GetDataPage('portfolio'));
        setIdPage(GetListPag('currentPageId'));

        setName(HandleData('name'));
        setFile(HandleData('file'));

        setStateFile(false);
    }, [showData]);

    useEffect(()=>{
        setShowData(GetDataPage('portfolio'));
        setIdPage(GetListPag('currentPageId'));

        setName(HandleData('name'));
        setFile(HandleData('file'));
    }, [idPage]);
    console.log(props.id);
    function HandleData(type){
        if(idPage !=0){
            const newData      = showData.find(item => item.id == idPage);
            if(newData.contents){
                if(props.id_content !=0){
                    const dataContents = newData.contents.find(item => item.id == props.id_content);
                    const showDataContent = dataContents.contents.find(item => item.id == props.id_data);
                    return showDataContent[type];
                }
                return '';
            }
            return '';
        }
        return '';
    }

    function handleName(value){
        setName(value);
        props.RegisterData({
            "id": props.id_data,
            "id_content": props.id_content,
            "height": "center",
            "width": "left",
            "type": "img",
            "status": false,
            "name": value,
            "file": file,
            "text": "",
            "type_video": "",
            "title": "",
            "link": ""
        }, props.index_0, props.index_1);
    }

    function handleFile(value){
        setFile(value);
        if(value){
            setStateFile(true);
        }else {
            setStateFile(false);
        }
        props.RegisterData({
            "id": props.id_data,
            "id_content": props.id_content,
            "height": "center",
            "width": "left",
            "type": "img",
            "status": false,
            "name": name,
            "file": value,
            "text": "",
            "type_video": "",
            "title": "",
            "link": ""
        }, props.index_0, props.index_1);
    }

    function OpenFile(type, value){
        SetModalData("ShowFile", { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    return(
        <div className="DefaultDiv">
            <div className="div_data_project">
                <div className="div_input space_top">
                    <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ handleName(e.target.value) } } value={ name } />
                    <span className="span_name_input">Nome</span>
                </div>
                <div className="div_input space_top">
                    <div className={ stateFile == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                        <label>
                            { stateFile == true ? "Imagem adicionada" : "Adicionar imagem" }
                            <input type="file" onChange={ (e)=>{ handleFile(e.target.files[0]); } } accept="image/*" />
                        </label>
                    </div>
                </div>
                {
                    file !='' ?
                        stateFile == true ?
                        <div className="div_input space_top" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                            <SvgImg color="#324d6b" className="icons_project"/>
                        </div> :
                        <div className="div_input space_top" onClick={ ()=>{ OpenFile('showImg', "img/" + file) } }>
                            <SvgImg color="#324d6b" className="icons_project"/>
                        </div>
                    : null
                }
            </div>
        </div>
    )
}
