import { useState, useEffect, useRef } from "react";

import './AboutChroma.css';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SvgAddData, SvgDelete, SvgImg, SvgSave, SvgSetaRight } from "components/SvgFile";
import { SetModalData, SetModalState } from "interface/InterfacePopUp";
import { Reg_About } from "services/RegisterData";

import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";
import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";

export default function AboutChroma(){

    const editText = useRef();

    const [ loading, setLoading ]   = useState(false);
    const [ dataPage, setDataPage ] = useState(GetDataPage('about_chroma'));

    const [ description, setDescription ] = useState(InitialData('description'));
    const [ servOffered, setServOffered ] = useState(InitialData('servicesOffered'));
    const [ awards, setAwards ]           = useState(InitialData('awards'));

    useEffect(()=>{
        RegisterDataPage('about_chroma', setDataPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('about_chroma'));

        setDescription(InitialData('description'));
        setServOffered(InitialData('servicesOffered'));
        setAwards(InitialData('awards'));
    }, [dataPage]);

    function InitialData(type){
        return dataPage[type];
    }

    function AddNewData(type){
        switch (type) {
            case 'servicesOffered':
                    setServOffered([...servOffered, { "id": 0, "title": "", "img": "", "state": false, "text": "", "link": "" }]);
                break;

            case 'awards':
                    setAwards([...awards, { "id": 0, "title": "", "img": "", "state": false }]);
                break;
        }
    }

    function OpenFile(type, value){
        SetModalData("ShowFile", { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    function Delete(type, index, id, value){
        switch (type) {
            case 'servicesOffered':
                    if(id == 0){
                        const newData = [...servOffered];
                        newData.splice(index, 1);
                        setServOffered(newData);
                    }else {
                        SetModalData("Confirmation", { "id": id, "origin": "about_chroma", "type": "delete_data", "name": value });
                        SetModalState('Confirmation', true);
                    }
                break;

            case 'awards':
                    if(id == 0){
                        const newData = [...awards];
                        newData.splice(index, 1);
                        setAwards(newData);
                    }else {
                        SetModalData("Confirmation", { "id": id, "origin": "about_chroma", "type": "delete_data", "name": value });
                        SetModalState('Confirmation', true);
                    }
                break;
        }
    }

    function HandleData(type, input, index, value){
        switch (type) {
            case 'servicesOffered':
                    const newData_1 = [...servOffered];
                    newData_1[index][input] = value;
                    if(input == 'img'){
                        if(value){
                            newData_1[index]['status'] = true;
                        }else {
                            newData_1[index]['status'] = false;
                        }
                    }
                    setServOffered(newData_1);
                break;

            case 'awards':
                    const newData_2 = [...awards];
                    newData_2[index][input] = value;
                    if(input == 'img'){
                        if(value){
                            newData_2[index]['status'] = true;
                        }else {
                            newData_2[index]['status'] = false;
                        }
                    }
                    setAwards(newData_2);
                break;
        }
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_About(description, servOffered, awards, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    return (
        <div className="AboutChroma">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            Sobre a Chroma
                        </div>
                    </div>
                    <button className="new_block_text new_project">
                        <div className="">
                            <SvgSave color="#ffffff" className="icons" />
                        </div>
                        <div className="">
                            Salvar
                        </div>
                    </button>
                </div>
                <div className="show_data_register">

                    <div className="div_show_text">
                        <div className="show_title">
                            <div className="">Descrição</div>
                        </div>
                        <div className="show_textarea">
                            <JoditEditor ref={ editText } config={ config } value={ description ? description.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setDescription(newContent) } />
                        </div>
                    </div>

                    <div className="div_show_or_new_data div_show_text">
                        <div className="show_title">
                            <div className="title_div">Serviços oferecidos</div>
                        </div>
                        <div className="div_show_text space_show_data">
                            {
                                servOffered.length > 0 ?
                                    servOffered.map((key, index)=>{
                                        return(
                                            <div className="list_opt_add show_data_add" key={ index }>
                                                <div className="div_data_project ">
                                                    <div className="div_input">
                                                        <input type="text" className="width_input" onChange={ (e)=>{ HandleData('servicesOffered', 'title', index, e.target.value) } } value={ key.title } maxLength="140" />
                                                        <span className="span_name_input">Título</span>
                                                    </div>
                                                    <div className={ key.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                        <label>
                                                            { key.status == true ? "Imagem adicionada" : "Adicionar imagem" }
                                                            <input type="file" onChange={ (e)=>{ HandleData('servicesOffered', 'img', index, e.target.files[0]); } } accept="image/*" />
                                                        </label>
                                                    </div>
                                                    {
                                                        key.img !='' ?
                                                            key.status == true ?
                                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.img)) } }>
                                                                <SvgImg color="#324d6b" className="icons"/>
                                                            </div> :
                                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "about/" + key.img) } }>
                                                                <SvgImg color="#324d6b" className="icons"/>
                                                            </div>
                                                        : null
                                                    }
                                                    <div onClick={ ()=>{ Delete('servicesOffered', index, key.id, key.title) } } style={ { display: "flex" } }>
                                                        <SvgDelete color="#f00000" className="icon" />
                                                    </div>
                                                </div>
                                                <div className="div_data_project show_textarea">
                                                    <div className="div_input show_textarea">
                                                        <input type="text" className="width_input" onChange={ (e)=>{ HandleData('servicesOffered', 'link', index, e.target.value) } } value={ key.link.replaceAll(' ', '') } />
                                                        <span className="span_name_input">Link</span>
                                                    </div>
                                                </div>
                                                <div className="div_data_project show_textarea">
                                                    <div className="div_input show_textarea">
                                                        <div className="show_textarea">
                                                            <JoditEditor config={ config } value={ key.text ? key.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('servicesOffered', 'text', index, newContent) } />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                : null
                            }
                            <div className="add_project">
                                <div className="add_data" onClick={ ()=>{ AddNewData('servicesOffered'); } }>
                                    <SvgAddData color="#F00000" className="icons" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="div_show_or_new_data div_show_text">
                        <div className="show_title">
                            <div className="title_div">Premiações</div>
                        </div>
                        <div className="div_show_text space_show_data">
                            {
                                awards.length > 0 ?
                                    awards.map((key, index)=>{
                                        return(
                                            <div className="list_opt_add show_data_add" key={ index }>
                                                <div className="div_input">
                                                    <input type="text" className="width_input" onChange={ (e)=>{ HandleData('awards', 'title', index, e.target.value) } } value={ key.title } />
                                                    <span className="span_name_input">Título</span>
                                                </div>

                                                <div className={ key.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        { key.status == true ? "Imagem adicionada" : "Adicionar imagem" }
                                                        <input type="file" onChange={ (e)=>{ HandleData('awards', 'img', index, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    key.img !='' ?
                                                        key.status == true ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.img)) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "about/" + key.img) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div>
                                                    : null
                                                }
                                                <div onClick={ ()=>{ Delete('awards', index, key.id, key.title) } } style={ { display: "flex" } }>
                                                    <SvgDelete color="#f00000" className="icons" />
                                                </div>
                                            </div>
                                        )
                                    })
                                : null
                            }
                            <div className="add_project">
                                <div className="add_data" onClick={ ()=>{ AddNewData('awards'); } }>
                                    <SvgAddData color="#F00000" className="icons" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <PopUP_ReturnResponse />
        </div>
    )
}
