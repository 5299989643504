import { useState, useEffect } from "react";

import './Signature.css';

import { SvgAddOpt_2, SvgDelete, SvgEdit, SvgReturn, SvgSearch, SvgSignature, SvgTriagle } from "components/SvgFile";

import { SetListPag } from "interface/InterfacePag";
import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";
import { SetModalData, SetModalState } from "interface/InterfacePopUp";

import PopUp_Signature from "components/PopUp/Signature";

export default function Signature(){

    const [ search, setSearch ]     = useState('');
    const [ dataPage, setDataPage ] = useState(GetDataPage('signature'));
    const [ type, setType ]         = useState('All');
    const [ status, setStatus ]     = useState(false);

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(dataPage.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = dataPage.slice(startItens, endItens);

    useEffect(()=>{
        RegisterDataPage('signature', setDataPage);
    }, []);

    function SearchInput(value){
        const newData   = [];
        if(value !=''){
            {
                GetDataPage("signature").forEach(item =>{
                    if(item.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                })
            }
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
            setDataPage(duplicate);

        }else if(value == ''){
            setDataPage(GetDataPage("signature"));
        }
        setSearch(value);
    }

    function EditData(id){
        SetModalData("Signature", { "id": id });
        SetModalState('Signature', true);
    }

    function OpenFile(type, value){
        SetModalData("ShowFile", { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    function DeleteData(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "signature", "type": "delete_data", "name": value });
        SetModalState('Confirmation', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'budget');
    }

    return (
        <div className="Signature">
            <div className="new_block">
                <div className="div_return" onClick={ ()=>{ ReturnPage() } }>
                    <SvgReturn color="#324d6b" className="icons" />
                    Voltar
                </div>
                <div className="name_project">
                    Assinaturas
                </div>
                <div className="list_opt_alt_page">
                    <div className="new_block_text new_project" onClick={ ()=>{ EditData(0) } }>
                        <SvgAddOpt_2 color="#ffffff" className="icons" />
                        Assinatura
                    </div>
                </div>
            </div>
            <div className="list_signature">
                <table>
                    <tbody>
                        <tr>
                            <th width="20">#</th>
                            <th>Nome</th>
                            <th width="20" align="right">#</th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                                currentItens.map((key, index)=>{
                                    return(
                                        <tr key={ index }>
                                            <td>{ startItens + index + 1 }</td>
                                            <td>{ key.name }</td>
                                            <td align="right">
                                                <div className="list_opt">
                                                    <div onClick={ ()=>{ OpenFile('showImg', "signature/" + key.img) } }>
                                                        <SvgSignature color="#324d6b" className="icons"/>
                                                    </div>
                                                    <div onClick={ ()=>{ DeleteData(key.id, key.name) } }>
                                                        <SvgDelete color="#F00000" className="icons"/>
                                                    </div>
                                                    <div onClick={ ()=>{ EditData(key.id) } }>
                                                        <SvgEdit color="#324d6b" className="icons"/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            :
                            <tr>
                                <td colSpan="7">
                                    <div className="no_data">Nenhuma assinatura encontrada...</div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {
                    dataPage.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
            <PopUp_Signature />
        </div>
    )
}
