import React, { useState, useEffect } from "react";

import './Confirmation.css';

import { SetModalState, RegisterModalData, RegisterModalObserver } from "interface/InterfacePopUp";
import { Reg_CopyBudget, Reg_Delete } from "services/RegisterData";

export default function PopUP_Confirmation(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    useEffect(()=>{
        RegisterModalData('Confirmation', setModaldata);
        RegisterModalObserver('Confirmation', setShowPopUp);
    }, []);

    function TypeAlt(){
        switch (modalData.type) {
            case "copy_budget":
                    Reg_CopyBudget(modalData.id, modalData.idUser, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() })
                break;

            case "update_json":
                break;

            default:
                    Reg_Delete(modalData.id, modalData.origin, modalData.type, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
                break;
        }
        SetModalState('Confirmation', false);
    }

    function CallbackSuccess(){
    }

    function CallbackError(){
    }

    function ClosePopUp(){
        SetModalState('Confirmation', false);
    }

    function TypeRemoval(){
        switch(modalData.type){
            case "update_json":
                return "Atualizar plataforma";
            case "disabled_access_dash":
                return (
                    <div>
                        Desativar usuário:
                        <div>{ modalData.name }</div>
                    </div>
                );
            case "reactivate_access_dash":
                return (
                    <div>
                        Reativar usuário:
                        <div>{ modalData.name }</div>
                    </div>
                );
            case "copy_budget":
                return (
                    <div>
                        Copiar orçamento:
                        <div>{ modalData.name }</div>
                    </div>
                );
            default:
                return (
                    <div>
                        Deletar:
                        <div>{ modalData.name }</div>
                    </div>
                );
        }
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp_Confirmation">
                    <div className="all">
                        <div className="div_data">
                            <div className="popup_title">
                                { TypeRemoval() }
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div onClick={ ()=>{ TypeAlt(); } }>
                                    <div className="opt_select yes_update">Sim</div>
                                </div>
                                <div onClick={ ()=>{ ClosePopUp(); } }>
                                    <div className="opt_select not_update">Não</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
