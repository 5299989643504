import React, { useState, useEffect } from "react";

import './ShowData.css';

import { SvgClose } from "components/SvgFile";

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/InterfacePopUp";
import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";
import { Reg_Category } from "services/RegisterData";

export default function PopUp_ShowData(props){

    const [ modalData, setModaldata ]     = useState({});
    const [ showPopUp, setShowPopUp ]     = useState(false);
    const [ dataPage, setDataPage ] = useState(GetDataPage('category'));

    const [ name, setName ]       = useState('');
    const [ nameAnt, setNameAnt ] = useState('');
    const [ type, setType ]       = useState('type');

    useEffect(()=>{
        RegisterModalData('ShowData', setModaldata);
        RegisterModalObserver('ShowData', setShowPopUp);

        RegisterDataPage('category', setDataPage);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setName(InitialData('name'));
            setNameAnt(InitialData('name'));
            setType(InitialData('type'));
        }
    }, [showPopUp]);

    function InitialData(type){
        if(modalData.type == 'edit'){
            const newData = dataPage.find(item => item.name == modalData.name);
            return newData[type];
        }
        if(type == 'type'){
            return 'Público';
        }
        return '';
    }

    function ClosePopUp(){
        SetModalState('ShowData', false);
    }

    function SaveData(){
        Reg_Category(modalData.type, type, nameAnt, name, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        ClosePopUp();
    }

    function CallbackError(){
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all popup_category">
                        <div className="div_data type_div">
                            <div className="title">
                                Categoria
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="show_input">
                                    <div className="div_input space_top div_type">
                                        <select className="type_category" onChange={ (e)=>{ setType(e.target.value); } } value={ type }>
                                            <option value="Público">Público</option>
                                            <option value="Privado">Privado</option>
                                        </select>
                                        <span className="span_search_select">Tipo</span>
                                    </div>
                                    <div className="div_input space_top space_data">
                                        <input type="text" className="div_input space_data" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="140" />
                                        <span className="span_name_input">Nome</span>
                                    </div>
                                </div>
                                <div className="register_data button_category" onClick={ ()=>{ SaveData() } }>
                                    Cadastrar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
