import { useState, useEffect, useRef } from "react";

import './Project.css';

import Cookies from 'universal-cookie';

import JoditEditor from 'jodit-react';
import { config, cookiesRegister } from "dataFixed";

import { SvgAddData, SvgDelete, SvgImg, SvgReturn, SvgOpt_1, SvgOpt_2, SvgOpt_3, SvgOpt_4, SvgOpt_5, SvgOpt_6, SvgOpt_7, SvgOpt_8, SvgOpt_9, SvgOpt_10, SvgOpt_11, SvgOpt_12, SvgEye, SvgSave, SvgSetaRight, SvgLockClose, SvgSearch, SvgCircle, SvgLink_opt2, SvgGoogleDrive, SvgBehance, SvgSite, SvgText, SvgYoutube, SvgReturnCircle, SvgEdit } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/InterfacePopUp";
import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";
import { GetListPag, RegisterListPag, SetListPag } from "interface/InterfacePag";

import Select from 'react-select'

import { Reg_Project } from "services/RegisterData";

import { allowDropOption, dragpoint, droppoint } from 'services/functionDragover';

import { typeCookiePageId } from "dataFixed";

import Img from "components/Input/Img";
import Text from "components/Input/Text";
import Video from "components/Input/Video";
import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";
import PopUp_NewClient from "components/PopUp/NewClient";

export default function Portfolio_Project(){

    const cookies    = new Cookies();
    const editText   = useRef();
    const editText_1 = useRef();
    const clickReturnTop = useRef();

    const [ loading, setLoading ] = useState(false);

    const [ listProject, setListProject ]   = useState(GetDataPage('portfolio'));
    const [ listCategory, setListCategory ] = useState(GetDataPage('category'));
    const [ showClient, setShowClient ]     = useState(GetDataPage("client"));
    const [ idPage, setIdPage ]             = useState(GetListPag('currentPageId'));

    const [ client, setClient ]           = useState(InitialData('client'));
    const [ newClient, setNewClient ]     = useState('');
    const [ project, setProject ]         = useState(InitialData('project'));
    const [ year, setYear ]               = useState(InitialData('year'));
    const [ file, setFile ]               = useState(InitialData('file'));
    const [ stateFile, setStateFile ]     = useState(false);
    const [ category, setCategory ]       = useState(InitialData('category'));
    const [ contents, setContents ]       = useState(InitialData('contents'));

    const [ linkDrive, setLinkDrive ]     = useState(InitialData('link_drive'));
    const [ linkSite, setLinkSite ]       = useState(InitialData('link_site'));
    const [ linkBehance, setLinkBehance ] = useState(InitialData('link_behance'));
    const [ restricted, setRestricted ]   = useState(InitialData('restricted'));
    const [ description, setDescription ] = useState(InitialData('description'));
    const [ datasheet, setDatasheet ]     = useState(InitialData('datasheet'));

    const [ statusClient, setStatusClient ]     = useState(false);
    const [ statusCategory, setStatusCategory ] = useState(false);

    useEffect(()=>{
        RegisterDataPage('portfolio', setListProject);
        RegisterDataPage('category', setListCategory);
        RegisterDataPage("client", setShowClient);

        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setListProject(GetDataPage('portfolio'));
        setListCategory(GetDataPage('category'));
        RegisterDataPage("client", setShowClient);

        setClient(InitialData('client'));
        setProject(InitialData('project'));
        setYear(InitialData('year'));
        setFile(InitialData('file'));
        setCategory(InitialData('category'));
        setContents(InitialData('contents'));
        setLinkDrive(InitialData('link_drive'));
        setLinkSite(InitialData('link_site'));
        setLinkBehance(InitialData('link_behance'));
        setRestricted(InitialData('restricted'));
        setDatasheet(InitialData('datasheet'));
    }, [listProject]);

    useEffect(()=>{
        if(cookies.get(typeCookiePageId)){
            let idPage = cookies.get(typeCookiePageId);
            if(idPage == 'undefined'){
                SetListPag('currentPage', 'portfolio');
                SetListPag('currentPageId', 'remuve');
            }
        }else {
            SetListPag('currentPage', 'portfolio');
            SetListPag('currentPageId', 'remuve');
        }

        setListProject(GetDataPage('portfolio'));
        setListCategory(GetDataPage('category'));
        RegisterDataPage("client", setShowClient);

        setIdPage(GetListPag('currentPageId'));

        setClient(InitialData('client'));
        setProject(InitialData('project'));
        setYear(InitialData('year'));
        setFile(InitialData('file'));
        setCategory(InitialData('category'));
        setContents(InitialData('contents'));
        setLinkDrive(InitialData('link_drive'));
        setLinkSite(InitialData('link_site'));
        setLinkBehance(InitialData('link_behance'));
        setRestricted(InitialData('restricted'));
        setDatasheet(InitialData('datasheet'));
    }, [idPage]);

    function InitialData(type){
        if(idPage !=0){
            const newData = listProject.find(item => item.id == idPage);
            if(type == 'client'){
                const dataClient = showClient.find(item => item.id == newData['client']);
                if(dataClient){
                    return { "value": dataClient.id, "label": dataClient.name };
                }
                let nameClient = newData['name_client'] + " -- cliente desativado"
                return { "value": newData['client'], "label": nameClient };
            }
            return newData[type];
        }
        if(type == 'client'){
            return { "value": 0, "label": "Selecione um cliente" };
        }
        if(type == 'restricted'){
            return true;
        }
        if(type == 'contents'){
            return [];
        }
        if(type == 'category'){
            return [];
        }
        return '';
    }

    function ReturnPage(){
        SetListPag('currentPage', 'portfolio');
        SetListPag('currentPageId', 'remuve');
    }

    function ShowCategory(){
        const options = [{ "value": "Adicionar nova categoria", "label": "Adicionar nova categoria" }]
        listCategory.map((key, index)=>{
            options.push({
                "value": key.name, "label": key.name
            });
        })
        return options;
    }

    function ShowClient(){
        const options = [{ "value": "add", "label": "Adicionar novo cliente" }]
        showClient.map((key, index)=>{
            options.push({
                "value": key.id, "label": key.name
            });
        })
        return options;
    }

    function OpenFile(type, value){
        SetModalData("ShowFile", { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    function AddFile(value){
        if(value){
            setStateFile(true);
            setFile(value);
        }else {
            setStateFile(false);
            setFile('');
        }
    }

    function AddNewBlock(){
        setContents([...contents, {
            "id": 0,
            "order_": 0,
            "type": 0,
            "container": false,
            "state": false,
            "height": "top",
            "width": "left",
            "contents": [ ]
        }
        ]);
    }

    function ShowTypeIconsAdd(typeIcon){
        switch (typeIcon) {
            case 1:
                return <SvgOpt_1 color="#C5D2E8" className="opt_icon_list"/>;
            case 2:
                return <SvgOpt_2 color="#C5D2E8" className="opt_icon_list"/>;
            case 3:
                return <SvgOpt_3 color="#C5D2E8" className="opt_icon_list"/>;
            case 4:
                return <SvgOpt_4 color="#C5D2E8" className="opt_icon_list"/>;
            case 5:
                return <SvgOpt_5 color="#C5D2E8" className="opt_icon_list"/>;
            case 6:
                return <SvgOpt_6 color="#C5D2E8" className="opt_icon_list"/>;
            case 7:
                return <SvgOpt_7 color="#C5D2E8" className="opt_icon_list"/>;
            case 8:
                return <SvgOpt_8 color="#C5D2E8" className="opt_icon_list"/>;
            case 9:
                return <SvgOpt_9 color="#C5D2E8" className="opt_icon_list"/>;
            case 10:
                return <SvgOpt_10 color="#C5D2E8" className="opt_icon_list"/>;
            case 11:
                return <SvgOpt_11 color="#C5D2E8" className="opt_icon_list"/>;
            case 12:
                return <SvgOpt_12 color="#C5D2E8" className="opt_icon_list"/>;
        }
    }

    function RemuveContent(index, id){
        const newData = [...contents];
        if(id == 0){
            newData.splice(index, 1);
            setContents(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "portfolio", "type": "delete_contents", "name": "Conteúdo" });
            SetModalState('Confirmation', true);
        }
    }

    function ShowTypeAdd(index, qtd){
        const newData       = [...contents];
        newData[index].type = qtd;
        for (let i = 0; i < qtd; i++) {
            newData[index].contents.push({ "id": 0, "type": "", "state": false, "height": "top", "width": "left"});
        }
        setContents(newData);
    }

    function StateContainer(index, state){
        const newData = [...contents];
        newData[index].container = state;
        setContents(newData);
    }

    function StateAlign(index, state){
        const newData = [...contents];
        newData[index].state = state;
        setContents(newData);
    }

    function AltAlign(index, height, width){
        const newData = [...contents];
        newData[index].height = height;
        newData[index].width  = width;
        newData[index].state  = false;
        setContents(newData);
    }

    function StateAlignContent(index_0, index_1, value){
        const newData = [...contents];
        newData[index_0].contents[index_1].status = value;
        setContents(newData);
    }

    function AltAlignContent(index_0, index_1, height, width){
        const newData = [...contents];
        newData[index_0].contents[index_1].height = height;
        newData[index_0].contents[index_1].width  = width;
        newData[index_0].contents[index_1].status = false;
        setContents(newData);
    }

    function ConstData(type_data, index_0, index_1){
        const newData = [...contents];

        const addData = newData[index_0].contents[index_1].data;
        addData.push({ "id" : 0, "type" : type_data });

        newData[index_0].contents[index_1].data = addData;
        setContents(newData);
    }

    function RegisterData(value, index_0, index_1){
        const newData = [...contents];
        newData[index_0].contents[index_1] = value;
        setContents(newData);
    }

    function ShowDataType(type, index, index_1, id_content, id_data){
        switch (type) {
            case "img":
                return <Img index_0={ index } index_1={ index_1 } id_content={ id_content } id_data={ id_data } RegisterData={ RegisterData } />;

            case "video":
                return <Video index_0={ index } index_1={ index_1 } id_content={ id_content } id_data={ id_data } RegisterData={ RegisterData } />;

            case "text":
                return <Text index_0={ index } index_1={ index_1 } id_content={ id_content } id_data={ id_data } RegisterData={ RegisterData } />;
        }
    }

    function Delete(id, index, value){
        if(id == 0){
            const newData = [...category];
            newData.splice(index, 1);
            setCategory(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "portfolio", "type": "delete_category", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function SearchCategory(value){
        const newData   = [];
        if(value !=''){
            {
                GetDataPage('category').forEach(item =>{
                    if(item.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                })
            }
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
            setListCategory(duplicate);
        }else if(value == ''){
            setListCategory(GetDataPage('category'));
        }
    }

    function SelectCategory(index, value){
        setCategory([...category, { "id": 0, "name": value }])
        setStatusCategory(false);
    }

    function NewDataContents(type, index, index_1){
        const newData = [...contents];
        newData[index].contents[index_1].type = type;
        setContents(newData);
    }

    function AltTypeContent(index, index_1){
        const newData = [...contents];
        newData[index].contents[index_1].type = '';
        setContents(newData);
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        if(client.value !=0){
            Reg_Project(idPage, client.value, newClient, project, year, file, category, contents, linkDrive, linkSite, linkBehance, restricted, description, datasheet, ()=>{ CallbackSuccess(); }, ()=>{ CallbackError() });
        }else {
            CallbackMissingData();
        }
    }

    function CallbackSuccess(){
        setLoading(false);
        setStateFile(false);
        ShowCategory();
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackMissingData(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "missingData", "text": "É necessário selecionar um cliente para registrar o projeto!" });
        SetModalState('ReturnResponse', true);
    }

    function OpenProject(){
        let pageCookies  = "project_" + idPage;
        let minutesValid = new Date(new Date().getTime() + 60 * 60 * 3600);
        cookies.set(pageCookies, idPage, { path: '/', expires: minutesValid }, cookiesRegister);
        window.open('preview.html?project=' + idPage, '_blank');
    }

    function NewClient(){
        SetModalData("NewClient", { "id": idPage });
        SetModalState('NewClient', true);
    }

    function NewCategory(){
        SetModalData("ShowData", { "type": "newData", "name": "" });
        SetModalState('ShowData', true);
    }

    return(
        <div className="Portfolio_Project">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>

            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() } }>
                        <SvgReturn color="#324d6b" className="icons" />
                        Voltar
                    </div>
                    {/*
                    <div className="name_project">
                        {
                            idPage == 0 ? "Novo projeto" : project
                        }
                    </div>
                    */}
                    <div className="list_opt_alt_page">
                        {
                            idPage == 0 ? null :
                            <div className="icons_project" onClick={ ()=>{ OpenProject() } }>
                                <div className="preview_eye">
                                    <SvgEye color="#E51D4D" className="icons" />
                                </div>
                                <div className="preview_project">
                                    Pré-visualizar
                                </div>
                            </div>
                        }
                        <button className="new_block_text">
                            <div className="">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="">
                                Salvar
                            </div>
                        </button>
                    </div>
                </div>
                <div className="show_data_register">
                    <div className="data_project">
                        <div className="div_show_text">
                            <div className="div_data_project">
                                <div className="div_input space_top space_data">
                                    <input type="text" className="" onChange={ (e)=>{ setProject(e.target.value) } } value={ project } maxLength="140" required />
                                    <span className="span_name_input">Nome do projeto</span>
                                </div>
                                <div className="div_input space_top">
                                    <input type="text" className="year" onChange={ (e)=>{ setYear(e.target.value) } } value={ year } maxLength="4" required />
                                    <span className="span_name_input">Ano</span>
                                </div>
                                <div className="div_show_or_new_data">
                                    <div className="list_opt_add show_data_add">
                                        <div className="show_textarea">
                                            <label className="show_title">Descrição</label>
                                            <JoditEditor className="text_editor" ref={ editText } config={ config } value={ description ? description.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setDescription(newContent) } />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            file !='' ?
                                <div className="div_page_cover">
                                    <label className="div_page_cover_edit">
                                        <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } className="close_file_edit" accept="image/*" placeholder="" title="" />
                                        <div>
                                            <SvgEdit color="#ffffff" className="icons" />
                                        </div>
                                    </label>
                                    {
                                        stateFile == true ?
                                        <div className="div_cover div_cover_img">
                                            <img alt="img" src={ URL.createObjectURL(file) } className="show_img_cover" />
                                        </div> :
                                        <div className="div_cover div_cover_img">
                                            <img alt="img" src={ "./assets/project/" + file } className="show_img_cover" />
                                        </div>
                                    }
                                </div>
                            :
                                <label>
                                    <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } className="close_file" accept="image/*" placeholder="" title="" />
                                    <div className="div_cover">
                                        <div className="div_cover_icons">
                                            <SvgImg color="#CDD1D6" className="icons" />
                                        </div>
                                        <div className="div_cover_title">Adicione uma capa</div>
                                        <div className="div_cover_explication">
                                            Arraste e solte aqui ou <span className="div_cover_color">procure o arquivo</span>
                                        </div>
                                        <div className="div_cover_desc">sugestão 1920x1080</div>
                                    </div>
                                </label>
                        }

                        <div className="div_show_text div_links_space_top">
                            <div className="show_title">
                                <div className="title_div div_links">
                                    <SvgLink_opt2 color="#606875" className="icons" />
                                    Links
                                </div>
                            </div>
                            <div className="div_data_project">
                                <div className="div_input space_top">
                                    <SvgGoogleDrive color="#606875" className="icons" />
                                </div>
                                <div className="div_input space_top space_data">
                                    <input type="text" className="" onChange={ (e)=>{ setLinkDrive(e.target.value) } } value={ linkDrive } />
                                </div>
                            </div>
                            <div className="div_data_project">
                                <div className="div_input space_top">
                                    <SvgBehance color="#606875" className="icons" />
                                </div>
                                <div className="div_input space_top space_data">
                                    <input type="text" className="" onChange={ (e)=>{ setLinkBehance(e.target.value) } } value={ linkBehance } />
                                </div>
                            </div>
                            <div className="div_data_project">
                                <div className="div_input space_top">
                                    <SvgSite color="#606875" className="icons" />
                                </div>
                                <div className="div_input space_top space_data">
                                    <input type="text" className="" onChange={ (e)=>{ setLinkSite(e.target.value) } } value={ linkSite } />
                                </div>
                            </div>
                            <div className="div_data_project">
                                <div className="div_input space_top space_data explication">
                                    Cole o link nos campos acima para criar um atalho no projeto.
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <div className="div_title_data">Layout</div>
                            <div className="div_show_or_new_data div_show_text">
                                {
                                    contents.map((key, index)=>{
                                        let numbContents = index + 1;
                                        if(numbContents <= 9){
                                            numbContents = 0 + "" + numbContents;
                                        }
                                        return(
                                            <div className="div_show_or_new_data" key={ index }>
                                                <div className="show_title">
                                                    <div className="title_div div_data_topic_project">
                                                        <div className="">#{ numbContents }</div>
                                                        <div className="div_switch">
                                                            <div className="">Largura total</div>
                                                            <div className="div_restricted_switch">
                                                                <label className="switch">
                                                                    <div className="switch_wrapper">
                                                                        <input type="checkbox" onChange={ (e)=>{ StateContainer(index, !key.container) } } checked={ key.container } />
                                                                        <span className="switch_button"></span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="align_type_contents">

                                                    <div className="div_align">
                                                        <div className="div_type_align" onClick={ ()=>{ StateAlign(index, !key.state) } }>
                                                            Alinhar linha
                                                            <SvgText color="#E51D4D" className="icons" />
                                                        </div>
                                                        {
                                                            key.state == true ?
                                                            <div className="list_align">
                                                                <div className="align">
                                                                    <div className={ key.height == "top" && key.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlign(index, 'top', 'left') } } />
                                                                    <div className={ key.height == "top" && key.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlign(index, 'top', 'center') } } />
                                                                    <div className={ key.height == "top" && key.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlign(index, 'top', 'right') } } />
                                                                </div>
                                                                <div className="align">
                                                                    <div className={ key.height == "center" && key.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlign(index, 'center', 'left') } } />
                                                                    <div className={ key.height == "center" && key.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlign(index, 'center', 'center') } } />
                                                                    <div className={ key.height == "center" && key.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlign(index, 'center', 'right') } } />
                                                                </div>
                                                                <div className="align">
                                                                    <div className={ key.height == "bottom" && key.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlign(index, 'bottom', 'left') } } />
                                                                    <div className={ key.height == "bottom" && key.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlign(index, 'bottom', 'center') } } />
                                                                    <div className={ key.height == "bottom" && key.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlign(index, 'bottom', 'right') } } />
                                                                </div>
                                                            </div>
                                                            : null
                                                        }
                                                    </div>
                                                    <div className="" onClick={ ()=>{ RemuveContent(index, key.id) } }>
                                                        <SvgDelete color="#E51D4D" className="icons" />
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="div_data_project">
                                                    {
                                                        key.contents.length == 0 ?
                                                        <div className="add_project">
                                                            <div className="list_content">
                                                                <div className="div_add_type">
                                                                    <div className="list_opt">
                                                                        <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 1); }}>
                                                                            <SvgOpt_1 color="#CDD1D6" className="opt_icon"/>
                                                                        </div>
                                                                        <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 2); }}>
                                                                            <SvgOpt_2 color="#CDD1D6" className="opt_icon"/>
                                                                        </div>
                                                                        <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 3); }}>
                                                                            <SvgOpt_3 color="#CDD1D6" className="opt_icon"/>
                                                                        </div>
                                                                        <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 4); }}>
                                                                            <SvgOpt_4 color="#CDD1D6" className="opt_icon"/>
                                                                        </div>
                                                                        <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 5); }}>
                                                                            <SvgOpt_5 color="#CDD1D6" className="opt_icon"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> :
                                                        <>
                                                            {
                                                                key.contents.map((key_1, index_1)=>{
                                                                    return(
                                                                        <div className="add_project show_data_project" key={ index_1 }>
                                                                            <div className="list_content">
                                                                                {
                                                                                    key_1.type == '' ?
                                                                                    <div className="div_add_type">
                                                                                        <div className="div_opt_add" onClick={ ()=>{ NewDataContents('img', index, index_1) } }>
                                                                                            <div className="">
                                                                                                <SvgImg color="#FFFFFF" className="icons" />
                                                                                            </div>
                                                                                            <div className="">
                                                                                                Imagem
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="div_opt_add" onClick={ ()=>{ NewDataContents('text', index, index_1) } }>
                                                                                            <div className="">
                                                                                                <SvgText color="#FFFFFF" className="icons" />
                                                                                            </div>
                                                                                            <div className="">
                                                                                                Texto
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="div_opt_add" onClick={ ()=>{ NewDataContents('video', index, index_1) } }>
                                                                                            <div className="">
                                                                                                <SvgYoutube color="#FFFFFF" className="icons" />
                                                                                            </div>
                                                                                            <div className="">
                                                                                                Vídeo
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    <div className="">
                                                                                        <div className="div_alt_data_contents">
                                                                                            <div className="" onClick={ ()=>{ AltTypeContent(index, index_1) } }>
                                                                                                <SvgReturnCircle color="#E51D4D" className="icons" />
                                                                                            </div>
                                                                                            <div className="div_align">
                                                                                                <div className="" onClick={ ()=>{ StateAlignContent(index, index_1, !key_1.status) } }>
                                                                                                    <SvgText color="#E51D4D" className="icons" />
                                                                                                </div>
                                                                                                {
                                                                                                    key_1.status == true ?
                                                                                                    <div className="list_align">
                                                                                                        <div className="align">
                                                                                                            <div className={ key_1.height == "top" && key_1.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlignContent(index, index_1, 'top', 'left') } } />
                                                                                                            <div className={ key_1.height == "top" && key_1.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlignContent(index, index_1, 'top', 'center') } } />
                                                                                                            <div className={ key_1.height == "top" && key_1.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlignContent(index, index_1, 'top', 'right') } } />
                                                                                                        </div>
                                                                                                        <div className="align">
                                                                                                            <div className={ key_1.height == "center" && key_1.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlignContent(index, index_1, 'center', 'left') } } />
                                                                                                            <div className={ key_1.height == "center" && key_1.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlignContent(index, index_1, 'center', 'center') } } />
                                                                                                            <div className={ key_1.height == "center" && key_1.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlignContent(index, index_1, 'center', 'right') } } />
                                                                                                        </div>
                                                                                                        <div className="align">
                                                                                                            <div className={ key_1.height == "bottom" && key_1.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlignContent(index, index_1, 'bottom', 'left') } } />
                                                                                                            <div className={ key_1.height == "bottom" && key_1.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlignContent(index, index_1, 'bottom', 'center') } } />
                                                                                                            <div className={ key_1.height == "bottom" && key_1.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlignContent(index, index_1, 'bottom', 'right') } } />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    : null
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            ShowDataType(key_1.type, index, index_1, key.id, key_1.id)
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className="add_project">
                                    <div className="add_data" onClick={ ()=>{ AddNewBlock() } }>
                                        <SvgAddData color="#F00000" className="icons" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <div className="div_title_data">Ficha técnica</div>
                            <div className="div_show_text">
                                <JoditEditor ref={ editText_1 } config={ config } value={ datasheet ? datasheet.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setDatasheet(newContent) } />
                            </div>
                        </div>

                    </div>

                    <div className="data_fixed">
                        <div className="div_show_text">
                            <div className="div_restricted">
                                <div className="div_restricted_lock">
                                    <SvgLockClose color="#606875" className="icons" />
                                </div>
                                <div className="div_restricted_title">
                                    Restrito
                                </div>
                                <div className="div_restricted_switch">
                                    <label className="switch">
                                        <div className="switch_wrapper">
                                            <input type="checkbox" onChange={ (e)=>{ setRestricted(!restricted) } } checked={ restricted } />
                                            <span className="switch_button"></span>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <div className="div_show_select">
                                <div className="div_show_select_height">
                                    <div className="div_show_select_opt_selected" onClick={ ()=>{ setStatusClient(!statusClient); setStatusCategory(false); } }>
                                        <div className="div_show_select_name_data" title={ client.label }>
                                            { client.label }
                                        </div>
                                        <div className="div_show_select_icons">
                                            <SvgSetaRight color="#E51D4D" className="icons" />
                                        </div>
                                    </div>
                                </div>
                                {
                                    statusClient == false ? null :
                                    <div className="div_show_select_list_data">
                                        <div className="div_show_select_name div_show_select_new_data" onClick={ ()=>{ NewClient() } }>
                                            <span className="">+</span>
                                            Novo cliente
                                        </div>
                                        {
                                            showClient.map((key, index)=>{
                                                return (
                                                    <div className="div_show_select_name div_show_select_name_data" key={ index } title={ key.name } onClick={ ()=>{ setClient({ "value": key.id, "label": key.name }); setStatusClient(false) } }>
                                                        { key.name }
                                                    </div>
                                                )
                                            })
                                        }
                                        <div className="div_show_select_name div_show_select_new_data div_show_select_cancel" onClick={ ()=>{ setStatusCategory(false); setStatusClient(false); } }>
                                            Cancelar
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="div_category">
                                <div className="div_category_title">Categorias</div>
                                <div className="div_category_search">
                                    <div className="input_search">
                                        <SvgSearch color="#606875" className="icons_search" />
                                        <input type="text" className="search" onChange={ (e)=>{ SearchCategory(e.target.value) } } onClick={ ()=>{ setStatusCategory(true); setStatusClient(false); } } placeholder="buscar" />
                                    </div>
                                    {
                                        statusCategory == false ? null :
                                        <div className="div_show_select_list_data">
                                            <div className="div_show_select_name div_show_select_new_data" onClick={ ()=>{ NewCategory() } }>
                                                <span className="">+</span>
                                                Novo categoria
                                            </div>
                                            {
                                                listCategory.map((key, index)=>{
                                                    return (
                                                        <div className="div_show_select_name div_show_select_name_data" key={ index } title={ key.name } onClick={ ()=>{ SelectCategory(index, key.name); setStatusClient(false); } }>
                                                            { key.name }
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className="div_show_select_name div_show_select_new_data div_show_select_cancel" onClick={ ()=>{ setStatusCategory(false); setStatusClient(false); } }>
                                                Cancelar
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="div_category_list">
                                    {
                                        category.length > 0 ?
                                            category.map((key, index)=>{
                                                return(
                                                    <div className="div_category_list_name_category" key={ index }>
                                                        <div className="div_category_list_icons" />
                                                        <div className="div_category_list_name div_show_select_name_data">{ key.name }</div>
                                                        <div className="div_category_list_remuve" onClick={ ()=>{ Delete(key.id, index, key.name) } }>
                                                            <SvgDelete color="#f00000" className="icons" />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        : <div className="div_category_list_no_data">Nenhuma categoria adicionada...</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <PopUP_ReturnResponse />
            <PopUp_NewClient />
        </div>
    )
}
