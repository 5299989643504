import { useState, useEffect } from "react";

import './Report.css';

import { SvgReturn } from "components/SvgFile";
import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";

export default function Report(props){

    const date = new Date();
    const [ showYear, setShowYear ]       = useState(date.getFullYear());
    const [ currentYear, setCurrentYear ] = useState(showYear);
    const [ pageClick, setPageClick ]     = useState('');

    const [ client, setClient ]       = useState(GetDataPage('client'));
    const [ qtdClient, setQtdClient ] = useState(ShowQtd('client'));

    const [ portfolio, setPortfolio ]       = useState(GetDataPage('portfolio'));
    const [ qtdPortfolio, setQtdPortfolio ] = useState(ShowQtd('portfolio'));

    const [ presentation, setPresentation ]       = useState(GetDataPage('presentation'));
    const [ qtdPresentation, setQtdPresentation ] = useState(ShowQtd('presentation'));

    const [ budget, setBudget ]       = useState(GetDataPage('budget'));
    const [ qtdBudget, setQtdBudget ] = useState(ShowQtd('budget'));

    useEffect(()=>{
        RegisterDataPage('client', setClient);
        RegisterDataPage('portfolio', setPortfolio);
        RegisterDataPage('presentation', setPresentation);
        RegisterDataPage('budget', setBudget);
    }, []);

    useEffect(()=>{
        setQtdClient(ShowQtd('client'));
        setQtdPortfolio(ShowQtd('portfolio'));
        setQtdPresentation(ShowQtd('presentation'));
        setQtdBudget(ShowQtd('budget'));
    }, [currentYear]);

    function ShowQtd(type){
        if(type == 'client'){
            const newData = client.filter(item => item.customer_since == currentYear);
            return newData.length;

        }else if(type == 'presentation'){
            const newData = presentation.filter(item => item.year == currentYear);
            return newData.length;

        }else if(type == 'portfolio'){
            const newData = portfolio.filter(item => item.year == currentYear);
            return newData.length;

        }else if(type == 'budget'){
            const newData = budget.filter(item => item.year == currentYear);
            return newData.length;
        }
    }

    return(
        <div className="Report">
            <div className="new_block">
                <div className="div_return" onClick={ ()=>{ setCurrentYear((currentYear - 1)); } }>
                    <SvgReturn color="#324d6b" className="icons" />
                </div>
                <div className="name_project">
                    { currentYear }
                </div>
                <div className="div_return" onClick={ ()=>{ setCurrentYear((currentYear + 1)); } }>
                    <SvgReturn color="#324d6b" className="icons icon_next" />
                </div>
            </div>
            <div className="list_data_year">
                <div className="typeData">
                    <div className="div_data" onClick={ ()=>{ props.CkickPage('report_clients'); }}>
                        <div className="qtd_">{ qtdClient }</div>
                        <div className="title_div">Clientes</div>
                    </div>
                    <div className="div_data" onClick={ ()=>{ props.CkickPage('report_project'); }}>
                        <div className="qtd_">{ qtdPortfolio }</div>
                        <div className="title_div">Projetos</div>
                    </div>
                    <div className="div_data" onClick={ ()=>{ props.CkickPage('report_presentation'); }}>
                        <div className="qtd_">{ qtdPresentation }</div>
                        <div className="title_div">Apresentações</div>
                    </div>
                    <div className="div_data" onClick={ ()=>{ props.CkickPage('report_budget'); }}>
                        <div className="qtd_">{ qtdBudget }</div>
                        <div className="title_div">Orçamentos</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
