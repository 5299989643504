import { useState, useEffect } from "react";

import './Category.css';

import { SvgAddOpt_2, SvgEdit, SvgSearch, SvgSetaRight, SvgTriagle } from "components/SvgFile";

import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";
import { SetModalData, SetModalState } from "interface/InterfacePopUp";

export default function Category(){

    const [ search, setSearch ]     = useState('');
    const [ dataPage, setDataPage ] = useState(GetDataPage('category'));
    const [ type, setType ]         = useState('All');
    const [ status, setStatus ]     = useState(false);

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(dataPage.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = dataPage.slice(startItens, endItens);

    useEffect(()=>{
        RegisterDataPage('category', setDataPage);
    }, []);

    function SearchInput(value){
        const newData   = [];
        if(value !=''){
            {
                GetDataPage("category").forEach(item =>{
                    if(type == 'All'){
                        if(item.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                            newData.push(item);
                        }
                    }else {
                        if(item.type == type){
                            if(item.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                                newData.push(item);
                            }
                        }
                    }
                })
            }
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
            setDataPage(duplicate);

        }else if(value == ''){
            setDataPage(GetDataPage("category"));
        }
        setSearch(value);
        setStatus(false);
    }

    function SearchType(value){
        const newData   = [];
        if(value !=''){
            {
                GetDataPage("category").forEach(item =>{
                    if(value == 'All'){
                        newData.push(item);
                    }else {
                        if(item.type.toLowerCase().indexOf(value.toLowerCase()) != -1){
                            newData.push(item);
                        }
                    }
                })
            }
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
            setDataPage(duplicate);

        }else if(value == ''){
            setDataPage(GetDataPage("category"));
        }
        setSearch('');
        setType(value);
        setStatus(false);
    }

    function EditData(name){
        SetModalData("ShowData", { "type": "edit", "name": name });
        SetModalState('ShowData', true);
    }

    function NewCategory(){
        SetModalData("ShowData", { "type": "newData", "name": "" });
        SetModalState('ShowData', true);
    }

    return (
        <div className="Category">
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page">
                        Categoria
                    </div>
                </div>
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="buscar" />

                    <div className="click_show_search">
                        <div className="div_input" onClick={ ()=>{ setStatus(!status); } }>
                            <div className="div_select search_status">
                                { type }
                                {
                                    status == true ?
                                    <SvgTriagle color="#747474" className="icon_select_open" /> :
                                    <SvgTriagle color="#747474" className="icon_select_close" />
                                }
                            </div>
                            <span className="span_name_select">Tipo</span>
                        </div>
                        {
                            status == true ?
                            <div className="click_show_search_selected search_status_">
                                <div className="opt_list_search" onClick={ ()=>{ SearchType('All'); } }>All</div>
                                <div className="opt_list_search" onClick={ ()=>{ SearchType('Público'); } }>Público</div>
                                <div className="opt_list_search" onClick={ ()=>{ SearchType('Privado'); } }>Privado</div>
                            </div>
                            : null
                        }
                    </div>
                </div>
                <div className="new_block_text new_project" onClick={ ()=>{ NewCategory() } }>
                    <div className="">
                        <span className="icons_menu">+</span>
                        {/* <SvgAddOpt_1 color="#ffffff" className="icons_menu" /> */}
                    </div>
                    <div className="">
                        categoria
                    </div>
                </div>
            </div>
            <div className="list_category">
                <table>
                    <tbody>
                        <tr>
                            <th width="20">#</th>
                            <th width="80">Tipo</th>
                            <th>Nome</th>
                            <th width="20">#</th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                                currentItens.map((key, index)=>{
                                    return(
                                        <tr key={ index }>
                                            <td>{ startItens + index + 1 }</td>
                                            <td>{ key.type }</td>
                                            <td>{ key.name }</td>
                                            <td align="right">
                                                <div className="list_opt">
                                                    <div onClick={ ()=>{ EditData(key.name) } }>
                                                        <SvgEdit color="#324d6b" className="icons"/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            :
                            <tr>
                                <td colSpan="7">
                                    <div className="no_data">Nenhuma categoria encontrado...</div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {
                    dataPage.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}
