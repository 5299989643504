import { useState, useEffect, useRef } from "react";

import './New.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SvgDelete, SvgReturn } from "components/SvgFile";

import { Reg_Services } from "services/RegisterData";

import { SetModalData, SetModalState } from "interface/InterfacePopUp";
import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";
import { GetListPag, RegisterListPag, SetListPag } from "interface/InterfacePag";
import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";

export default function Services_New(){

    const editText = useRef();

    const [ loading, setLoading ]   = useState(false);
    const [ dataPage, setDataPage ] = useState(GetDataPage('services'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ type, setType ]           = useState(InitialData('type'));
    const [ title, setTitle ]         = useState(InitialData('title'));
    const [ cash, setCash ]           = useState(InitialData('cash'));
    const [ text, setText ]           = useState(InitialData('text'));
    const [ listCombo, setListCombo ] = useState(InitialData('listCombo'));

    useEffect(()=>{
        RegisterDataPage("services", setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('services'));
        setIdPage(GetListPag('currentPageId'));

        setType(InitialData('type'));
        setTitle(InitialData('title'));
        setCash(InitialData('cash'));
        setText(InitialData('text'));
        setListCombo(InitialData('listCombo'));
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage('services'));
        setIdPage(GetListPag('currentPageId'));

        setType(InitialData('type'));
        setTitle(InitialData('title'));
        setCash(InitialData('cash'));
        setText(InitialData('text'));
        setListCombo(InitialData('listCombo'));
    }, [idPage]);

    function InitialData(type){
        if(idPage != 0){
            const newData = dataPage.find(item => item.id == idPage);
            if(type == 'type'){
                if(newData[type] == 0){
                    return { "value": 0, "label": "Texto fixo" }
                }else {
                    return { "value": 1, "label": "Serviços" }
                }
            }
            return newData[type];
        }
        if(type == 'type'){
            return { "value": 1, "label": "Serviços" }
        }
        if(type == 'listCombo'){
            return [];
        }
        return '';
    }

    function ShowComboServices(){
        const options = []
        GetDataPage('services').map((key, index)=>{
            if(key.type == 1){
                options.push({
                    "value": key.id, "label": key.title
                });
            }
        })
        return options;
    }

    function NewCombo(){
        setListCombo([...listCombo, { "id": 0, "title": "", "new_list": [], "list": [] }]);
    }

    function HandleData(type, index, value){
        const newData = [...listCombo];
        newData[index][type] = value;
        setListCombo(newData);
    }

    function RemoveCombo(id, index, value){
        const newData = [...listCombo];
        if(id == 0){
            newData.splice(index, 1);
            setListCombo(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "services", "type": "delete_combo", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function RemoveComboServices(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "services", "type": "delete_services", "name": value });
        SetModalState('Confirmation', true);
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        setListCombo([])
        Reg_Services(idPage, type, title, cash, text, listCombo, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
    }

    function ReturnPage(){
        SetListPag('currentPage', 'services');
        SetListPag('currentPageId', 'remuve');
    }

    return(
        <div className="NewServices">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() } }>
                        <SvgReturn color="#324d6b" className="icons" />
                        Voltar
                    </div>
                    <div className="name_project">
                        Serviços do orçamento
                    </div>
                    <div className="list_opt_alt_page">
                        <button className="new_block_text new_project">
                            Salvar
                        </button>
                    </div>
                </div>
                <div className="show_data_register">
                    <div className="div_show_text">
                        <div className="div_data_project">
                            <div className="div_input space_top">
                                <Select className="type_text" defaultOptions options={ [{ "value": 0, "label": "Texto fixo" }, { "value": 1, "label": "Serviços" } ] } value={ type } onChange={ (e)=> { setType(e); } } />
                                <span className="span_search_select">Tipo</span>
                            </div>
                            <div className="div_input space_data space_top">
                                <input type="text" className="" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" required />
                                <span className="span_search_select">Título</span>
                            </div>
                            {
                                type.value == 0 ? null :
                                <div className="div_input space_top">
                                    <input type="text" className="cash" onChange={ (e)=>{ setCash(e.target.value) } } value={ cash } maxLength="20" required />
                                    <span className="span_search_select">Valor R$</span>
                                </div>
                            }
                        </div>
                        <div className="show_title">
                            <div className="title_div">Descrição</div>
                        </div>
                        <div className="show_textarea">
                            <JoditEditor ref={ editText } config={ config } value={ text ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } />
                        </div>
                    </div>

                    {
                        type.value == 0 ? null :
                        <div className="div_show_or_new_data div_show_text">
                            <div className="show_title">
                                <div className="title_div">Montar combo com outro serviço</div>
                                <div className="title_div">
                                    <div className="new_block_text new_project" onClick={ ()=>{ NewCombo(); } }>
                                        Adicionar combo
                                    </div>
                                </div>
                            </div>
                            <div className="div_show_text space_show_data">
                                {
                                    listCombo.length > 0 ?
                                        listCombo.map((key, index)=>{
                                            return(
                                                <div className="list_opt_add show_data_add" key={ index }>
                                                    <div className="div_data_project">
                                                        <div className="div_input show_textarea">
                                                            <input type="text" className="" onChange={ (e)=>{ HandleData('title', index, e.target.value) } } value={ key.title } maxLength="140" required />
                                                            <span className="span_search_select">Título</span>
                                                            <div onClick={ ()=>{ RemoveCombo(key.id, index, key.title) } }>
                                                                <SvgDelete color="#F00000" className="icons" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="div_data_project">
                                                        <div className="div_input show_textarea">
                                                            <Select className="space_data" defaultOptions options={ ShowComboServices() } onChange={ (e)=> { HandleData('new_list', index, e); } } value={ key.new_list } placeholder="Clique aqui para visualizar os serviços..." isMulti />
                                                            <span className="span_search_select">Serviços</span>
                                                        </div>
                                                    </div>
                                                    {
                                                        key.list.length > 0 ?
                                                        <div className="div_data_project">
                                                            <div className="div_input show_textarea">
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <th width="20">#</th>
                                                                            <th>Título</th>
                                                                            <th width="80">Valor R$</th>
                                                                            <th width="20" align="right">#</th>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>1</td>
                                                                            <td>{ title }</td>
                                                                            <td>{ cash }</td>
                                                                            <td />
                                                                        </tr>
                                                                        {
                                                                            key.list.map((key_1, index_1)=>{
                                                                                return(
                                                                                    <tr key={ index_1 }>
                                                                                        <td>{ index_1 + 2 }</td>
                                                                                        <td>{ key_1.title }</td>
                                                                                        <td>{ key_1.cash }</td>
                                                                                        <td align="right">
                                                                                            <div onClick={ ()=>{ RemoveComboServices(key_1.id, key_1.title) } }>
                                                                                                <SvgDelete color="#F00000" className="icons" />
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        : null
                                                    }
                                                </div>
                                            )
                                        })
                                    : <div className="no_data">Nenhum combo criado até o momento...</div>
                                }
                            </div>
                        </div>
                    }

                </div>
            </form>
            <PopUP_ReturnResponse />
        </div>
    )
}
