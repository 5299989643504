import React, { useState, useEffect } from "react";

import './NewProject.css';

import { SvgCircleNoSelected, SvgCircleSelected, SvgClose, SvgLockClose } from "components/SvgFile";

import { SetModalState, RegisterModalData, RegisterModalObserver } from "interface/InterfacePopUp";

import { Reg_NewProject } from "services/RegisterData";
import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";

export default function PopUP_NewProject(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ showDataPage, setShowDataPage ] = useState([]);
    const [ showCategory, setShowCategory ] = useState([]);

    const [ newProject, setNewProject ] = useState([]);

    const [ category, setCategory ] = useState('all');

    useEffect(()=>{
        RegisterModalData('NewProject', setModaldata);
        RegisterModalObserver('NewProject', setShowPopUp);

        RegisterDataPage("portfolio", setShowDataPage);
        RegisterDataPage("category", setShowCategory);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setShowDataPage(GetDataPage("portfolio"));
            setShowCategory(GetDataPage("category"));
        }else {
            setShowDataPage([]);
            setShowCategory([]);
        }
    }, [showPopUp]);

    function SearchCategory(value){
        const newList = [];
        if(value){
            if(value == 'all'){
                setShowDataPage(GetDataPage("portfolio"));
            }else {
                GetDataPage('portfolio').forEach(item =>{
                    item.category.map((key, index)=>{
                        if(key.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                            newList.push(item);
                        }
                    })
                });
                const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
                setShowDataPage(duplicate);
            }
        }else {
            setShowDataPage(GetDataPage("portfolio"));
        }
        setCategory(value);
    }

    function ShowCircleSelected(index, id, value){
        const newData = [...showDataPage];
        newData[index]['selected'] = value;
        setShowDataPage(newData);

        if(value == true){
            newProject.push({ "id": id, "project": value, "text": "" })
        }else {
            const dataAdd = [...newProject];
            let index_remuve = '';
            dataAdd.map((key, index)=>{
                if(key.id == id){
                    index_remuve = index;
                }
            })
            dataAdd.splice(index_remuve, 1);
            setNewProject(dataAdd);
        }
    }

    function SaveData(){
        Reg_NewProject(modalData.id, newProject, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        ClosePopUp();
    }

    function CallbackError(){
    }

    function ClosePopUp(){
        setShowDataPage([]);
        setShowCategory([]);
        SetModalState('NewProject', false);
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all pop_new_project">
                        <div className="div_data type_div">
                            <div className="title">
                                Registrar novo projeto
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">

                                <div className="list_category">
                                    <div className={ category == 'all' ? "show_name_category category_active" : "show_name_category" } onClick={ ()=>{ SearchCategory('all') } }>
                                        Todas categorias
                                    </div>
                                    {
                                        showCategory.map((key, index)=>{
                                            return(
                                                <div className={ category == key.name ? "show_name_category category_active" : "show_name_category" } key={ index } onClick={ ()=>{ SearchCategory(key.name) } }>
                                                    {
                                                        key.name
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="list_portfolio_new_presentation">
                                    <div className="list_portfolio">
                                        {
                                            showDataPage.map((key, index)=>{
                                                return(
                                                    <div className="div_project_data" key={ index } onClick={ ()=>{ ShowCircleSelected(index, key.id, !key.selected) } }>
                                                        <div className="project_data_inf">
                                                            <div className="project_data_client select_project_data">{ key.name_client }</div>
                                                            <div className="project_data_opt_select">
                                                                {
                                                                    key.selected == true ?
                                                                    <SvgCircleSelected color="#07C4D6" className="circle_selected" /> :
                                                                    <SvgCircleNoSelected color="#ffffff" className="circle_selected" />
                                                                }
                                                            </div>
                                                        </div>
                                                        {
                                                            key.restricted == false ? null :
                                                            <div className="project_data_lock_close">
                                                                <SvgLockClose color="#FFFFFF" className="lock" />
                                                            </div>
                                                        }
                                                        <div className="project_data_div_img">
                                                            <img alt="show img" src={ "./assets/project/" + key.file } className="project_data_img" />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                <div className="register_data button_project" onClick={ ()=>{ SaveData() } }>
                                    Cadastrar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
