import { useState, useEffect } from "react";

import { SvgReturn } from "components/SvgFile";

import ReactApexChart from "react-apexcharts";

import { monthList } from "dataFixed";

import { Calendar, Views, DateLocalizer, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';


export default function Report_Budget(props){

    const date = new Date();
    const [ showYear, setShowYear ]       = useState(date.getFullYear());
    const [ currentYear, setCurrentYear ] = useState(showYear);

    return(
        <div className="Report_Budget">
            <div className="new_block">
                <div className="div_return" onClick={ ()=>{ setCurrentYear((currentYear - 1)); } }>
                    <SvgReturn color="#324d6b" className="icons" />
                </div>
                <div className="name_project">
                    { currentYear }
                </div>
                <div className="div_return" onClick={ ()=>{ setCurrentYear((currentYear + 1)); } }>
                    <SvgReturn color="#324d6b" className="icons icon_next" />
                </div>
            </div>
            <div className="return_opt" onClick={ ()=>{ props.CkickPage('report') } }>
                Voltar
            </div>
            <div className="list_data_report">
                <div className="show_calendar">
                </div>
                <div className="show_table">
                    <div className="title_table">
                        Indicador comercial
                    </div>
                    <table width="100%" cellPadding="0" cellSpacing="0">
                        <thead>
                            <tr>
                                <th>Mês</th>
                                <th>Oportunidades</th>
                                <th>Propostas enviadas</th>
                                <th>Perdidas</th>
                                <th>Conquistadas</th>
                                <th>Aguardando resposta</th>
                                <th>% de conversão</th>
                                <th>Valor orçado</th>
                                <th>Valor perdido</th>
                                <th>Valor conquistado</th>
                                <th>% Valor convertido</th>
                                <th>Total aberto</th>
                                <th>Total negociação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                monthList.map((key, index)=>{
                                    return(
                                        <tr key={ index }>
                                            <td>{ key.name }</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="show_table">
                    <div className="title_table">
                        Projetos com mais chances de fechar
                    </div>
                    <table width="100%" cellPadding="0" cellSpacing="0">
                        <thead>
                            <tr>
                                <th>Mês</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                monthList.map((key, index)=>{
                                    return(
                                        <tr key={ index }>
                                            <td>{ key.name }</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="show_table">
                    <div className="title_table">
                        Projetos fechados
                    </div>
                    <table width="100%" cellPadding="0" cellSpacing="0">
                        <thead>
                            <tr>
                                <th>Mês</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                monthList.map((key, index)=>{
                                    return(
                                        <tr key={ index }>
                                            <td>{ key.name }</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
