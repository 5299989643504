import React, { useState, useEffect } from "react";

import './NewClient.css';

import { SvgClose } from "components/SvgFile";

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/InterfacePopUp";

import { Reg_NewClient } from "services/RegisterData";

export default function PopUp_NewClient(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ name, setName ]           = useState('');
    const [ corporateName, setCorporateName ] = useState('');
    const [ cnpj, setCnpj ]          = useState('');
    const [ actingArea, setActingArea ] = useState('');

    useEffect(()=>{
        RegisterModalData('NewClient', setModaldata);
        RegisterModalObserver('NewClient', setShowPopUp);
    }, []);

    function SaveData(){
        Reg_NewClient(modalData.id, name, corporateName, cnpj, actingArea, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        ClosePopUp();
    }

    function CallbackError(){
        ClosePopUp();
    }

    function ClosePopUp(){
        SetModalState('NewClient', false);
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all new_client">
                        <div className="div_data type_div">
                            <div className="title">
                                Novo cliente
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="show_input">
                                    <div className="div_input space_top space_client_top">
                                        <input type="text" className="div_input space_client" onChange={ (e)=>{ setName(e.target.value) } } maxLength="80" />
                                        <span className="span_name_input">Nome</span>
                                    </div>
                                    <div className="div_input space_top space_data space_client">
                                        <input type="text" className="div_input space_client" onChange={ (e)=>{ setCorporateName(e.target.value) } } maxLength="40" />
                                        <span className="span_name_input">Razão Social</span>
                                    </div>
                                </div>
                                <div className="show_input">
                                    <div className="div_input space_top space_client_top">
                                        <input type="text" className="cnpj" onChange={ (e)=>{ setCnpj(e.target.value) } } maxLength="20" />
                                        <span className="span_name_input">CNPJ</span>
                                    </div>
                                    <div className="div_input space_top space_client">
                                        <input type="text" className="div_input space_client" onChange={ (e)=>{ setActingArea(e.target.value) } } maxLength="50" />
                                        <span className="span_name_input">Área de atuação</span>
                                    </div>
                                </div>

                                <div className="register_data space_client" onClick={ ()=>{ SaveData() } }>
                                    Cadastrar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
