import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SvgDelete, SvgImg, SvgReturn, SvgSave } from "components/SvgFile";

import { Reg_UserDash } from "services/RegisterData";
import { GetUserData, RegisterUserData } from "interface/InterfaceUsers";
import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";
import { GetListPag, RegisterListPag, SetListPag } from "interface/InterfacePag";
import { SetModalData, SetModalState } from "interface/InterfacePopUp";
import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";

export default function AccessDash_Details(){

    const [ loading, setLoading ]   = useState(false);

    const [ userId, setUserId ]         = useState(GetUserData('id'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));

    const [ dataPage, setDataPage ] = useState(GetDataPage("accessDash"));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ status, setStatus ] = useState(InitialData('status'));
    const [ name, setName ]     = useState(InitialData('name'));
    const [ email, setEmail ]   = useState(InitialData('email'));
    const [ pass, setPass ]     = useState('');
    const [ file, setFile ]     = useState(InitialData('file'));
    const [ nivel, setNivel ]   = useState(InitialData('nivel'));
    const [ note, setNote ]     = useState(InitialData('note'));
    const [ page, setPage ]     = useState(InitialData('page'));

    const [ changesMade, setChangesMade ] = useState(InitialData('changesMade'));

    const [ statusFile, setStatusFile ] = useState(false);

    const [ addPage, setAddPage ] = useState([]);
    const [ opt, setOpt ]         = useState([
        { "value": "about_chroma/0", "label": "Sobre a chroma" },
        { "value": "category/1", "label": "Categoria" },
        { "value": "client/2", "label": "Clientes" },
        { "value": "portfolio/3", "label": "Portfólio" },
        { "value": "presentation/4", "label": "Apresentações" },
        { "value": "services/5", "label": "Serviços" },
        { "value": "budget/6", "label": "Orçamentos" },
        { "value": "report/7", "label": "Relatório" }
    ]);
    const [ statusPage, setStatusPage ] = useState(false);

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterUserData('access', setUserAccess);
        RegisterDataPage('accessDash', setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage("accessDash"));
        setIdPage(GetListPag('currentPageId'));

        setStatus(InitialData('status'));
        setName(InitialData('name'));
        setEmail(InitialData('email'));
        setFile(InitialData('file'));
        setNivel(InitialData('nivel'));
        setNote(InitialData('note'));
        setPage(InitialData('page'));
        setChangesMade(InitialData('changesMade'));
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage("accessDash"));
        setIdPage(GetListPag('currentPageId'));

        setStatus(InitialData('status'));
        setName(InitialData('name'));
        setEmail(InitialData('email'));
        setFile(InitialData('file'));
        setNivel(InitialData('nivel'));
        setNote(InitialData('note'));
        setPage(InitialData('page'));
        setChangesMade(InitialData('changesMade'));
    }, [idPage]);


    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        if(type == 'page'){
            return [];
        }
        return '';
    }

    function ReturnPage(){
        SetListPag('currentPage', 'accessDash');
        SetListPag('currentPageId', 'remuve');
    }

    function AddFile(value){
        if(value){
            setFile(value);
            setStatusFile(true);
        }else {
            setStatusFile(false);
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "accessDash", "type" : "delete_page", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        console.log(nivel);
        Reg_UserDash(userId, idPage, name, email, pass, file, nivel, note, addPage, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setPass('');
        setAddPage([]);
        setLoading(false);
        setStatusFile(false);
        setStatusPage(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setPass('');
        setAddPage([]);
        setLoading(false);
        setStatusFile(false);
        setStatusPage(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    return(
        <div className="AccessDash_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData } className="div_login">
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() } }>
                        <SvgReturn color="#2D3C53" className="icons" />
                        voltar
                    </div>
                    {/*
                    <div className="name_project">
                        Detalhes
                    </div>
                    */}
                    <div className="list_opt_alt_page">
                        <button className="new_block_text new_project">
                            <div className="">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="">
                                Salvar
                            </div>
                        </button>
                    </div>
                </div>

                <div className="show_data_register">
                    <div className="div_show_text">
                        <div className="div_data_project">
                            <div className="div_input space_top">
                                <input type="text" className="dash_user_name" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="16" required />
                                <span className="span_name_input">Nome</span>
                            </div>

                            <div className="div_input space_top">
                                <input type="email" className="dash_user_email" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email.replaceAll(' ', '') } maxLength="60" required />
                                <span className="span_name_input">E-mail</span>
                            </div>

                            <div className="div_input space_top">
                                {
                                    idPage !=0 ?
                                    <input type="password" className="pass" onChange={ (e)=>{ setPass(e.target.value) } } maxLength="20" value={ pass } /> :
                                    <input type="password" className="pass" onChange={ (e)=>{ setPass(e.target.value) } } maxLength="20" required />
                                }
                                <span className="span_name_input">Senha</span>
                            </div>
                            {
                                idPage == userId ? null :
                                <div className="div_input space_top">
                                    <select className="data_select" onChange={ (e)=>{ setNivel(e.target.value) } } value={ nivel }>
                                        <option value="0">Usuário</option>
                                        <option value="1">Administrador</option>
                                    </select>
                                    <span className="span_search_select">Acesso</span>
                                </div>
                            }
                            <div className="div_input space_top">
                                <div className={ statusFile == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { statusFile == true ? "Imagem adicionada" : "Adicionar imagem" }
                                        <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    file !='' ?
                                        statusFile == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "login/" + file) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                    : null
                                }
                            </div>
                        </div>

                        <div className="show_title">
                            <div className="title_div">Observação</div>
                        </div>
                        <div className="show_textarea">
                            <JoditEditor config={ config } value={ note ? note.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setNote(newContent) } />
                        </div>
                    </div>

                    {
                        nivel == 1 ? null :
                        <div className="div_show_text space_block">
                            <div className="show_title">
                                <div className="title_div">Acesso as páginas</div>
                                <div className="title_div">
                                    <div className="new_block_text new_project" onClick={ ()=>{ setStatusPage(!statusPage) } }>
                                        { statusPage == true ? "Cancelar" : "Liberar nova página" }
                                    </div>
                                </div>
                            </div>
                            {
                                statusPage == true ?
                                <div className="div_input space_page">
                                    <div className="div_space space_data">
                                        <Select className="div_space space_data" defaultOptions options={ opt } onChange={ (e)=> { setAddPage(e); } } value={ addPage } placeholder="Clique aqui para visualizar os serviços..." isMulti />
                                    </div>
                                </div>
                                : null
                            }
                            <div className="div_input space_page">
                                {
                                    page.length > 0 ?
                                        page.map((key, index)=>{
                                            return(
                                                <div className="page_released" key={ index }>
                                                    <div className="">
                                                        { key.name }
                                                    </div>
                                                    <div className="" onClick={ ()=>{ DeleteData(key.id, key.name) } }>
                                                        <SvgDelete color="#f00000" className="icons" />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    :
                                    statusPage == true ? null : <div className="no_data">Nenhuma página liberada até o momento...</div>
                                }
                            </div>
                        </div>
                    }

                    {
                        idPage == 0 ? null :
                        <div className="div_show_text space_block">
                            <div className="show_title">
                                <div className="title_div">Alterações realizadas</div>
                            </div>
                            <div className="div_input space_page">
                                {
                                    changesMade.length > 0 ?
                                        changesMade.map((key, index)=>{

                                        })
                                        :
                                    <div className="no_data">Nenhuma alterações realizada até o momento...</div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </form>
            <PopUP_ReturnResponse />
        </div>
    )
}
