import { useState, useEffect } from "react";

import { SvgReturn } from "components/SvgFile";

import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";

export default function Report_Clients(props){

    const date = new Date();
    const [ showYear, setShowYear ]       = useState(date.getFullYear());
    const [ currentYear, setCurrentYear ] = useState(showYear);

    const [ client, setClient ] = useState(GetDataPage('client'));
    const [ showClients, setShowClients ] = useState(InitialData());

    useEffect(()=>{
        RegisterDataPage('client', setClient);
    }, []);

    useEffect(()=>{
        setShowClients(InitialData());
    }, [currentYear]);

    function InitialData(){
        const newData = client.filter(item => item.customer_since == currentYear);
        return newData;
    }

    function QtdBudget(id){
        const newData = client.find(item => item.id == id);

        const bud_apr = newData.budget.filter(item => item.status == 'Aprovado');
        const bud_neg = newData.budget.filter(item => item.status == 'Em negociação');
        const bud_can = newData.budget.filter(item => item.status == 'Cancelado');

        return (
            <div className="show_status">
                <div className="show_status_qtd">
                    <div className="">Em negociação:</div>
                    <div className="">{ bud_neg.length }</div>
                </div>
                <div className="show_status_qtd">
                    <div className="">Aprovado:</div>
                    <div className="">{ bud_apr.length }</div>
                </div>
                <div className="show_status_qtd">
                    <div className="">Cancelado:</div>
                    <div className="">{ bud_can.length }</div>
                </div>
            </div>
        )
    }

    return(
        <div className="Report_Clients">
            <div className="new_block">
                <div className="div_return" onClick={ ()=>{ setCurrentYear((currentYear - 1)); } }>
                    <SvgReturn color="#324d6b" className="icons" />
                </div>
                <div className="name_project">
                    { currentYear }
                </div>
                <div className="div_return" onClick={ ()=>{ setCurrentYear((currentYear + 1)); } }>
                    <SvgReturn color="#324d6b" className="icons icon_next" />
                </div>
            </div>
            <div className="return_opt" onClick={ ()=>{ props.CkickPage('report') } }>
                Voltar
            </div>
            <div className="list_data_report">
                <table width="100%" cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20">#</th>
                            <th>Cliente</th>
                            <th width="150">Orçamentos</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            showClients.map((key, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td>{ index + 1 }</td>
                                        <td>{ key.name }</td>
                                        <td>{ QtdBudget(key.id) }</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
