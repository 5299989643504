import { useState, useEffect } from "react";

import { SvgReturn } from "components/SvgFile";

export default function Report_Presentation(props){

    const date = new Date();
    const [ showYear, setShowYear ]       = useState(date.getFullYear());
    const [ currentYear, setCurrentYear ] = useState(showYear);

    return(
        <div className="Report_Presentation">
            <div className="new_block">
                <div className="div_return" onClick={ ()=>{ setCurrentYear((currentYear - 1)); } }>
                    <SvgReturn color="#324d6b" className="icons" />
                </div>
                <div className="name_project">
                    { currentYear }
                </div>
                <div className="div_return" onClick={ ()=>{ setCurrentYear((currentYear + 1)); } }>
                    <SvgReturn color="#324d6b" className="icons icon_next" />
                </div>
            </div>
            <div className="return_opt" onClick={ ()=>{ props.CkickPage('report') } }>
                Voltar
            </div>
        </div>
    )
}
