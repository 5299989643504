const ModalState = {
    Confirmation    : false,
    Profile         : false,
    ShowFile        : false,
    ShowData        : false,
    MonthlyBilling  : false,
    ReturnResponse  : false,
    NewPresentation : false,
    NewProject      : false,
    NewClient       : false,
    NewContact      : false,
    Signature       : false
};

const ModalData = {
    Confirmation    : {},
    Profile         : {},
    ShowFile        : {},
    ShowData        : {},
    MonthlyBilling  : {},
    ReturnResponse  : {},
    NewPresentation : {},
    NewProject      : {},
    NewClient       : {},
    NewContact      : {},
    Signature       : {}
}

const NotifyModalObserver = {
    Confirmation    : [],
    Profile         : [],
    ShowFile        : [],
    ShowData        : [],
    MonthlyBilling  : [],
    ReturnResponse  : [],
    NewPresentation : [],
    NewProject      : [],
    NewClient       : [],
    NewContact      : [],
    Signature       : []
}

const NotifyModalData = {
    Confirmation    : [],
    Profile         : [],
    ShowFile        : [],
    ShowData        : [],
    MonthlyBilling  : [],
    ReturnResponse  : [],
    NewPresentation : [],
    NewProject      : [],
    NewClient       : [],
    NewContact      : [],
    Signature       : []
}
// ModalData
export function SetModalData(modal, data){
    ModalData[modal] = data;
    NotifyModalData[modal].forEach(element => {
        element(data);
    });
}
export function GetModalData(key, value){
    return ModalData[key][value];
}
export function RegisterModalData(modal, func){
    NotifyModalData[modal].push(func);
}
// ModalState
export function SetModalState(modal, state){
    ModalState[modal] = state;
    NotifyModalObserver[modal].forEach(element => {
        element(state);
    });
}
export function GetModalState(key, value){
    return ModalState[key][value];
}
export function RegisterModalObserver(modal, func){
    NotifyModalObserver[modal].push(func);
}
