import { useState, useEffect } from "react";

import './Client.css';

import { SvgSearch, SvgAddOpt_2, SvgTriagle, SvgEye, SvgEdit, SvgDelete, SvgSetaRight } from "components/SvgFile";

import { SetListPag } from "interface/InterfacePag";
import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";
import { SetModalData, SetModalState } from "interface/InterfacePopUp";

export default function Client(){

    const date = new Date();

    const [ search, setSearch ]             = useState('');
    const [ showDataPage, setShowDataPage ] = useState(GetDataPage('client'));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(showDataPage.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showDataPage.slice(startItens, endItens);

    useEffect(()=>{
        RegisterDataPage('client', setShowDataPage);
    }, []);

    function ShowYear(){
        // const newData = [];
        // GetDataPage('client').map((key, index)=>{
        //     newData.push(key.year)
        // })
        // const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        // setListYear(duplicate);
    }

    function SearchInput(value){
        const newData   = [];
        if(value !=''){
            {
                GetDataPage('client').forEach(item =>{
                    if(item.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.corporate_name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.acting_area.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                })
            }
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
            setShowDataPage(duplicate);
        }else if(value == ''){
            setShowDataPage(GetDataPage('client'));
        }
        setSearch(value);
    }

    function NewClients(id){
        SetListPag('currentPage', 'client_details')
        SetListPag('currentPageId', id);
    }

    function DeleteData(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "client", "type": "delete_client", "name": value });
        SetModalState('Confirmation', true);
    }

    return(
        <div className="Client">
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page">
                        Clientes
                    </div>
                </div>
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="buscar" />
                </div>
                <div className="new_block_text new_project" onClick={ ()=>{ NewClients(0) } }>
                    <div className="">
                        <span className="icons_menu">+</span>
                    </div>
                    <div className="">
                        cliente
                    </div>
                </div>
            </div>
            <div className="list_client">
                <table>
                    <tbody>
                        <tr>
                            <th width="20">#</th>
                            <th width="200">Name</th>
                            <th>Razão social</th>
                            <th width="140">Último orçamento</th>
                            <th width="20" align="right">#</th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                                currentItens.map((key, index)=>{
                                    return(
                                        <tr key={ index }>
                                            <td align="left">{ startItens + index + 1 }</td>
                                            <td>{ key.name }</td>
                                            <td>{ key.corporate_name }</td>
                                            <td>{ key.last_purchase_br }</td>
                                            <td align="right">
                                                <div className="list_opt">
                                                    <div onClick={ ()=>{ DeleteData(key.id) } }>
                                                        <SvgDelete color="#f00000" className="icons" />
                                                    </div>
                                                    <div onClick={ ()=>{ NewClients(key.id, key.name) } }>
                                                        <SvgEdit color="#324d6b" className="icons" />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            :
                            <tr>
                                <td colSpan="7">
                                    <div className="no_data">Nenhum cliente encontrado...</div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {
                    showDataPage.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}
