
let DataPage = {
    "accessDash"   : [],
    "about_chroma" : {},
    "portfolio"    : [],
    "presentation" : [],
    "services"     : [],
    "combo"        : [],
    "budget"       : [],
    "client"       : [],
    "category"     : [],
    "signature"    : []
};

let NotifyDataPage = {
    "accessDash"   : [],
    "about_chroma" : [],
    "portfolio"    : [],
    "presentation" : [],
    "services"     : [],
    "combo"        : [],
    "budget"       : [],
    "client"       : [],
    "category"     : [],
    "signature"    : []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["accessDash"].forEach(element => {
        element(value['accessDash']);
    });
    NotifyDataPage["about_chroma"].forEach(element => {
        element(value['about_chroma']);
    });
    NotifyDataPage["portfolio"].forEach(element => {
        element(value['portfolio']);
    });
    NotifyDataPage["presentation"].forEach(element => {
        element(value['presentation']);
    });
    NotifyDataPage["services"].forEach(element => {
        element(value['services']);
    });
    NotifyDataPage["budget"].forEach(element => {
        element(value['budget']);
    });
    NotifyDataPage["combo"].forEach(element => {
        element(value['combo']);
    });
    NotifyDataPage["client"].forEach(element => {
        element(value['client']);
    });
    NotifyDataPage["category"].forEach(element => {
        element(value['category']);
    });
    NotifyDataPage["signature"].forEach(element => {
        element(value['signature']);
    });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}
