import { useState, useEffect, useRef } from "react";

import './New.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config, connectionPage } from "dataFixed";

import { GetListPag, RegisterListPag, SetListPag } from "interface/InterfacePag";

import { SvgAddData, SvgAddOpt_2, SvgCopy, SvgDelete, SvgEye, SvgEyeClose, SvgLink, SvgLink_opt2, SvgPdf, SvgReturn, SvgSave, SvgStatus } from "components/SvgFile";

import { Reg_Budget } from "services/RegisterData";
import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";
import { SetModalData, SetModalState } from "interface/InterfacePopUp";
import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";
import { GetUserData, RegisterUserData } from "interface/InterfaceUsers";

export default function Budget_New(props){

    const editText_1 = useRef();
    const editText_2 = useRef();

    const [ loading, setLoading ]       = useState(false);
    const [ listSig, setListSig ]        = useState(GetDataPage('signature'));
    const [ dataPage, setDataPage ]     = useState(GetDataPage('budget'));
    const [ idUser, setIdUser ]         = useState(GetUserData("id"));
    const [ dataFixed, setDataFixed ]   = useState(GetDataPage("services"));
    const [ showClient, setShowClient ] = useState(GetDataPage("client"));
    const [ idPage, setIdPage ]         = useState(GetListPag('currentPageId'));
    const [ idNew, setIdNew ]           = useState(GetListPag('idNewRegister'));

    const [ showPresentation, setShowPresentation ] = useState(GetDataPage("presentation"));

    const [ code, setCode ]                   = useState(InitialData('code'));
    const [ signature, setSignature ]         = useState(InitialData('signature'));
    const [ typeBudget, setTypeBudget ]       = useState(InitialData('type'));
    const [ client, setClient ]               = useState(InitialData('client'));
    const [ contact, setContact ]             = useState(InitialData('contact'));
    const [ presentation, setPresentation ]   = useState(InitialData('presentation'));
    const [ status, setStatus ]               = useState(InitialData('status'));
    const [ billed, setBilled ]               = useState(InitialData('billed'));
    const [ subject, setSubject ]             = useState(InitialData('subject'));
    const [ estimatedDate, setEstimatedDate ] = useState(InitialData('estimatedDate'));
    const [ dueDate, setDuedDate ]            = useState(InitialData('dueDate'));
    const [ listServices, setListServices ]   = useState(InitialData('listServices'));
    const [ newServices, setNewServices ]     = useState([]);
    const [ tax, setTax ]                     = useState(InitialData('tax'));
    const [ textFixed, setTextFixed ]         = useState(InitialData('textFixed'));

    const [ nameUser, setNameUser ]           = useState(InitialData('nameUser'));
    const [ dataRegister, setDataRegister ]   = useState(InitialData('dataRegister'));
    const [ listContact, setListContact ]     = useState(ListContact());

    const [ nameClient, setNameClient ]           = useState('');
    const [ corporateName, setCorporateName ]     = useState('');
    const [ cnpj, setCnpj ]                       = useState('');
    const [ actingArea, setActingArea ]           = useState('');
    const [ note, setNote ]                       = useState('');
    const [ contactName, setContactName ]         = useState('');
    const [ contactEmail, setContactEmail ]       = useState('');
    const [ contactPhone, setContactPhone ]       = useState('');
    const [ contactPosition, setContactPosition ] = useState('');

    const [ altStatus, setAltStatus ]   = useState(false);
    const [ statusText, setStatusText ] = useState(false);

    const [ cashTax, setCashTax ]     = useState(ShowCash(tax, 'tax'));
    const [ cashTotal, setCashTotal ] = useState(ShowCash(tax, 'total'));

    useEffect(()=>{
        RegisterUserData('id', setIdUser);
        RegisterDataPage('services', setDataFixed);
        RegisterDataPage("budget", setDataPage);
        RegisterDataPage("signature", setListSig);
        RegisterDataPage("client", setShowClient);
        RegisterListPag('currentPageId', setIdPage);
        RegisterListPag('idNewRegister', setIdNew);
        RegisterDataPage("presentation", setShowPresentation);
    }, []);

    useEffect(()=>{
        setIdNew(GetListPag('idNewRegister'));
    }, [idNew]);

    useEffect(()=>{
        setIdUser(GetUserData("id"));
        setDataFixed(GetDataPage("services"));
        setDataPage(GetDataPage('budget'));
        setShowPresentation(GetDataPage("presentation"));
        setIdPage(GetListPag('currentPageId'));

        setCode(InitialData('code'));
        setTypeBudget(InitialData('type'));
        setClient(InitialData('client'));
        setContact(InitialData('contact'));
        setPresentation(InitialData('presentation'));
        setStatus(InitialData('status'));
        setBilled(InitialData('billed'));
        setSubject(InitialData('subject'));
        setEstimatedDate(InitialData('estimatedDate'));
        setDuedDate(InitialData('dueDate'));
        setListServices(InitialData('listServices'));
        setTax(InitialData('tax'));
        setTextFixed(InitialData('textFixed'));
        setNameUser(InitialData('nameUser'));
        setDataRegister(InitialData('dataRegister'));

        setListContact(ListContact());
        setCashTax(ShowCash(tax, 'tax'));
        setCashTotal(ShowCash(tax, 'total'));
    }, [dataPage]);

    useEffect(()=>{
        setIdUser(GetUserData("id"));
        setDataFixed(GetDataPage("services"));
        setDataPage(GetDataPage('budget'));
        setShowPresentation(GetDataPage("presentation"));
        setIdPage(GetListPag('currentPageId'));

        setCode(InitialData('code'));
        setTypeBudget(InitialData('type'));
        setClient(InitialData('client'));
        setContact(InitialData('contact'));
        setPresentation(InitialData('presentation'));
        setStatus(InitialData('status'));
        setBilled(InitialData('billed'));
        setSubject(InitialData('subject'));
        setEstimatedDate(InitialData('estimatedDate'));
        setDuedDate(InitialData('dueDate'));
        setListServices(InitialData('listServices'));
        setTax(InitialData('tax'));
        setTextFixed(InitialData('textFixed'));
        setNameUser(InitialData('nameUser'));
        setDataRegister(InitialData('dataRegister'));

        setListContact(ListContact());
        setCashTax(ShowCash(tax, 'tax'));
        setCashTotal(ShowCash(tax, 'total'));
    }, [idPage]);

    useEffect(()=>{
        setListContact(ListContact());
        setContact(InitialData('contact'));
    }, [client]);

    useEffect(()=>{
        setListContact(ListContact());
        setClient(InitialData('client'));
        setContact(InitialData('contact'));
    }, [typeBudget]);

    useEffect(()=>{
        setCashTax(ShowCash(tax, 'tax'));
        setCashTotal(ShowCash(tax, 'total'));
    }, [listServices]);

    useEffect(()=>{
        setCashTax(ShowCash(tax, 'tax'));
        setCashTotal(ShowCash(tax, 'total'));
    }, [tax]);

    function ShowPresentation(){
        const options = []
        showPresentation.map((key, index)=>{
            let labelOpt = key.name_client + ' - ' + key.title;
            options.push({
                "value": key.id, "label": labelOpt
            });
        })
        return options;
    }

    function ShowServices(type){
        const options = []
        dataFixed.map((key, index)=>{
            if(key.type == 1){
                if(type == 'service'){
                    options.push({
                        "value": key.id, "label": key.title
                    });
                }else {
                    key.listCombo.map((key_1, index_1)=>{
                        options.push({
                            "value": key_1.id, "label": key_1.title
                        });
                    })
                }
            }
        })
        return options;
    }

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            if(newData){
                if(type == 'signature'){
                    if(newData['signature'] != 0){
                        return { "value": newData['signature'], "label": newData['nameSignature'] };
                    }
                    return { "value": "", "label": "..." };
                }
                if(type == 'type'){
                    return { "value": newData['type'], "label": newData['nameType'] };
                }
                if(type == 'client'){
                    if(newData['type'] == 0){
                        return { "value": newData['id_given'], "label": newData['nameClient'] };
                    }
                    return { "value": "", "label": "..." };
                }
                if(type == 'contact'){
                    if(newData['id_given'] == client.value){
                        if(newData['contact'] != 0){
                            return { "value": newData['contact'], "label": newData['nameContact'] };
                        }
                        return { "value": "", "label": "..." };
                    }
                    return { "value": "", "label": "..." };
                }
                if(type == 'presentation'){
                    if(newData['type'] == 1){
                        let nameLabel = newData['nameClient'] + " - " + newData['nameProject'];
                        return { "value": newData['id_given'], "label": nameLabel };
                    }
                    return { "value": "", "label": "..." };
                }
                if(type == 'billed'){
                    if(newData[type] !=''){
                        return { "value": newData[type], "label": newData[type] }
                    }
                    return { "value": "Não faturado", "label": "Não faturado" };
                }
                return newData[type];
            }
            return ReturnPage();
        }
        if(type == 'tax'){
            return 20;
        }
        if(type == 'textFixed'){
            const newData = [];
            dataFixed.map((key, index)=>{
                if(key.type == 0){
                    newData.push({"id": 0, "title": key.title, "text": key.text})
                }
            });
            return newData;
        }
        if(type == 'listServices'){
            return [];
        }
        if(idNew){
            if(type == 'type'){
                return { "value": 1, "label": "Com apresentação" };
            }
            if(type == 'presentation'){
                const newData =  showPresentation.find(item => item.id == idNew);
                let labelOpt = newData.name_client + ' - ' + newData.title;
                return { "value": newData.id, "label": labelOpt };
            }
        }else {
            if(type == 'type'){
                return { "value": 0, "label": "Somente orçamento" };
            }
        }
        return '';
    }

    function NewService(){
        setNewServices([...newServices, { "id": 0, "type": 0, "data": [], "service": [] }]);
    }

    function HandleService(type, index, value){
        const newData = [...newServices];
        newData[index][type] = value;
        setNewServices(newData);
    }

    function HandleServiceData(index, index_1, type, value){
        const newData = [...newServices];
        newData[index]['service'][index_1][type] = value;
        setNewServices(newData);
    }

    function HandleServiceEdit(index, type, value){
        const newData = [...listServices];
        newData[index][type] = value;
        setListServices(newData);
    }

    function HandleTextFixed(type, index, value){
        const newData = [...textFixed];
        newData[index][type] = value;
        setTextFixed(newData);
    }

    function RemuveService(type, index, index_){
        const newData = [...newServices];
        if(type == 0){
            newData.splice(index, 1);
        }else if(type == 1){
            newData[index].service.splice(index_, 1);
        }
        setNewServices(newData);
    }

    function DeleteData(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "budget", "type": "delete_service", "name": value });
        SetModalState('Confirmation', true);
    }

    function ListSignature(){
        const options = []
        listSig.map((key, index)=>{
            options.push({
                "value": key.id, "label": key.name
            });
        })
        return options;
    }

    function ShowClient(){
        const options = [{ "value": "add", "label": "Adicionar nova empresa"}]
        showClient.map((key, index)=>{
            options.push({
                "value": key.id, "label": key.name
            });
        })
        return options;
    }

    function ListContact(){
        const options     = [{ "value": "add", "label": "Adicionar novo contato"}];
        if(client.value > 0){
            const listContact = showClient.find(item => item.id == client.value);
            if(listContact){
                listContact.contact.map((key, index)=>{
                    let phone = '';
                    if(key.phone){
                        phone = " - " + key.phone
                    }
                    let label = key.name + " - " + key.email + phone
                    options.push({
                        "value": key.id, "label": label
                    });
                })
            }
            return options;
        }
        return options;
    }

    function StatusProject(value){
        switch (value) {
            case 'Aprovado':
                return(
                    <div className="status" style={ { color: '#00BC08', backgroundColor: 'rgba(0, 188, 8, 0.2)' }} onClick={ ()=>{ setAltStatus(!altStatus); } }>
                        <SvgStatus color="#00BC08" color1="#00BC08" className="icon_status" />
                        Aprovado
                    </div>
                )

            case 'Em negociação':
                return(
                    <div className="status" style={ { color: '#BC9300', backgroundColor: 'rgba(188, 147, 0, 0.2)' }} onClick={ ()=>{ setAltStatus(!altStatus); } }>
                        <SvgStatus color="#BC9300" color1="#BC9300" className="icon_status" />
                        Em negociação
                    </div>
                )

            case 'Cancelado':
                return(
                    <div className="status" style={ { color: '#AE1B1B', backgroundColor: 'rgba(174, 27, 27, 0.2)' }} onClick={ ()=>{ setAltStatus(!altStatus); } }>
                        <SvgStatus color="#AE1B1B" color1="#AE1B1B" className="icon_status" />
                        Cancelado
                    </div>
                )
        }

    }

    function ShowTextService(index, type, id){
        const newData = [...newServices];
        switch (type) {
            case 0: case '0':
                    const single = dataFixed.find(item => item.id == id);
                    newData[index]['service'] = [{ "id": 0, "id_service": single.id, "title": single.title, "qtd": "", "cash": single.cash, "text": single.text }];
                break;

            case 1: case '1':
                    const multiple = GetDataPage('combo').find(item => item.id == id);
                    const dataMult = [];
                    console.log(multiple.list);
                    multiple.list.map((key, index)=>{
                        dataMult.push({ "id": 0, "id_service": key.id, "title": key.title, "qtd": "", "cash": key.cash, "text": key.text });
                    });
                    newData[index]['service'] = dataMult;
                break;
        }
        setNewServices(newData);
    }

    function ReturnPage(){
        if(idNew !=''){
            SetListPag('currentPage', 'presentation_details');
            SetListPag('currentPageId', 'remuve');
            SetListPag('currentPageId', idNew);
        }else {
            SetListPag('currentPage', 'budget');
            SetListPag('currentPageId', 'remuve');
        }
        window.history.pushState("", "", "/");
    }

    function CopyBudget(){
        SetModalData("Confirmation", { "id": idPage, "origin": "budget", "type": "copy_budget", "name": "#EST-" + code, "idUser": idUser });
        SetModalState('Confirmation', true);
    }

    function CopyLink(value){
        navigator.clipboard.writeText(connectionPage + "?budget=" + value);
    }

    function ShowCash(showTax, type){
        if(idPage != 0){
            let cash = 0;
            let cashTaxa = 0;
            // let showData_1 = cash.toLocaleString('pt-br', { minimumFractionDigits: 2 }); // sem R$
            let showData = 0;

            listServices.map((key, index)=>{
                let cashAlt_ = key.cash.replaceAll('.', '');
                let cashAlt = cashAlt_.replaceAll(',', '.');
                cash += key.qtd * cashAlt;
            });

            let tax_ = showTax.replaceAll('.', '');
            let tax = tax_.replaceAll(',', '.');
            cashTaxa = (cash * tax) / 100;

            if(type == 'tax'){

                showData = cashTaxa.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); // com R$
            }else {
                showData = (cash + cashTaxa).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); // com R$
            }
            return showData;
        }
        return '';
    }

    function HideTextServices(value){
        const newData = [...listServices];
        newData.map((key, index)=>{
            key.status = value;
        });
        setStatusText(value);
        setListServices(newData);
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        setNewServices([]);
        if(signature.value){
            if(typeBudget.value == 0){
                if(client.value){
                    if(contact.value){
                        Reg_Budget(idPage, idUser, status, signature.value, typeBudget.value, client.value, contact.value, presentation.value, subject, estimatedDate, dueDate, listServices, newServices, textFixed, nameClient, corporateName, cnpj, actingArea, note, contactName, contactEmail, contactPhone, contactPosition, tax, billed.value, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
                    }else {
                        CallbackMissingData('É necessário selecionar um contato ou registrar um novo para registrar o orçamento!');
                    }
                }else {
                    CallbackMissingData('É necessário selecionar um cliente ou registrar um novo para registrar o orçamento!');
                }
            }else {
                if(presentation.value){
                    Reg_Budget(idPage, idUser, status, signature.value, typeBudget.value, client.value, contact.value, presentation.value, subject, estimatedDate, dueDate, listServices, newServices, textFixed, nameClient, corporateName, cnpj, actingArea, note, contactName, contactEmail, contactPhone, contactPosition, tax, billed.value, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
                }else {
                    CallbackMissingData('É necessário selecionar uma apresentação para registrar o orçamento!');
                }
            }

        }else {
            CallbackMissingData('É necessário selecionar uma assinatura para registrar o orçamento!');
        }
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackMissingData(text){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "missingData", "text": text });
        SetModalState('ReturnResponse', true);
    }
console.log(statusText);
    return(
        <div className="NewBudget">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() } }>
                        <SvgReturn color="#324d6b" className="icons" />
                        Voltar
                    </div>
                    <div className="name_project">
                        {
                            idPage == 0 ? "Novo orçamento" : "Orçamento #EST-" + code
                        }
                    </div>
                    <div className="list_opt_alt_page">
                        {
                            idPage == 0 ? null :
                            <>
                                <div className="div_icons" onClick={ ()=>{ CopyLink(idPage) } }>
                                    <SvgLink_opt2 color="#324d6b" className="icons" />
                                </div>
                                <div className="div_icons" onClick={ ()=>{ CopyBudget() } }>
                                    <SvgCopy color="#324d6b" className="icons" />
                                </div>
                                <div className="div_icons">
                                    <a href={ "./budget.html?budget=" + (Math.floor(Math.random() * 65536) - 32768) + "_" + idPage } target="_blank">
                                        <SvgPdf color="#F00000" className="icons" />
                                    </a>
                                </div>
                            </>
                        }
                        <button className="new_block_text new_project">
                            <div className="">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="">
                                Salvar
                            </div>
                        </button>
                    </div>
                </div>

                <div className="show_data_register">
                    <div className="div_show_text">
                        <div className="div_data_project">
                            <div className="div_input space_top">
                                <Select className="select_signature" defaultOptions options={ ListSignature() } onChange={ (e)=> { setSignature(e) } } value={ signature } placeholder="..." />
                                <span className="span_search_select">Assinaturas</span>
                            </div>

                            <div className="div_input space_top">
                                <Select className="space_data" defaultOptions options={ [{ "value": 0, "label": "Somente orçamento" }, { "value": 1, "label": "Com apresentação" } ] } value={ typeBudget } onChange={ (e)=> { setTypeBudget(e); } } placeholder="..." />
                                <span className="span_search_select">Tipo de orçamento</span>
                            </div>
                            {
                                typeBudget.value == 0 ?
                                <div className="div_input space_top space_data">
                                    <Select className="space_data" defaultOptions options={ ShowClient() } value={ client } onChange={ (e)=> { setClient(e) } } placeholder="..." />
                                    <span className="span_search_select">Cliente</span>
                                </div> :
                                <div className="div_input space_top space_data">
                                    <Select className="space_data" defaultOptions options={ ShowPresentation() } value={ presentation } onChange={ (e)=> { setPresentation(e); } } placeholder="..." />
                                    <span className="span_search_select">Apresentações</span>
                                </div>
                            }
                            {
                                typeBudget.value == 0 ?
                                    client.value != 'add' ?
                                    <div className="div_input space_top">
                                        <Select className="select_contact" defaultOptions options={ listContact } onChange={ (e)=> { setContact(e); } } value={ contact } placeholder="..." />
                                        <span className="span_search_select">Contatos</span>
                                    </div>
                                    : null
                                : null
                            }
                        </div>

                        {
                            client.value != 'add' ? null :
                            <div className="div_show_text">
                                <div className="show_title">
                                    <div className="title_div">Dados da nova empresa</div>
                                </div>
                                <div className="data_client">
                                    <div className="div_data_project">
                                        <div className="div_input space_top space_data">
                                            <input type="text" className="" onChange={ (e)=>{ setNameClient(e.target.value) } } value={ nameClient } maxLength="140" />
                                            <span className="span_name_input">Nome</span>
                                        </div>
                                        <div className="div_input space_top space_data">
                                            <input type="text" className="" onChange={ (e)=>{ setCorporateName(e.target.value) } } value={ corporateName } maxLength="100" />
                                            <span className="span_name_input">Razão Social</span>
                                        </div>
                                    </div>

                                    <div className="div_data_project space_top">
                                        <div className="div_input space_top">
                                            <input type="text" className="cnpj" onChange={ (e)=>{ setCnpj(e.target.value) } } value={ cnpj } maxLength="18" />
                                            <span className="span_name_input">CNPJ</span>
                                        </div>
                                        <div className="div_input space_top space_data">
                                            <input type="text" className="" onChange={ (e)=>{ setActingArea(e.target.value) } } value={ actingArea } maxLength="100" />
                                            <span className="span_name_input">Área de atuação</span>
                                        </div>
                                    </div>

                                    <div className="div_data_project space_top">
                                        <div className="div_input space_top space_data">
                                            <JoditEditor config={ config } value={ note } onBlur={ newContent => setNote(newContent) } />
                                        </div>
                                    </div>

                                    <div className="div_data_project space_top">
                                        <div className="div_input space_top space_data">
                                            <input type="text" onChange={ (e)=>{ setContactName(e.target.value) } } value={ contactName } maxLength="80" />
                                            <span className="span_name_input">Nome do contato</span>
                                        </div>
                                        <div className="div_input space_top space_data">
                                            <input type="text" onChange={ (e)=>{ setContactEmail(e.target.value) } } value={ contactEmail } maxLength="40" />
                                            <span className="span_name_input">E-mail</span>
                                        </div>
                                    </div>
                                    <div className="div_data_project space_top">
                                        <div className="div_input space_top">
                                            <input type="text" className="phone" onChange={ (e)=>{ setContactPhone(e.target.value) } } value={ contactPhone } maxLength="20" />
                                            <span className="span_name_input">Telefone</span>
                                        </div>
                                        <div className="div_input space_top space_data">
                                            <input type="text" onChange={ (e)=>{ setContactPosition(e.target.value) } } value={ contactPosition } maxLength="50" />
                                            <span className="span_name_input">Cargo</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        }

                        {
                            contact.value !='add' ? null :
                            <div className="div_show_text">
                                <div className="show_title">
                                    <div className="title_div">Dados do novo contato</div>
                                </div>
                                <div className="data_client">

                                    <div className="div_data_project space_top">
                                        <div className="div_input space_top space_data">
                                            <input type="text" onChange={ (e)=>{ setContactName(e.target.value) } } value={ contactName } maxLength="80" />
                                            <span className="span_name_input">Nome do contato</span>
                                        </div>
                                        <div className="div_input space_top space_data">
                                            <input type="text" onChange={ (e)=>{ setContactEmail(e.target.value) } } value={ contactEmail } maxLength="40" />
                                            <span className="span_name_input">E-mail</span>
                                        </div>
                                    </div>
                                    <div className="div_data_project space_top">
                                        <div className="div_input space_top">
                                            <input type="text" className="phone" onChange={ (e)=>{ setContactPhone(e.target.value) } } value={ contactPhone } maxLength="20" />
                                            <span className="span_name_input">Telefone</span>
                                        </div>
                                        <div className="div_input space_top space_data">
                                            <input type="text" onChange={ (e)=>{ setContactPosition(e.target.value) } } value={ contactPosition } maxLength="50" />
                                            <span className="span_name_input">Cargo</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        }

                        <div className="div_data_project">
                            <div className="div_input space_top space_data">
                                <input type="text" className="" onChange={ (e)=>{ setSubject(e.target.value) } } value={ subject } maxLength="140" required />
                                <span className="span_name_input">Assunto</span>
                            </div>

                            <div className="div_input space_top">
                                <input type="date" className="date_space" onChange={ (e)=>{ setEstimatedDate(e.target.value) } } value={ estimatedDate } maxLength="140" required />
                                <span className="span_search_select">Data do evento</span>
                            </div>

                            <div className="div_input space_top">
                                <input type="date" className="date_space" onChange={ (e)=>{ setDuedDate(e.target.value) } } value={ dueDate } maxLength="140" required />
                                <span className="span_search_select">Data Vencimento</span>
                            </div>

                            <div className="div_input space_top">
                                {
                                    StatusProject(status)
                                }
                                {
                                    altStatus == true ?
                                    <div className="opt_status">
                                        {
                                            status == 'Em negociação' ? null :
                                            <div className="status sub_" onClick={ ()=>{ setStatus('Em negociação'); setAltStatus(false); } }>
                                                <SvgStatus color="#BC9300" color1="#BC9300" className="icon_status" />
                                                Em negociação
                                            </div>
                                        }
                                        {
                                            status == 'Aprovado' ? null :
                                            <div className="status sub_" onClick={ ()=>{ setStatus('Aprovado'); setAltStatus(false); } }>
                                                <SvgStatus color="#00BC08" color1="#00BC08" className="icon_status" />
                                                Aprovado
                                            </div>
                                        }
                                        {
                                            status == 'Cancelado' ? null :
                                            <div className="status sub_" onClick={ ()=>{ setStatus('Cancelado'); setAltStatus(false); } }>
                                                <SvgStatus color="#AE1B1B" color1="#AE1B1B" className="icon_status" />
                                                Cancelado
                                            </div>
                                        }
                                    </div> : null
                                }
                            </div>

                            {
                                status != 'Aprovado' ? null :
                                <div className="div_input space_top">
                                    <Select className="select_signature" defaultOptions options={[{ "value": "Faturado", "label": "Faturado" }, { "value": "Não faturado", "label": "Não faturado" }]} onChange={ (e)=> { setBilled(e) } } value={ billed } placeholder="..." />
                                    <span className="span_search_select">Faturamento</span>
                                </div>
                            }

                        </div>
                    </div>

                    <div className="div_show_or_new_data div_show_text">
                        <div className="show_title">
                            <div className="title_div">Serviços</div>
                            <div className="title_div">
                                <div className="div_input space_data">
                                    <label>Imposto sobre o serviço</label>
                                    <div>
                                        <input type="text" className="tax" onChange={ (e)=>{ setTax(e.target.value) } } maxLength="6" value={ tax } />
                                    </div>
                                    <label>Taxa: { cashTax }</label>
                                    <label>Valor: { cashTotal }</label>
                                    <div className="open_or_close_text" onClick={ ()=>{ HideTextServices(!statusText) } }>
                                        {
                                            statusText != true ?
                                            <SvgEye color="#324d6b" className="icons" />:
                                            <SvgEyeClose color="#324d6b" className="icons" />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="div_show_text space_show_data">
                            {
                                listServices.map((key, index)=>{
                                    return(
                                        <div className="list_opt_add show_data_add" key={ index }>
                                            <div className="data_services _registered">
                                                <div className="div_data_project">
                                                    <div className="div_input space_data">
                                                        <input type="text" className="" onChange={ (e)=>{ HandleServiceEdit(index, 'title', e.target.value) } } value={ key.title } maxLength="140" required />
                                                        <span className="span_name_input">Título</span>
                                                    </div>

                                                    <div className="div_input">
                                                        <input type="text" className="cash" onChange={ (e)=>{ HandleServiceEdit(index, 'qtd', e.target.value) } } value={ key.qtd } maxLength="20" required />
                                                        <span className="span_name_input">Qtd</span>
                                                    </div>

                                                    <div className="div_input" title="Valor unitário">
                                                        <input type="text" className="cash" onChange={ (e)=>{ HandleServiceEdit(index, 'cash', e.target.value) } } value={ key.cash } maxLength="20" required />
                                                        <span className="span_name_input">Valor uni.</span>
                                                    </div>
                                                    <div className="" onClick={ ()=>{ DeleteData(key.id, key.title) } } style={ { display: 'flex' } }>
                                                        <SvgDelete color="#f00000" className="icons" />
                                                    </div>
                                                    <div className="open_or_close_text" onClick={ ()=>{ HandleServiceEdit(index, 'status', !key.status) } }>
                                                        {
                                                            key.status == true ?
                                                            <SvgEyeClose color="#324d6b" className="icons" />:
                                                            <SvgEye color="#324d6b" className="icons" />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="show_textarea">
                                                    {
                                                        key.status == true ? null :
                                                        <JoditEditor className="height_textarea" config={ config } value={ key.text ? key.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleServiceEdit(index, 'text', newContent) } />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                newServices.map((key, index)=>{
                                    return(
                                        <div className="list_opt_add show_data_add" key={ index }>
                                            <div className="div_data_project">
                                                <div className="div_input space_top">
                                                    <select onChange={ (e)=>{ HandleService('type', index, e.target.value); HandleService('data', index, ''); HandleService('service', index, []); } } value={ key.type }>
                                                        <option value="0">Serviços</option>
                                                        <option value="1">Combo</option>
                                                    </select>
                                                    <span className="span_search_select">Tipo</span>
                                                </div>
                                                <div className="div_input space_top space_data">
                                                    {
                                                        key.type == 0 ?
                                                        <>
                                                            <Select className="space_data" defaultOptions options={ ShowServices('service') } onChange={ (e)=> { HandleService('data', index, e); ShowTextService(index, key.type, e.value); } } value={ key.data } placeholder="..." />
                                                            <span className="span_search_select">Lista dos serviços</span>
                                                        </> :
                                                        <>
                                                            <Select className="space_data" defaultOptions options={ ShowServices('combo') } onChange={ (e)=> { HandleService('data', index, e); ShowTextService(index, key.type, e.value); } } value={ key.data } placeholder="..." />
                                                            <span className="span_search_select">Lista dos combos</span>
                                                        </>
                                                    }
                                                </div>
                                                <div className="" onClick={ ()=>{ RemuveService(0, index) } } style={ { display: 'flex' } }>
                                                    <SvgDelete color="#f00000" className="icons" />
                                                </div>
                                            </div>
                                            {
                                                key.service.map((key_1, index_1)=>{
                                                    return(
                                                        <div className="data_services" key={ index_1 }>
                                                            <div className="div_data_project">
                                                                <div className="div_input space_data">
                                                                    <input type="text" className="" onChange={ (e)=>{ HandleServiceData(index, index_1, 'title', e.target.value) } } value={ key_1.title } maxLength="140" required />
                                                                    <span className="span_name_input">Título</span>
                                                                </div>

                                                                <div className="div_input">
                                                                    <input type="text" className="cash" onChange={ (e)=>{ HandleServiceData(index, index_1, 'qtd', e.target.value) } } value={ key_1.qtd } maxLength="20" required />
                                                                    <span className="span_name_input">Qtd</span>
                                                                </div>

                                                                <div className="div_input" title="Valor unitário">
                                                                    <input type="text" className="cash" onChange={ (e)=>{ HandleServiceData(index, index_1, 'cash', e.target.value) } } value={ key_1.cash } maxLength="20" required />
                                                                    <span className="span_name_input">Valor uni.</span>
                                                                </div>

                                                                <div className="" onClick={ ()=>{ RemuveService(1, index, index_1) } }>
                                                                    <SvgDelete color="#f00000" className="icons" />
                                                                </div>
                                                            </div>
                                                            <div className="show_textarea">
                                                                <JoditEditor config={ config } value={ key_1.text ? key_1.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleServiceData(index, index_1, 'text', newContent) } />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                            <div className="list_services">
                                <div onClick={ ()=>{ NewService() } } style={ { display: 'flex' } }>
                                    <SvgAddData color="#F00000" className="icons" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="div_show_or_new_data div_show_text">
                        <div className="show_title">
                            <div className="title_div">Texto fixo</div>
                        </div>
                        <div className="div_show_text space_show_data">
                            {
                                textFixed.map((key, index)=>{
                                    return(
                                        <div className="list_opt_add show_data_add" key={ index }>
                                            <div className="div_data_project">
                                                <div className="div_input show_textarea">
                                                    <div className="div_input space_data">
                                                        <input type="text" className="" onChange={ (e)=>{ HandleTextFixed('title', index, e.target.value) } } value={ key.title } maxLength="140" required />
                                                        <span className="span_name_input">Título</span>
                                                    </div>
                                                </div>
                                                <div className="show_textarea">
                                                    {
                                                        key.status == true ? null :
                                                        <JoditEditor className="height_textarea" config={ config } value={ key.text ? key.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleTextFixed('text', index, newContent) } />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </form>
            <PopUP_ReturnResponse />
        </div>
    )
}
