import { useState, useEffect } from "react";

import './Services.css';

import { SvgSearch, SvgAddOpt_2, SvgEdit, SvgTriagle, SvgDelete, SvgSetaRight } from "components/SvgFile";

import { SetListPag } from "interface/InterfacePag";
import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";
import { SetModalData, SetModalState } from "interface/InterfacePopUp";

export default function Services(){

    const [ search, setSearch ]     = useState('');
    const [ dataPage, setDataPage ] = useState(InitialData());

    const [ listDataFixed, setListDataFixed ] = useState(ShowDataFixed());

    const [ combo, setCombo ]             = useState('All');
    const [ statusCombo, setStatusCombo ] = useState(false);
    const [ listCombo, setListCombo ]     = useState([]);

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(dataPage.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = dataPage.slice(startItens, endItens);

    useEffect(()=>{
        RegisterDataPage('services', setDataPage);
        ListCombo();
    }, []);

    function InitialData(){
        const newData = [];
        GetDataPage('services').map((key, index)=>{
            if(key.type == 1){
                newData.push(key);
            }
        })
        return newData;
    }

    function SearchInput(value){
        const newData   = [];
        if(value !=''){
            {
                GetDataPage("services").forEach(item =>{
                    if(item.type == 1){
                        if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                            newData.push(item);
                        }
                        if(item.text.toLowerCase().indexOf(value.toLowerCase()) != -1){
                            newData.push(item);
                        }
                    }
                })
            }
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
            setDataPage(duplicate);

        }else if(value == ''){
            GetDataPage('services').map((key, index)=>{
                if(key.type == 1){
                    newData.push(key);
                }
            })
            setDataPage(newData);
        }
        setCombo('All');
        setSearch(value);
        setStatusCombo(false);
    }

    function SearchCombo(value){
        const newData = [];
        GetDataPage('services').map((key, index)=>{
            if(key.type == 1){
                if(value == 'All'){
                    newData.push(key);
                }else {
                    key.listCombo.map((key_1, index_1)=>{

                        if(key_1.title.toLowerCase() == value.toLowerCase()){
                            newData.push(key);
                        }
                    });
                }
            }
        })
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        setCombo(value);
        setStatusCombo(false);
        setDataPage(duplicate);
        setSearch('');
    }

    function ShowDataFixed(){
        const newData = [];
        GetDataPage('services').map((key, index)=>{
            if(key.type == 0){
                newData.push(key);
            }
        })
        return newData;
    }

    function ListCombo(){
        const newData = [];
        GetDataPage('services').map((key, index)=>{
            if(key.type == 1){
                key.listCombo.map((key_1, index_1)=>{
                    newData.push(key_1.title);
                });
            }
        })
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        setListCombo(duplicate);
    }

    function NewServices(id){
        SetListPag('currentPage', 'services_details')
        SetListPag('currentPageId', id);
    }

    function DeleteData(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "services", "type": "delete_services_all", "name": value });
        SetModalState('Confirmation', true);
    }

    return (
        <div className="Services">
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page">
                        Serviços
                    </div>
                </div>
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="buscar" />

                    <div className="click_show_search">
                        <div className="div_input" onClick={ ()=>{ setStatusCombo(!statusCombo); } }>
                            <div className="div_select search_status">
                                { combo }
                                {
                                    statusCombo == true ?
                                    <SvgTriagle color="#747474" className="icon_select_open" /> :
                                    <SvgTriagle color="#747474" className="icon_select_close" />
                                }
                            </div>
                            <span className="span_name_select">Combo</span>
                        </div>
                        {
                            statusCombo == true ?
                            <div className="click_show_search_selected search_status_">
                                <div className="opt_list_search" onClick={ ()=>{ SearchCombo('All'); } }>All</div>
                                {
                                    listCombo.map((key, index)=>{
                                        return (
                                            <div className="opt_list_search" key={ index } onClick={ ()=>{ SearchCombo(key); } }>
                                                { key }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            : null
                        }
                    </div>
                </div>
                <div className="list_opt_alt_page">
                    <div className="new_block_text new_project" onClick={ ()=>{ NewServices(0) } }>
                        <SvgAddOpt_2 color="#ffffff" className="icons" />
                        Serviço
                    </div>
                </div>
            </div>
            <div className="list_services">
                <table className="show_data_fixed">
                    <tbody>
                        {
                            listDataFixed.map((key, index)=>{
                                if(key.type == 0){
                                    return(
                                        <tr key={ index }>
                                            <td colSpan="3">{ key.title }</td>
                                            <td align="right">
                                                <div onClick={ ()=>{ NewServices(key.id) } }>
                                                    <SvgEdit color="#324d6b" className="icons"/>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                            })
                        }
                    </tbody>
                </table>
                <table>
                    <tbody>
                        <tr>
                            <th width="20">#</th>
                            <th>Título</th>
                            <th width="300">Combo</th>
                            <th width="20">#</th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                                currentItens.map((key, index)=>{
                                    return(
                                        <tr key={ index }>
                                            <td>{ startItens + index + 1 }</td>
                                            <td>{ key.title }</td>
                                            <td>
                                                {
                                                    key.listCombo.length > 0 ?
                                                    key.listCombo.map((key_1, index_1)=>{
                                                        return(
                                                            <div className="" key={ index_1 }>
                                                                { key_1.title }
                                                            </div>
                                                        )
                                                    }) :
                                                    <div className="">
                                                        Não foi definido...
                                                    </div>
                                                }
                                            </td>
                                            <td align="right">
                                                <div className="list_opt">
                                                    <div onClick={ ()=>{ DeleteData(key.id, key.title) } } style={ { display: "flex" } }>
                                                        <SvgDelete color="#f00000" className="icons" />
                                                    </div>
                                                    <div onClick={ ()=>{ NewServices(key.id) } }>
                                                        <SvgEdit color="#324d6b" className="icons"/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            :
                            <tr>
                                <td colSpan="7">
                                    <div className="no_data">Nenhum serviço encontrado...</div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {
                    dataPage.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}
