import { useState, useEffect } from "react";

import { SvgAddOpt_2, SvgCopy, SvgEdit, SvgEye, SvgSearch, SvgSetaRight, SvgStatus, SvgTriagle } from "components/SvgFile";

import { monthList } from "dataFixed";

import { GetDataPage, RegisterDataPage, SetListDataSingle } from "interface/InterfaceData";
import { SetListPag } from "interface/InterfacePag";

import './Presentation.css';
import { Reg_UpdatePresentation } from "services/RegisterData";
import { SetModalData, SetModalState } from "interface/InterfacePopUp";
import PopUP_NewPresentation from "components/PopUp/NewPresentation";
import Budget_New from "../Budget/New";

export default function Presentation(){

    const date = new Date();

    const [ search, setSearch ]     = useState('');
    const [ year, setYear ]         = useState(date.getFullYear());

    const [ listYear, setListYear ]       = useState([]);
    const [ clickYear, setClickYear ]     = useState(false);
    const [ listMonth, setListMonth ]     = useState([]);
    const [ clickMonth, setClickMonth ]   = useState(false);
    const [ listStatus, setListStatus ]   = useState([]);
    const [ clickStatus, setClickStatus ] = useState(false);

    const [ month, setMonth ]         = useState('All');
    const [ nameMonth, setNameMonth ] = useState('All');
    const [ status, setStatus ]       = useState('All');

    const [ showData, setShowData ]       = useState(InitialData(year));
    const [ showDataAll, setShowDataAll ] = useState(InitialData('All'));

    const [ itensPage, setItensPage ] = useState(30);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(showData.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showData.slice(startItens, endItens);

    useEffect(()=>{
        RegisterDataPage('presentation', setShowData);
        ShowYear();
        ShowMonth();
        ShowStatus();
    }, []);

    function InitialData(type){
        const newData = [];
        GetDataPage('presentation').forEach(item =>{
            if(type == 'All'){
                newData.push(item);
            }else{
                if(item.year.toLowerCase().indexOf(type) != -1){
                    newData.push(item);
                }
            }
        });
        return newData;
    }

    function ShowYear(){
        const newData = [];
        GetDataPage('presentation').map((key, index)=>{
            newData.push(key.year)
        })
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        setListYear(duplicate);
    }

    function ShowMonth(){
        const newData = [];
        GetDataPage('presentation').map((key, index)=>{
            newData.push(key.month)
        })
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        setListMonth(duplicate);
    }

    function ShowStatus(){
        const newData = [];
        GetDataPage('presentation').map((key, index)=>{
            newData.push(key.status)
        })
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        setListStatus(duplicate);
    }

    function SearchInput(value){
        const newData   = [];

        const newYear   = [];
        const newMonth  = [];
        const newStatus = [];

        // Year
        {
            showDataAll.map((key, index)=>{
                if(year == 'All'){
                    newYear.push(key);
                }else {
                    if(key.year == year){
                        newYear.push(key);
                    }
                }
            })
        }
        // Month
        {
            newYear.map((key, index)=>{
                if(month == 'All'){
                    newMonth.push(key);
                }else {
                    if(key.month == month){
                        newMonth.push(key);
                    }
                }
            })
        }
        // Status
        {
            newMonth.map((key, index)=>{
                if(status == 'All'){
                    newStatus.push(key);
                }else {
                    if(key.status == status){
                        newStatus.push(key);
                    }
                }
            })
        }

        if(value !=''){
            {
                newStatus.forEach(item =>{
                    if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.name_client.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.description.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                })
            }
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
            setShowData(duplicate);

        }else if(value == ''){
            setShowData(newStatus);
        }
        setSearch(value);
        setClickYear(false);
        setClickMonth(false);
        setClickStatus(false);
    }

    function SearchYear(value){
        const newData   = [];

        const newYear   = [];
        const newMonth  = [];
        const newStatus = [];

        // Year
        {
            showDataAll.map((key, index)=>{
                if(value == ''){
                    newYear.push(key);
                }else if(value == 'All'){
                    newYear.push(key);
                }else {
                    if(key.year == value){
                        newYear.push(key);
                    }
                }
            })
        }
        // Month
        {
            newYear.map((key, index)=>{
                if(month == 'All'){
                    newMonth.push(key);
                }else {
                    if(key.month == month){
                        newMonth.push(key);
                    }
                }
            })
        }
        // Status
        {
            newMonth.map((key, index)=>{
                if(status == 'All'){
                    newStatus.push(key);
                }else {
                    if(key.status == status){
                        newStatus.push(key);
                    }
                }
            })
        }
        if(search !=''){
            {
                newStatus.forEach(item =>{
                    if(item.title.toLowerCase().indexOf(search.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.name_client.toLowerCase().indexOf(search.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.description.toLowerCase().indexOf(search.toLowerCase()) != -1){
                        newData.push(item);
                    }
                })
            }
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
            setShowData(duplicate);
        }else {
            setShowData(newStatus);
        }
        setYear(value);
        setClickYear(false);
        setClickMonth(false);
        setClickStatus(false);
    }

    function SearchMonth(value, name){
        const newData   = [];

        const newYear   = [];
        const newMonth  = [];
        const newStatus = [];

        // Year
        {
            showDataAll.map((key, index)=>{
                if(year == 'All'){
                    newYear.push(key);
                }else {
                    if(key.year == year){
                        newYear.push(key);
                    }
                }
            })
        }
        // Month
        {
            newYear.map((key, index)=>{
                if(value == 'All'){
                    newMonth.push(key);
                }else {
                    if(key.month == value){
                        newMonth.push(key);
                    }
                }
            })
        }
        // Status
        {
            newMonth.map((key, index)=>{
                if(status == 'All'){
                    newStatus.push(key);
                }else {
                    if(key.status == status){
                        newStatus.push(key);
                    }
                }
            })
        }
        if(search !=''){
            {
                newStatus.forEach(item =>{
                    if(item.title.toLowerCase().indexOf(search.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.name_client.toLowerCase().indexOf(search.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.description.toLowerCase().indexOf(search.toLowerCase()) != -1){
                        newData.push(item);
                    }
                })
            }
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
            setShowData(duplicate);
        }else {
            setShowData(newStatus);
        }
        setNameMonth(name);
        setMonth(value);
        setClickYear(false);
        setClickMonth(false);
        setClickStatus(false);
    }

    function SearchStatus(value){
        const newData   = [];

        const newYear   = [];
        const newMonth  = [];
        const newStatus = [];

        // Year
        {
            showDataAll.map((key, index)=>{
                if(year == 'All'){
                    newYear.push(key);
                }else {
                    if(key.year == year){
                        newYear.push(key);
                    }
                }
            })
        }
        // Month
        {
            newYear.map((key, index)=>{
                if(month == 'All'){
                    newMonth.push(key);
                }else {
                    if(key.month == month){
                        newMonth.push(key);
                    }
                }
            })
        }
        // Status
        {
            newMonth.map((key, index)=>{
                if(value == 'All'){
                    newStatus.push(key);
                }else {
                    if(key.status == value){
                        newStatus.push(key);
                    }
                }
            })
        }
        if(search !=''){
            {
                newStatus.forEach(item =>{
                    if(item.title.toLowerCase().indexOf(search.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.name_client.toLowerCase().indexOf(search.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.description.toLowerCase().indexOf(search.toLowerCase()) != -1){
                        newData.push(item);
                    }
                })
            }
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
            setShowData(duplicate);
        }else {
            setShowData(newStatus);
        }
        setStatus(value);
        setClickYear(false);
        setClickMonth(false);
        setClickStatus(false);
    }

    function PageClick(idPage){
        Reg_UpdatePresentation();
        setTimeout(() => {
            SetListPag('currentPage', 'presentation_details')
            SetListPag('currentPageId', idPage);
        }, 100);
    }

    function CopyLink(value){
        navigator.clipboard.writeText("https://portfolio.chroma-garden.com/presentation.html?project=" + value);
    }

    function NewPresentation(){
        SetModalData("NewPresentation");
        SetModalState('NewPresentation', true);
    }

    function RegisterBudget(idProject, idBudget){
        SetListPag('currentPage', 'budget_details');
        SetListPag('currentPageId', idBudget);
        SetListPag('idNewRegister', idProject);
    }

    return(
        <div className="Presentation">
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page">
                        Apresentações
                    </div>
                </div>
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="buscar" />

                    <div className="click_show_search">
                        <div className="div_input" onClick={ ()=>{ setClickYear(!clickYear); setClickMonth(false); setClickStatus(false) } }>
                            <div className="div_select search_year">
                                { year }
                                {
                                    clickYear == true ?
                                    <SvgTriagle color="#747474" className="icon_select_open" /> :
                                    <SvgTriagle color="#747474" className="icon_select_close" />
                                }
                            </div>
                            <span className="span_name_select">Ano</span>
                        </div>
                        {
                            clickYear == true ?
                            <div className="click_show_search_selected click_show_search_selected_year">
                                <div className="opt_list_search" onClick={ ()=>{ SearchYear('All'); } }>All</div>
                                {
                                    listYear.map((key, index)=>{
                                        return (
                                            <div className="opt_list_search" key={ index } onClick={ ()=>{ SearchYear(key); } }>
                                                { key }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            : null
                        }
                    </div>

                    <div className="click_show_search">
                        <div className="div_input" onClick={ ()=>{ setClickYear(false); setClickMonth(!clickMonth); setClickStatus(false) } }>
                            <div className="div_select search_month">
                                { nameMonth }
                                {
                                    clickMonth == true ?
                                    <SvgTriagle color="#747474" className="icon_select_open" /> :
                                    <SvgTriagle color="#747474" className="icon_select_close" />
                                }
                            </div>
                            <span className="span_name_select">Mês</span>
                        </div>
                        {
                            clickMonth == true ?
                            <div className="click_show_search_selected">
                                <div className="opt_list_search" onClick={ ()=>{ SearchMonth('All', 'All'); } }>All</div>
                                {
                                    listMonth.map((key, index)=>{
                                        let name_month = monthList.find(item => item.month == key);
                                        return (
                                            <div className="opt_list_search" key={ index } onClick={ ()=>{ SearchMonth(key, name_month['name']); } }>
                                                { name_month['name'] }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            : null
                        }
                    </div>

                    <div className="click_show_search">
                        <div className="div_input" onClick={ ()=>{ setClickYear(false); setClickMonth(false); setClickStatus(!clickStatus) } }>
                            <div className="div_select search_status">
                                { status }
                                {
                                    clickStatus == true ?
                                    <SvgTriagle color="#747474" className="icon_select_open" /> :
                                    <SvgTriagle color="#747474" className="icon_select_close" />
                                }
                            </div>
                            <span className="span_name_select">Status</span>
                        </div>
                        {
                            clickStatus == true ?
                            <div className="click_show_search_selected">
                                <div className="opt_list_search" onClick={ ()=>{ SearchStatus('All'); } }>All</div>
                                {
                                    listStatus.map((key, index)=>{
                                        return (
                                            <div className="opt_list_search" key={ index } onClick={ ()=>{ SearchStatus(key); } }>
                                                { key }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            : null
                        }
                    </div>
                </div>
                <div className="new_block_text new_project" onClick={ ()=>{ NewPresentation() } }>
                    <div className="">
                        <span className="icons_menu">+</span>
                    </div>
                    <div className="">
                        apresentação
                    </div>
                </div>
            </div>

            <div className="list_presentation">
                <table>
                    <tbody>
                        <tr>
                            <th width="20">#</th>
                            <th width="80">Status</th>
                            <th>Cliente</th>
                            <th>Projeto</th>
                            <th width="80">Data</th>
                            <th width="90">Orçamento</th>
                            <th width="20">#</th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                                currentItens.map((key, index)=>{
                                    return(
                                        <tr key={ index }>
                                            <td align="left">{ startItens + index + 1 }</td>
                                            <td>{ key.status }</td>
                                            <td>{ key.name_client }</td>
                                            <td>{ key.title }</td>
                                            <td>{ key.date_br }</td>
                                            <td>
                                                {
                                                    key.budget == 0 ?
                                                    <div className="budget new_budget" onClick={ ()=>{ RegisterBudget(key.id, key.budget) } }>+Orçamento</div> :
                                                    <div className="budget show_budget" onClick={ ()=>{ RegisterBudget(key.id, key.budget) } }>Ver orçamento</div>
                                                }
                                            </td>
                                            <td align="right">
                                                <div className="list_opt">
                                                    <div className="" title="Copiar link" onClick={ ()=>{ CopyLink(key.id) } }>
                                                        <SvgCopy color="#324d6b" className="icons" />
                                                    </div>
                                                    <div>
                                                        <a href={ "./presentation.html?project=" + key.id } target="_blank">
                                                            <div className="icons_project">
                                                                <SvgEye color="#324d6b" className="icons" />
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div onClick={ ()=>{ PageClick(key.id) } }>
                                                        <SvgEdit color="#324d6b" className="icons"/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            :
                            <tr>
                                <td colSpan="7">
                                    <div className="no_data">Nenhum portfólio encontrado...</div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                <div className="div_qtd_presentation">
                    <span className="count_presentation">
                        {
                            GetDataPage('presentation').length == 0 ?
                            "Nenhuma apresentação criada" :
                                GetDataPage('presentation').length == 1 ?
                                    GetDataPage('presentation').length + " apresentação criada" :
                                    GetDataPage('presentation').length + " apresentações criadas"
                        }
                    </span>
                </div>
                {
                    showData.length >= 30 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
            <PopUP_NewPresentation />
        </div>
    )
}
