import { useState, useEffect } from "react";

import './Budget.css';

import { monthList } from "dataFixed";

import { SvgSearch, SvgAddOpt_2, SvgTriagle, SvgEye, SvgEdit, SvgPdf, SvgSignature, SvgCheked, SvgAlert, SvgSetaRight } from "components/SvgFile";

import { SetListPag } from "interface/InterfacePag";
import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";

export default function Budget(){

    const date = new Date();

    const [ search, setSearch ]           = useState('');
    const [ year, setYear ]               = useState(date.getFullYear());
    const [ listYear, setListYear ]       = useState([]);
    const [ clickYear, setClickYear ]     = useState(false);
    const [ listMonth, setListMonth ]     = useState([]);
    const [ clickMonth, setClickMonth ]   = useState(false);
    const [ listStatus, setListStatus ]   = useState([]);
    const [ clickStatus, setClickStatus ] = useState(false);

    const [ month, setMonth ]         = useState('All');
    const [ nameMonth, setNameMonth ] = useState('All');
    const [ status, setStatus ]       = useState('All');

    const [ showDataPage, setShowDataPage ] = useState(InitialData(year));
    const [ showDataAll, setShowDataAll ]   = useState(InitialData('All'));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(showDataPage.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showDataPage.slice(startItens, endItens);

    useEffect(()=>{
        RegisterDataPage('budget', setShowDataPage);
        ShowYear();
        ShowMonth();
        ShowStatus();
    }, []);

    useEffect(()=>{
        ShowYear();
        ShowMonth();
        ShowStatus();
    }, [showDataPage]);

    function InitialData(type){
        const newData = [];
        GetDataPage('budget').forEach(item =>{
            if(type == 'All'){
                newData.push(item);
            }else{
                if(item.year == type){
                    newData.push(item);
                }
            }
        });
        return newData;
    }

    function ShowYear(){
        const newData = [];
        GetDataPage('budget').map((key, index)=>{
            newData.push(key.year)
        })
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        setListYear(duplicate);
    }

    function ShowMonth(){
        const newData = [];
        GetDataPage('budget').map((key, index)=>{
            newData.push(key.month)
        })
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        setListMonth(duplicate);
    }

    function ShowStatus(){
        const newData = [];
        GetDataPage('budget').map((key, index)=>{
            newData.push(key.status)
        })
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        setListStatus(duplicate);
    }

    function SearchInput(value){
        const newData   = [];

        const newYear   = [];
        const newMonth  = [];
        const newStatus = [];

        // Year
        {
            showDataAll.map((key, index)=>{
                if(year == 'All'){
                    newYear.push(key);
                }else {
                    if(key.year == year){
                        newYear.push(key);
                    }
                }
            })
        }
        // Month
        {
            newYear.map((key, index)=>{
                if(month == 'All'){
                    newMonth.push(key);
                }else {
                    if(key.month == month){
                        newMonth.push(key);
                    }
                }
            })
        }
        // Status
        {
            newMonth.map((key, index)=>{
                if(status == 'All'){
                    newStatus.push(key);
                }else {
                    if(key.status == status){
                        newStatus.push(key);
                    }
                }
            })
        }

        if(value !=''){
            {
                newStatus.forEach(item =>{
                    if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.name_client.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.description.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                })
            }
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
            setShowDataPage(duplicate);

        }else if(value == ''){
            setShowDataPage(newStatus);
        }
        setSearch(value);
        setClickYear(false);
        setClickMonth(false);
        setClickStatus(false);
    }

    function SearchYear(value){
        const newData   = [];

        const newYear   = [];
        const newMonth  = [];
        const newStatus = [];

        // Year
        {
            showDataAll.map((key, index)=>{
                if(value == ''){
                    newYear.push(key);
                }else if(value == 'All'){
                    newYear.push(key);
                }else {
                    if(key.year == value){
                        newYear.push(key);
                    }
                }
            })
        }
        // Month
        {
            newYear.map((key, index)=>{
                if(month == 'All'){
                    newMonth.push(key);
                }else {
                    if(key.month == month){
                        newMonth.push(key);
                    }
                }
            })
        }
        // Status
        {
            newMonth.map((key, index)=>{
                if(status == 'All'){
                    newStatus.push(key);
                }else {
                    if(key.status == status){
                        newStatus.push(key);
                    }
                }
            })
        }
        if(search !=''){
            {
                newStatus.forEach(item =>{
                    if(item.title.toLowerCase().indexOf(search.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.name_client.toLowerCase().indexOf(search.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.description.toLowerCase().indexOf(search.toLowerCase()) != -1){
                        newData.push(item);
                    }
                })
            }
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
            setShowDataPage(duplicate);
        }else {
            setShowDataPage(newStatus);
        }
        setYear(value);
        setClickYear(false);
        setClickMonth(false);
        setClickStatus(false);
    }

    function SearchMonth(value, name){
        const newData   = [];

        const newYear   = [];
        const newMonth  = [];
        const newStatus = [];

        // Year
        {
            showDataAll.map((key, index)=>{
                if(year == 'All'){
                    newYear.push(key);
                }else {
                    if(key.year == year){
                        newYear.push(key);
                    }
                }
            })
        }
        // Month
        {
            newYear.map((key, index)=>{
                if(value == 'All'){
                    newMonth.push(key);
                }else {
                    if(key.month == value){
                        newMonth.push(key);
                    }
                }
            })
        }
        // Status
        {
            newMonth.map((key, index)=>{
                if(status == 'All'){
                    newStatus.push(key);
                }else {
                    if(key.status == status){
                        newStatus.push(key);
                    }
                }
            })
        }
        if(search !=''){
            {
                newStatus.forEach(item =>{
                    if(item.title.toLowerCase().indexOf(search.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.name_client.toLowerCase().indexOf(search.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.description.toLowerCase().indexOf(search.toLowerCase()) != -1){
                        newData.push(item);
                    }
                })
            }
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
            setShowDataPage(duplicate);
        }else {
            setShowDataPage(newStatus);
        }
        setNameMonth(name);
        setMonth(value);
        setClickYear(false);
        setClickMonth(false);
        setClickStatus(false);
    }

    function SearchStatus(value){
        const newData   = [];

        const newYear   = [];
        const newMonth  = [];
        const newStatus = [];

        // Year
        {
            showDataAll.map((key, index)=>{
                if(year == 'All'){
                    newYear.push(key);
                }else {
                    if(key.year == year){
                        newYear.push(key);
                    }
                }
            })
        }
        // Month
        {
            newYear.map((key, index)=>{
                if(month == 'All'){
                    newMonth.push(key);
                }else {
                    if(key.month == month){
                        newMonth.push(key);
                    }
                }
            })
        }
        // Status
        {
            newMonth.map((key, index)=>{
                if(value == 'All'){
                    newStatus.push(key);
                }else {
                    if(key.status == value){
                        newStatus.push(key);
                    }
                }
            });
            console.log(newStatus);
            setShowDataPage(newStatus);
        }
        setStatus(value);
        setClickYear(false);
        setClickMonth(false);
        setClickStatus(false);
    }

    function NewBudget(id){
        SetListPag('currentPage', 'budget_details');
        SetListPag('currentPageId', id);
    }

    function ShowSignature(){
        SetListPag('currentPage', 'signature');
    }

    function AlertBudget(type, statusCheck, billed){
        if(type == 0){
            if(billed == 'Faturado'){
                return (
                    <div>
                        <SvgCheked color="#7c9f06" className="icons"/>
                    </div>
                )
            }
            return '';
        }else if(type == 1){
            if(statusCheck == 2){
                return (
                    <div>
                        <SvgAlert color="#ff9842" className="icons"/>
                    </div>
                )
            }else if(statusCheck == 1){
                return (
                    <div>
                        <SvgAlert color="#F00000" className="icons"/>
                    </div>
                )
            }
            return '';
        }
        return '';
    }

    return(
        <div className="Budget">
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page">
                        Orçamentos
                    </div>
                </div>
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="buscar" />

                    <div className="click_show_search">
                        <div className="div_input" onClick={ ()=>{ setClickYear(!clickYear); setClickMonth(false); setClickStatus(false) } }>
                            <div className="div_select search_year">
                                { year }
                                {
                                    clickYear == true ?
                                    <SvgTriagle color="#747474" className="icon_select_open" /> :
                                    <SvgTriagle color="#747474" className="icon_select_close" />
                                }
                            </div>
                            <span className="span_name_select">Ano</span>
                        </div>
                        {
                            clickYear == true ?
                            <div className="click_show_search_selected click_show_search_selected_year">
                                <div className="opt_list_search" onClick={ ()=>{ SearchYear('All'); } }>All</div>
                                {
                                    listYear.map((key, index)=>{
                                        return (
                                            <div className="opt_list_search" key={ index } onClick={ ()=>{ SearchYear(key); } }>
                                                { key }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            : null
                        }
                    </div>

                    <div className="click_show_search">
                        <div className="div_input" onClick={ ()=>{ setClickYear(false); setClickMonth(!clickMonth); setClickStatus(false) } }>
                            <div className="div_select search_month">
                                { nameMonth }
                                {
                                    clickMonth == true ?
                                    <SvgTriagle color="#747474" className="icon_select_open" /> :
                                    <SvgTriagle color="#747474" className="icon_select_close" />
                                }
                            </div>
                            <span className="span_name_select">Mês</span>
                        </div>
                        {
                            clickMonth == true ?
                            <div className="click_show_search_selected">
                                <div className="opt_list_search" onClick={ ()=>{ SearchMonth('All'); } }>All</div>
                                {
                                    listMonth.map((key, index)=>{
                                        let name_month = monthList.find(item => item.month == key);
                                        return (
                                            <div className="opt_list_search" key={ index } onClick={ ()=>{ SearchMonth(key, name_month['name']); } }>
                                                { name_month['name'] }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            : null
                        }
                    </div>

                    <div className="click_show_search">
                        <div className="div_input" onClick={ ()=>{ setClickYear(false); setClickMonth(false); setClickStatus(!clickStatus) } }>
                            <div className="div_select search_status">
                                { status }
                                {
                                    clickStatus == true ?
                                    <SvgTriagle color="#747474" className="icon_select_open" /> :
                                    <SvgTriagle color="#747474" className="icon_select_close" />
                                }
                            </div>
                            <span className="span_name_select">Status</span>
                        </div>
                        {
                            clickStatus == true ?
                            <div className="click_show_search_selected div_search_status">
                                <div className="opt_list_search" onClick={ ()=>{ SearchStatus('All'); } }>All</div>
                                {
                                    listStatus.map((key, index)=>{
                                        return (
                                            <div className="opt_list_search" key={ index } onClick={ ()=>{ SearchStatus(key); } }>
                                                { key }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            : null
                        }
                    </div>
                </div>
                <div className="list_opt_alt_page">
                    <div className="" onClick={ ()=>{ ShowSignature() } }>
                        <SvgSignature color="#111827" className="icons" />
                    </div>
                    <div className="new_block_text new_project" onClick={ ()=>{ NewBudget(0) } }>
                        <div className="">
                            <span className="icons_menu">+</span>
                        </div>
                        <div className="">
                            orçamento
                        </div>
                    </div>
                </div>
            </div>
            <div className="list_budget">
                <table>
                    <tbody>
                        <tr>
                            <th width="20">#</th>
                            <th width="114">Status</th>
                            <th>Cliente</th>
                            <th>Projeto</th>
                            <th width="80">Data</th>
                            <th width="20" align="right">#</th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                                currentItens.map((key, index)=>{
                                    return(
                                        <tr key={ index }>
                                            <td align="left">{ startItens + index + 1 }</td>
                                            <td>{ key.status }</td>
                                            <td>{ key.nameClient }</td>
                                            <td>{ key.nameProject == '' ? '---' : key.nameProject }</td>
                                            <td>{ key.estimatedBr }</td>
                                            <td align="right">
                                                <div className="list_opt">
                                                    {
                                                        key.status == 'Aprovado' ?
                                                        AlertBudget(0, key.statusCheck, key.billed) :
                                                        AlertBudget(1, key.statusCheck, key.billed)
                                                    }
                                                    <div>
                                                        <div className="icons_project">
                                                            <a href={ "./budget.html?budget=" + (Math.floor(Math.random() * 65536) - 32768) + "_" + key.id } target="_blank">
                                                                <SvgPdf color="#F00000" className="icons" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div onClick={ ()=>{ NewBudget(key.id) } }>
                                                        <SvgEdit color="#324d6b" className="icons"/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            :
                            <tr>
                                <td colSpan="7">
                                    <div className="no_data">Nenhum orçamento encontrado...</div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {
                    showDataPage.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}
