import { useState, useEffect } from "react";

import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";
import { GetListPag, RegisterListPag } from "interface/InterfacePag";
import { SetModalData, SetModalState } from "interface/InterfacePopUp";

import { SvgGoogleDrive, SvgSetaRight, SvgVideo, SvgVimeo, SvgYoutube } from "components/SvgFile";

export default function Video(props){

    const [ showData, setShowData ] = useState(GetDataPage('portfolio'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ type, setType ]     = useState(HandleData('type_video'));
    const [ link, setLink ]     = useState(HandleData('link'));
    const [ title, setTitle ]   = useState(HandleData('title'));
    const [ status, setStatus ] = useState(false);

    useEffect(()=>{
        RegisterListPag('currentPageId', setIdPage);
        RegisterListPag('portfolio', setShowData);
    }, []);

    useEffect(()=>{
        setShowData(GetDataPage('portfolio'));
        setIdPage(GetListPag('currentPageId'));

        setType(HandleData('type_video'));
        setLink(HandleData('link'));
        setTitle(HandleData('title'));
    }, [showData]);

    useEffect(()=>{
        setShowData(GetDataPage('portfolio'));
        setIdPage(GetListPag('currentPageId'));

        setType(HandleData('type_video'));
        setLink(HandleData('link'));
        setTitle(HandleData('title'));
    }, [idPage]);

    function HandleData(type){
        if(idPage !=0){
            const newData      = showData.find(item => item.id == idPage);
            if(newData.contents){
                if(props.id_content !=0){
                    const dataContents = newData.contents.find(item => item.id == props.id_content);
                    const showDataContent = dataContents.contents.find(item => item.id == props.id_data);
                    return showDataContent[type];
                }
                return '';
            }
            return '';
        }
        return '';
    }

    function handleType(value){
        setType(value);
        props.RegisterData({
            "id": props.id_data,
            "id_content": props.id_content,
            "height": "center",
            "width": "left",
            "type": "video",
            "status": false,
            "name": "",
            "file": "",
            "text": "",
            "type_video": value,
            "title": title,
            "link": link
        }, props.index_0, props.index_1);
    }
    function handleLink(value){
        let linkSpace = value.replaceAll(' ', '');
        setLink(linkSpace);
        props.RegisterData({
            "id": props.id_data,
            "id_content": props.id_content,
            "height": "center",
            "width": "left",
            "type": "video",
            "status": false,
            "name": "",
            "file": "",
            "text": "",
            "type_video": type,
            "title": title,
            "link": linkSpace
        }, props.index_0, props.index_1);
    }
    function handleTitle(value){
        setTitle(value);
        props.RegisterData({
            "id": props.id_data,
            "id_content": props.id_content,
            "height": "center",
            "width": "left",
            "type": "video",
            "status": false,
            "name": "",
            "file": "",
            "text": "",
            "type_video": type,
            "title": value,
            "link": link
        }, props.index_0, props.index_1);
    }

    function OpenFile(type, file){
        SetModalData("ShowFile", { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    return(
        <div className="DefaultDiv">
            <div className="div_data_project">
                <div className="div_input space_top">
                    <select className="space_select" onChange={ (e)=>{ handleType(e.target.value) } } value={ type }>
                        <option value="">#</option>
                        <option value="Drive">Drive</option>
                        <option value="Vimeo">Vimeo</option>
                        <option value="Youtube">Youtube</option>
                    </select>
                    <span className="span_name_select">Tipo</span>
                </div>
                <div className="div_input space_data space_top">
                    <input type="text" className="" maxLength="140" onChange={ (e)=>{ handleTitle(e.target.value) } } value={ title } />
                    <span className="span_name_input">Título</span>
                </div>
            </div>
            <div className="div_data_project">
                <div className="div_input space_data space_top">
                    <input type="text" className="" maxLength="140" onChange={ (e)=>{ handleLink(e.target.value) } } value={ link } />
                    <span className="span_name_input">Link</span>
                </div>
                {
                    link !='' ?
                    <div className="div_input space_top" onClick={ ()=>{ OpenFile(type, link) } }>
                        {
                            type == 'Drive' ?
                            <SvgGoogleDrive color="#324d6b" className="icons_project" /> :
                                type == 'Vimeo' ?
                                <SvgVimeo color="#324d6b" className="icons_project"/> :
                                <SvgYoutube color="#324d6b" className="icons_project"/>
                        }
                    </div> : ""
                }
            </div>
        </div>
    )
}
